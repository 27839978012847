import * as React from "react";
import { GenericBtnStyle } from "../../styles";

const Button = (props) =>{
  const { styles = [" "]  } = props;
    return (
      <button  onClick={props.onClick}  type="submit" className={`${GenericBtnStyle}` + " " + styles + " " +`hover:bg-transparent border border-accent hover:text-accent ` } disabled={props.disabled}>
        {props.children}
      </button>
      ) 
}

export default Button
