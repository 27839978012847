import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./plan.module.scss";
import circle from "../../../assets/images/check-circle.svg";
import { useHistory } from "react-router-dom";
import { getJWT } from "../../../services/httpServices";
import Axios from "axios";
import { BASE_URL } from "../../../utils/AppConstants";
import DropDown from "../../common/DropDown/dropDown";
import numbro from "numbro";
import Aos from "aos";
import "aos/dist/aos.css";

const Plan = () => {
  useEffect(() => {
    Aos.init();
  });
  let { wallet } = useSelector((state) => state);
  const [selected, setSelected] = useState("1 Month");
  const [dataPlans, setDataPlans] = useState();
  // const [option, setOption] = useState();
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const token = await getJWT();
      const res = await Axios.get(`${BASE_URL}/plans`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      });
      setDataPlans(res.data);
    } catch (e) {}
  };

  const planHandler = () => {
    wallet.key?.public ? history.push("/subscription") : history.push("/login");
  };

  return (
    <div className={styles.wrapper} id="PaymentPlan">
      <div className={styles.heading}>API Package Plans</div>
      <div className={styles.text}>
        Following plans will be given to the GPAY Users
      </div>
      <div
        className={styles.cardWrapper}
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        {dataPlans?.map((data, index) => {
          const options = data?.duration.map((item) => {
            return `${item} ${item === 1 ? "Month" : "Months"}`;
          });
          return (
            <div className={styles.card} key={index}>
              <div className={styles.heading}>{data?.title}</div>
              <div className={styles.middle}>
                <div className={styles.textWrapper}>
                  <div className={styles.icon}>
                    <img src={circle} width={"80%"} />
                  </div>
                  <div className={styles.text}>
                    Total API Keys {data?.apisPerSecond}
                  </div>
                </div>
                <div className={styles.textWrapper}>
                  {/* <div className={styles.icon}>
                    <img src={circle} width={"80%"} />
                  </div> */}
                  {/* <div className={styles.text}>
                    {`${numbro(data?.apisPerDay).format({
                      average: true,
                      mantissa: 1,
                    })} APIs per day.`}
                  </div> */}
                </div>
                <div className={styles.dropdownWrapper}>
                  <div className={styles.label}>Duration : 1 Month</div>
                  {/* <DropDown
                    selected={selected}
                    setSelected={setSelected}
                    options={options}
                  /> */}
                </div>

                <div className={styles.priceWrapper}>
                  <div className={styles.text}>
                    {data?.price * selected.substr(0, selected.indexOf(" "))}{" "}
                    USDT
                  </div>
                </div>
              </div>
              {/* <div className={styles.buttonWrapper}>
                <button className={styles.button} onClick={planHandler}>
                  <span className={styles.text}>Choose Plan</span>
                </button>
              </div> */}
            </div>
          );
        })}
        <div className={styles.card}>
          <div className={styles.heading}>Business Plan </div>
          <div className={styles.middle}>
            <div className={styles.textWrapper}>
              <div className={styles.icon}>
                <img src={circle} />
              </div>
              <div className={styles.text}>
                Contact us for your custom plan.
              </div>
            </div>
            <div className={styles.textWrapper}>
              <div className={styles.icon}>
                <img src={circle} />
              </div>
              <div className={styles.text}>Duration as per requirement</div>
            </div>
          </div>
          <div
            className={styles.buttonWrapper}
            onClick={() => history.push("/contactUs")}
          >
            {/* <button className={styles.button}>
              <span className={styles.text}>Contact Us</span>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
