import React, { useState } from "react";
import GenericModal from "../../common/genericModal/index";
import styles from "./Success.module.scss";
import success from "../../../assets/images/success.svg";

const Success = ({ isOpen, toggle }) => {
  // const [selected, setSelected] = useState("XML");
  // const [isActive, setIsActive] = useState(false);
  const changeHandler = () => {};
  return (
    <>
      <GenericModal
        isOpen={isOpen}
        toggle={toggle}
        style={{ backgroundColor: "#1A1B16!important" }}
      >
        <div className={styles.fieldWrapper}>
          <div className={styles.imgdiv}>
            <img src={success} />
          </div>
        </div>

        <div className={styles.fieldWrapper}>
          <div className={styles.row1}>You Successfully Subscribe</div>
          <div className={styles.row2}>Silver Plan</div>
        </div>
      </GenericModal>
    </>
  );
};

export default Success;
