import React, { useEffect } from "react";
import styles from "./feature.module.scss";
import cardIcon1 from "../../../assets/images/cardIcon1.svg";
import cardIcon2 from "../../../assets/images/cardIcon2.svg";
import cardIcon3 from "../../../assets/images/cardIcon3.svg";
import cardIcon4 from "../../../assets/images/cardIcon4.svg";
import Aos from "aos";
import "aos/dist/aos.css";
const Feature = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Core Features</div>
      <div className={styles.text}>
        Integrate a secure payment gateway to any E-commerce website and pay
        using Cryptocurrency.
      </div>
      <div className={styles.cardsContainer}>
        <div
          className={styles.cardWrapper}
          data-aos="flip-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <div className={styles.card}>
            <div className={styles.cardIcon}>
              <img src={cardIcon1} />
            </div>
            <div className={styles.cardHeading}>SECURE</div>
            <div className={styles.cardText}>
              The decentralized niche leads to ultimate trust and freedom in
              managing your own digital wealth.
            </div>
          </div>
        </div>
        <div
          className={styles.cardWrapper}
          data-aos="flip-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <div className={styles.card}>
            <div className={styles.cardIcon}>
              <img src={cardIcon2} />
            </div>
            <div className={styles.cardHeading}>24 / 7 SUPPORT</div>
            <div className={styles.cardText}>
              24/7 support for any help or query Team is available.
            </div>
          </div>
        </div>
        <div
          className={styles.cardWrapper}
          data-aos="flip-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <div className={styles.card}>
            <div className={styles.cardIcon}>
              <img src={cardIcon3} />
            </div>
            <div className={styles.cardHeading}>P2P </div>
            <div className={styles.cardText}>
              Your secured P2P escrow services for crypto to fiat exchange.
            </div>
          </div>
        </div>
        <div
          className={styles.cardWrapper}
          data-aos="flip-right"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <div className={styles.card}>
            <div className={styles.cardIcon}>
              <img src={cardIcon4} />
            </div>
            <div className={styles.cardHeading}>STABLE COIN</div>
            <div className={styles.cardText}>
              Stellar makes it possible to create, send, and trade digital
              representations of all forms of money.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
