const Reviews = [
  {
    review:
      " There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope. It’s exciting to think about setting up your own viewing station whether that is on the deck of your home or having a powerful but mobile telescope set up to take.",
    author: "Jose Mill",
  },
  {
    review:
      " There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope. It’s exciting to think about setting up your own viewing station whether that is on the deck of your home or having a powerful but mobile telescope set up to take.",
    author: "Jose Mill",
  },
  {
    review:
      " There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope. It’s exciting to think about setting up your own viewing station whether that is on the deck of your home or having a powerful but mobile telescope set up to take.",
    author: "Jose Mill",
  },
];
const assetDetails = [
  { title: "Asset Website", value: "https://gwallet.tech" },
  { title: "Organization", value: "gwallet" },
  { title: "Organization E-mail", value: "support@gwalletglobal.com" },
  { title: "Asset Conditions", value: "Withdrawal fees apply" },
  {
    title: "Asset Redemption Instructions",
    value:
      "Use SEP24 with our federation server or use the deposit/withdrawal UI at https://gwallet.tech",
  },
];

let commaSeparated = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const isTestNet = true;
export const isExchangeVisible = true;
export const p2pSpread = 1;
const horizon = false
  ? "https://horizon.gwallet.tech"
  : "https://horizon.stellar.org";

const BASE_URL = "https://stagingapi.gpay.tech";
// const BASE_URL = "https://3271-101-53-234-165.in.ngrok.io";
//   ? "https://api.gwallet.tech"
//    : "https://anchor.gwallet.tech";

// https://stagingapi.gyapay.tech

const STELLAR_URL = isTestNet
  ? "https://horizon-testnet.stellar.org"
  : "https://horizon.stellar.org";

export const MIN_BALANCE_COIN = isTestNet ? "LTC" : "LEVELG";

//const networkPhraseTest = "Test SDF Network ; September 2015";
const stellarNetworkPhrase = isTestNet
  ? "Test SDF Network ; September 2015"
  : "Public Global Stellar Network ; September 2015";

const stellarNetwork = isTestNet ? "testnet" : "public";

const postOptions = (object) => {
  let options = object ? JSON.stringify(object) : "";

  return {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: options,
  };
};

export const getChange = (closeNow, closePrev) => {
  return (((closeNow - closePrev) / closePrev) * 100).toFixed(7);
};

export const tradeActionButtonEnum = {
  I_HAVE_PAID: "I_HAVE_PAID",
  HOLD_BALANCE: "HOLD_BALANCE",
  I_HAVE_RECEIVED: "I_HAVE_RECEIVED",
  I_HAVE_NOT_RECEIVED: "I_HAVE_NOT_RECEIVED",
  CANCEL: "CANCEL",
  CANCEL_DISABLE: "CANCEL_DISABLE",
  REJECT: "REJECT",
  NON: "NON",
  DISPUTE_EXPIRED_SELLER: "DISPUTE_EXPIRED_SELLER",
  DISPUTE_EXPIRED_BUYER: "DISPUTE_EXPIRED_BUYER",
};
export const tradeConfirmMessageEnum = {
  I_HAVE_PAID: "Are you sure that you have paid the seller?",
  HOLD_BALANCE: "Are you sure you want to start the trade?",
  I_HAVE_RECEIVED:
    "The trade will be completed with this action so make sure that you have received the amount.",
  I_HAVE_NOT_RECEIVED:
    "Payments can take some time so make sure you have waited enough before starting a dispute.",
  CANCEL: "Are you sure you want to cancel this trade?",
  REJECT: "Are you sure you want to reject this trade?",
  DISPUTE_EXPIRED_SELLER:
    "Payments can take some time so make sure you have waited enough before starting a dispute.",
  DISPUTE_EXPIRED_BUYER:
    "Payments can take some time so make sure you have waited enough before starting a dispute.",
};
export const tradeActionIndexEnum = {
  I_HAVE_PAID: 2,
  HOLD_BALANCE: 1,
  I_HAVE_RECEIVED: 3,
  I_HAVE_NOT_RECEIVED: 4,
  CANCEL: 0,
  CANCEL_DISABLE: 0,
  NON: -1,
  DISPUTE_EXPIRED_SELLER: 5,
  DISPUTE_EXPIRED_BUYER: 6,
};

export const tradeStatusEnum = {
  pending_user_transfer: "pending_user_transfer",
  Started: "Started",
  FundsSent: "FundsSent",
  Completed: "Completed",
  Disputed: "Disputed",
  Cancelled_With_Holded_Funds: "Cancelled_With_Holded_Funds",
  Cancelled: "Cancelled",
};

const getInitUsers = () => {
  const document = {
    country: "",
    expDate: new Date(),
    issueDate: new Date(),
    number: "",
    docType: "",
    backUrl: "",
    realName: "",
    frontUrl: "",
    proofCode: "",
    proofImage: "",
  };
  const user = {
    id: 0,
    firstName: "",
    lastName: "",
    otherName: "",
    dob: new Date(),
    file: "",

    companyName: "",
    companyWebsite: "",
    businessName: "",
    isCompanyKyc: true,
    adminNotes: null,
    address: "",
    countryCode: "",
    city: "",
    companyDocument: null,
    email: "",
    phone: "",
    postalCode: "",
    state: "",
    status: "",
    isPhoneVerified: false,
    isEmailVerified: false,
    countryDial: "",
    rating: 0,
    reviews: 0,
  };
  return { user, document };
};

export const chainTypeEnum = {
  TRC: "TRC20",
  ERC: "ERC20",
};

const getEmptyKYC = () => {
  const document = {
    country: "US",
    expDate: new Date(),
    issueDate: new Date(),
    realName: "",
    number: "",
    docType: "",
    backUrl: "",
    frontUrl: "",
    proofImage: "",
    proofCode: "",
  };

  const object = {
    id: 0,
    firstName: "",
    lastName: "",
    otherName: "",
    dob: new Date(),
    file: "",

    companyName: "",
    companyWebsite: "",
    businessName: "",
    isCompanyKyc: true,
    adminNotes: null,
    address: "",
    companyDocument: null,
    countryCode: "US",
    city: "",
    email: "",
    phone: "",
    postalCode: "",
    state: "",
    status: "",
    isEmailVerified: false,
    isPhoneVerified: false,
    countryDial: "",
    reviews: 0,
    rating: 0,
  };
  return object;
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// If want to add more coin just insert coingecko id of such coin in the string with comma sepration

const walletCoins =
  "stellar,bitcoin,dogecoin,dash,ethereum,tether,shiba-inu,levelg,kishu-inu,tron,litecoin";

export {
  Reviews,
  assetDetails,
  commaSeparated,
  BASE_URL,
  postOptions,
  getInitUsers,
  getEmptyKYC,
  capitalize,
  STELLAR_URL,
  stellarNetworkPhrase,
  stellarNetwork,
  walletCoins,
};
