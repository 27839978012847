import React from "react";
import { KYCProfileTab } from "../components/kyc";

const KycPage = () => {
  return (
    <div>
      <KYCProfileTab />
    </div>
  );
};

export default KycPage;
