import React from "react";
// import { Button } from "components";
// import { resetPassword } from "services/authServces";
import { useHistory } from "react-router-dom";


import Modal from "react-modal";
import  closeButton  from "../../assets/images/close_button.png";
import useMediaQuery from "../common/useQuery";
// import axios from 'axios'

function ResetPassword({toggle,isOpen }) {
  const isDesktop = useMediaQuery(1024, true);
  let history = useHistory();
  let handleReset = () => {
    // resetPassword();
    history.push({ pathname: "/register", state: { reset: true } });
  };
  let handleCancel = () => history.goBack();
  return (
    <Modal  ariaHideApp={false}
    isOpen={isOpen}
    style={isDesktop?customStyle:customStyleMob}>
      <button className="float-right"   onClick={handleCancel}>
        <img src={closeButton} className="w-6 h-6" />
      </button>
      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl text-pink font-semibold mb-8 text-h1 sm:text-2xl ">
          Reset Password Warning
        </h2>
        <div className="mx-4 mb-8 mt-8 bg-sparkleGray  p-6 rounded text-white text-s font-small sm:font-regular">
          <b>Are you sure you want to reset password ?</b>
          <br />
          <br />
          <p>
            Resetting your password will only delete accounts added to this
            device, once the new session is created.
            <br />
            <br />
            You will need to import your private keys to restore any previously
            created accounts.
          </p>
          <br />
          <br />
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleReset}
            className="bg-pink font-regular text-xs text-white py-3 px-10 mt-2 mb-10 mr-3 focus:outline-none sm:px-20"
          >
            Reset
          </button>
          <button
            onClick={handleCancel}
            className="bg-accent font-regular text-xs text-white py-3 px-10 mt-2 mb-10 mr-3 focus:outline-none sm:px-20"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ResetPassword;

const customStyle = {
  overlay: {
    backgroundColor: "#00000090",
  },
  content: {
    top: "10%",
    left: "25%",
    right: "auto",
    bottom: "auto",
    width: "54.4%",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
const customStyleMob = {
  overlay: {
    backgroundColor: "#00000090",
  },
  content: {
    top: "10%",
    left: "5%",
    right: "auto",
    bottom: "10%",
    width: "90%",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
