import * as React from "react";
// import * as Images from "assets/images/index";
import useUpload from "../hooks/useUpload";
import Upload from "../screens/Upload";
import { countries } from "../../../utils/countries";
import CustomInput from "../../common/CustomInput";
import { colors } from "../../../styles/theme";
import CustomPicker2 from "../../common/CustomPicker2";
import { DropdownGray } from "../../common/DropdownGray";
import { BottomNav } from "../index";

export const CompanyDocumentsForm = ({ tab, setTab, status }) => {
  let {
    docType,
    country,
    issueDate,
    realName,
    realNameVal,
    number,
    docTypeVal,
    issueDateVal,
    numberVal,
    companyDocumentVal,
    countryVal,
    submit,
    handleInputChange,
    saveUrl,
    submitting,
    companyDocWithNameVal,
  } = useUpload(tab, setTab, true);
  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" ||
      document.activeElement.classList.contains("noscroll")
    ) {
      document.activeElement.blur();
    }
  });

  return (
    <div className="flex flex-col justify-between flex-1">
      <form onSubmit={submit} className="pt-10 p-5 w-full m-auto">
        <div>
          <h4 className="text-white pt-8 pb-2 font-regular text-2xl">
            Identity Verification
          </h4>

          <div className="lg:flex pb-2 items-center">
            <DropdownGray
              label="Business Registration Country"
              textMd={true}
              value={country}
              name="country"
              validation={countryVal}
              onChange={handleInputChange}
              data={countries}
            />
            <div className="px-1" />

            <CustomPicker2
              label="Registration Date"
              left={true}
              validation={issueDateVal}
              startDate={issueDate}
              setStartDate={(value) =>
                handleInputChange({ target: { name: "issueDate", value } })
              }
            />
          </div>

          <div className="lg:flex pb-2">
            <div className="flex-1 lg:pr-1">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Business Name on Document"
                name="realName"
                value={realName}
                onChange={handleInputChange}
                valMsg={realNameVal}
                marginVertical="2px"
                padding={"12px"}
                placeholder="Enter your Real Name on Document here"
              />
            </div>
            <div className="flex-1 pl-1">
              <CustomInput
                classs="noscroll"
                labelColor={colors.secondaryGray}
                label="Document Number"
                name="number"
                value={number}
                type="number"
                onChange={handleInputChange}
                valMsg={numberVal}
                marginVertical="2px"
                padding={"12px"}
                placeholder="Enter your Document Number here"
              />
            </div>
          </div>

          <h4 className="text-white pt-8 mb-2 font-regular text-2xl">
            Upload Documents
          </h4>
          <p className="font-regular text-white text-xs pb-2">
            Upload your verification document below.
          </p>
        </div>

        <div className="lg:flex">
          <div
            style={{ backgroundColor: "rgba(255,159,0,0.15)" }}
            className="w-full p-5 mb-0 lg:pr-1"
          >
            <div>
              <ul className="list-inside list-alpha">
                <p className="font-regular text-white text-xs pb-1">
                  The verification picture needs to satisfy these conditions:
                </p>
                <li className="font-regular text-white text-xs">
                  No modification is made on the uploaded documents
                </li>
                <li className="font-regular text-white text-xs">
                  Documents are readable and not blurred
                </li>
              </ul>
              <p className="mt-2 font-regular text-white text-xs">
                Picture that does not satisfy any of the above will not be
                considered
              </p>
            </div>
          </div>
          <div className="w-full lg:pl-1">
            <div className="flex-1">
              <Upload
                label="Company registration certificate"
                onSuccess={(url) =>
                  saveUrl({ name: "companyDocument", value: url })
                }
                text="Upload picture of company document"
                id="documentFront"
                validation={companyDocumentVal}
              />
            </div>
            <div className="flex-1">
              <Upload
                label="Kindly upload the certificate that shows your name as the owner or one of the owners"
                onSuccess={(url) =>
                  saveUrl({ name: "companyDocWithName", value: url })
                }
                text="Document with your name as the owner"
                id="documentWithName"
                validation={companyDocWithNameVal}
              />
            </div>
            <div />
          </div>
        </div>
        <div></div>
      </form>
      <BottomNav
        tab={tab}
        setTab={setTab}
        submit={submit}
        loading={submitting}
        status={status}
        edit={false}
      />
    </div>
  );
};
