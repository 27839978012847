import * as React from "react";

export const DropdownGray = (props) => {
  const data = props.data;
  const opt = data.map((item, index) => (
    <option key={index} selected={props.value === item.code} value={item.code}>
      {item.name}
    </option>
  ));

  return (
    <div className="flex-1 mt-2">
      <p className="text-gray4 mb-1 font-regular text-xs text-left">
        {props.label}
      </p>

      <div className={`relative ${props.class}`}>
        <select
          onChange={props.onChange}
          name={props.name}
          className={`block appearance-none w-full font-regular text-sm bg-primary text-secondaryGray focus:outline-none"`}
          style={{ padding: "10px" }}
        >
          {opt}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 h-10 text-white">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      <p className="text-red text-sm italic">{props.validation}</p>
    </div>
  );
};

export const DropdownCountry = (props) => {
  const data = props.data;
  const opt = data.map((item, index) => (
    <option
      key={index}
      selected={props.value === item.code}
      value={JSON.stringify(item)}
    >
      {item.name}
    </option>
  ));

  return (
    <div className="flex-1">
      <p className="text-gray4 mb-1 font-regular text-xs text-left">
        {props.label}
      </p>

      <div className={`relative ${props.class}`}>
        <select
          disabled={props.disabled}
          onChange={props.onChange}
          name={props.name}
          className={`block appearance-none w-full font-regular text-sm bg-primary text-secondaryGray focus:outline-none"`}
          style={{ padding: "10px" }}
        >
          {opt}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 h-10 text-white">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      <p className="text-red text-sm italic">{props.validation}</p>
    </div>
  );
};
