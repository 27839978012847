import {SET_COINS } from "../actions/coinTypes";
const initialState = {
  coinsData: [],
};
export const coinReducer = (state = initialState, action) => {


  
  switch (action.type) {
    case SET_COINS:
      return {
        ...state,
        coinsData: action.payload,
      };
    default:
      return state;
  }
};
