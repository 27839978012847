import React from "react";
import Navigation from "../components/LandingPage/navigation";
import ImportWallet from "../components/NewWallet/importWallet";

const ImportWalletPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navigation />
      <div className="mt-20 ">
        <ImportWallet />
      </div>
    </div>
  );
};

export default ImportWalletPage;
