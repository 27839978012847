import React, { useEffect, useState } from "react";
import axios from "axios";
import { getJWT } from "../../services/httpServices";
import { BASE_URL } from "../../utils/AppConstants";
import { useAlert } from "react-alert";
import useDashboard from "../dashboard/useDashboard";

const useEscrow = () => {
  const { wallet } = useDashboard();
  // let searchData = [];
  const [searchData, setSearchData] = useState([]);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [rowData, setrowData] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [dataSelected, setDataSelected] = useState(`1x10 of ${count && count}`);
  let dataLimit = [
    `1x10 of ${count ? count : 0}`,
    `2x10 of ${count ? count : 0}`,
    `3x10 of ${count ? count : 0}`,
    `4x10 of ${count ? count : 0}`,
    `5x10 of ${count ? count : 0}`,
  ];
  const [selectedOrder, setSelectedOrder] = useState("ALL");
  const optionsOrder = ["DISPUTED", "COMPLETED", "ALL"];
  const [pickedStartDate, setPickedStartDate] = useState(new Date());
  const [pickedEndDate, setPickedEndDate] = useState(new Date());
  const [searchState, setSearchState] = useState({
    value: "",
    change: false,
  });

  useEffect(() => {
    fetchData();
  }, [
    pagination.page,
    pagination.limit,
    selectedOrder,
    pickedStartDate,
    pickedEndDate,
  ]);
  function capitalizeFirstLetter(string) {
    let cloneString = string.toLowerCase();
    return cloneString.charAt(0).toUpperCase() + cloneString.slice(1);
  }
  useEffect(() => {
    const splitLimit = +dataSelected.split("x")[0];
    const newLimit = splitLimit * 10;
    setPagination((prev) => ({ ...prev, limit: newLimit, page: 1 }));
  }, [dataSelected]);
  useEffect(() => {
    setDataSelected(`1x10 of ${count ? count : 0}`);
  }, [count]);
  useEffect(() => {
    let filterData = data?.filter((item) => {
      return (
        item?.merchantPublicKey
          ?.toUpperCase()
          .includes(searchState?.value?.toUpperCase()) ||
        item?.customerPublicKey
          ?.toUpperCase()
          .includes(searchState?.value?.toUpperCase()) ||
        item?.orderNumber
          ?.toUpperCase()
          .includes(searchState?.value?.toUpperCase())
      );
    });
    setSearchData(filterData);
    // searchData.push(filterData);
  }, [searchState.value]);

  const handlePageChange = async (pageNumber) => {
    setPagination((prev) => ({ ...prev, page: pageNumber }));
  };
  const fetchData = async () => {
    setSubmitting(true);
    try {
      const token = await getJWT();
      let res = await axios.get(
        `${BASE_URL}/transactions/merchant?page=${pagination.page}&limit=${
          pagination.limit
        }&isInstant=${false}&merchantStatus=${capitalizeFirstLetter(
          selectedOrder
        )}&startDate=${pickedStartDate}&endDate=${pickedEndDate}&merchantId=${
          wallet?.key?.public
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token?.data?.token}`,
          },
        }
      );

      setData(res.data.results);
      setCount(res.data.count);
      setSubmitting(false);
      // searchData.push(res.data.results);
      setSearchData(res.data.results);
      setSearchState((prev) => ({ ...prev, change: !searchState.change }));
    } catch {
      alert.show("Something Went Wrong", { type: "error" });
      setSubmitting(false);
    }
  };

  const toggle = () => {
    setisOpen(false);
  };

  const handleDispute = async (data) => {
    setisOpen(true);
    return;
    let param = {
      merchantEmail: data.merchantPublicKey,
      userEmail: "ali.asghar@kryptomind.com",
      disputedBy: "merchant",
      orderNumber: data.orderNumber,
      totalAmount: String(data.totalAmount),
      fee: String(data.transactionFee),
      releaseTo: "",
    };
    setSubmitting(true);
    try {
      // const token = await getJWT();
      let res = await axios.post(
        `${BASE_URL}/dispute/create-dispute`,
        param
        // ,
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${token?.data?.token}`,
        //   },
        // }
      );
      // await fetchData();
      // alert.show("Disputed request sent to admin!", { type: "success" });

      setSubmitting(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setSubmitting(false);
    }
  };
  const searchHandler = (e) => {
    setSearchState((prev) => ({ ...prev, value: e.target.value }));
  };
  return {
    handlePageChange,
    pagination,
    data,
    count,
    dataSelected,
    setDataSelected,
    dataLimit,
    submitting,
    selectedOrder,
    setSelectedOrder,
    optionsOrder,
    pickedStartDate,
    setPickedStartDate,
    pickedEndDate,
    setPickedEndDate,
    searchHandler,
    searchState,
    searchData,
    handleDispute,
    toggle,
    isOpen,
    rowData,
    setrowData,
    fetchData,
  };
};

export default useEscrow;
