import React from "react";
import styles from "./sidebar.module.scss";
import { Link, useLocation } from "react-router-dom";
import activeNavIcon from "../../../../assets/images/activeNavIcon.svg";
import logo from "../../../../assets/images/Logo-Merchant.svg";
import wallet from "../../../../assets/images/walletGrey.svg";
import walletActive from "../../../../assets/images/walletWhite.svg";
import dashboard from "../../../../assets/images/dashboardGrey.svg";
import dashboardActive from "../../../../assets/images/dashboardWhite.svg";
import report from "../../../../assets/images/reportGrey.svg";
import reportActive from "../../../../assets/images/reportWhite.svg";
import api from "../../../../assets/images/apiGrey.svg";
import apiActive from "../../../../assets/images/apiWhite.svg";
import kyc from "../../../../assets/images/kycGrey.svg";
import kycActive from "../../../../assets/images/kycWhite.svg";
import payment from "../../../../assets/images/paymentGrey.svg";
import paymentActive from "../../../../assets/images/paymentWhite.svg";
import setting from "../../../../assets/images/settingGrey.svg";
import settingActive from "../../../../assets/images/settingWhite.svg";
import logout from "../../../../assets/images/signoutGrey.svg";
import logoutActive from "../../../../assets/images/signoutWhite.svg";

const SideBar = ({ mobileView }) => {
  const router = useLocation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {!mobileView && (
          <Link to="/dashboard">
            <div className={styles.logo}>
              <img src={logo} />
              {/* <p style={{ color: "grey", marginLeft: "3.5rem" }}>Merchant</p> */}
            </div>{" "}
          </Link>
        )}

        <Link
          to="/dashboard"
          className={`${
            router.pathname === "/dashboard" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={dashboard} />
          </div>
          <div className={styles.iconActive}>
            <img src={dashboardActive} />
          </div>
          <div className={styles.text}>Dashboard</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/escrow-report"
          className={`${
            router.pathname === "/escrow-report"
              ? styles.activeLink
              : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={report} />
          </div>
          <div className={styles.iconActive}>
            <img src={reportActive} />
          </div>
          <div className={styles.text}>Escrow Report</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/instant-report"
          className={`${
            router.pathname === "/instant-report"
              ? styles.activeLink
              : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={report} />
          </div>
          <div className={styles.iconActive}>
            <img src={reportActive} />
          </div>
          <div className={styles.text}>Instant Report</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/wallet"
          className={`${
            router.pathname === "/wallet" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={wallet} />
          </div>
          <div className={styles.iconActive}>
            <img src={walletActive} />
          </div>
          <div className={styles.text}>Wallet</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/api-key"
          className={`${
            router.pathname === "/api-key" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={api} />
          </div>
          <div className={styles.iconActive}>
            <img src={apiActive} />
          </div>
          <div className={styles.text}>API Keys</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/kyc-profile"
          className={`${
            router.pathname === "/kyc-profile" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={kyc} />
          </div>
          <div className={styles.iconActive}>
            <img src={kycActive} />
          </div>
          <div className={styles.text}>KYC</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/subscription"
          className={`${
            router.pathname === "/subscription" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={payment} />
          </div>
          <div className={styles.iconActive}>
            <img src={paymentActive} />
          </div>
          <div className={styles.text}>Payment Plans</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
        <Link
          to="/settings"
          className={`${
            router.pathname === "/settings" ? styles.activeLink : styles.tab
          } `}
        >
          <div className={styles.icon}>
            <img src={setting} />
          </div>
          <div className={styles.iconActive}>
            <img src={settingActive} />
          </div>
          <div className={styles.text}>Settings</div>
          <img className={styles.rightIcon} src={activeNavIcon} />
        </Link>
      </div>
      <Link
        to="/"
        className={`${
          router.pathname === "/1" ? styles.activeLink : styles.signout
        } `}
      >
        <div className={styles.icon}>
          <img src={logout} />
        </div>
        <div className={styles.iconActive}>
          <img src={logoutActive} />
        </div>
        <div className={styles.text}>Logout</div>
        <img className={styles.rightIcon} src={activeNavIcon} />
      </Link>
    </div>
  );
};

export default SideBar;
