import { WALLET_KEY, WALLET_COINS, SET_COINS } from "../actions/walletTypes";
const initialState = {
  keys: {},
  coins: [],
};
export const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_KEY:
      return {
        ...state,
        key: action.payload,
      };
    case SET_COINS:
      return {
        ...state,
        coins: action.payload,
      };
    default:
      return state;
  }
};
