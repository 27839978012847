import {
  GetProfileInfoType,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_PENDING,
  FETCH_PROFILE_FAILURE,
  ADD_DOCUMENT_SUCCESS,
  CLEAR_PROFILE,
  ADD_USER_DATA,
  EMAIL_VERIFIED,
  PHONE_VERIFIED,
  SET_INPUT_PROPERTY,
  DELETE_KYC_PENDING,
  SET_PROFILE_PICTURE,
} from "../actions/ProfileActionsTypes";
import { getInitUsers } from "../../utils/AppConstants";
import { profileStatus } from "../../utils/constants";

const { user, document } = getInitUsers();
const initialState = {
  user: user,
  document: document,
  loading: false,
  error: false,
  deleting: false,
};

export function profileReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.user,
        document: action.document,
        loading: false,
        error: false,
      };
    case SET_PROFILE_PICTURE:
      return {
        ...state,
        user: { ...state.user, file: action.photo },
      };
    case FETCH_PROFILE_FAILURE:
      let obj = getInitUsers();
      return {
        ...state,
        user: obj.user,
        document: obj.document,
        loading: false,
        error: action.error,
      };
    case ADD_DOCUMENT_SUCCESS:
      const { firstName, companyName } = state.user;
      let status =
        (firstName && firstName !== "") || (companyName && companyName !== "")
          ? profileStatus.pending
          : profileStatus.draft;

      return {
        ...state,
        document: action.payload,
        user: { ...state.user, status },
        loading: false,
      };
    case FETCH_PROFILE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_KYC_PENDING:
      return {
        ...state,
        deleting: true,
      };
    case CLEAR_PROFILE:
      const { user, document } = getInitUsers();
      return {
        ...state,
        user: user,
        document: document,
        deleting: false,
      };
    case EMAIL_VERIFIED:
      return {
        ...state,
        user: { ...state.user, isEmailVerified: true, email: action.email },
      };
    case PHONE_VERIFIED:
      return {
        ...state,
        user: {
          ...state.user,
          isPhoneVerified: true,
          phone: action.phone,
          countryCode: action.country,
          countryDial: action.dial,
        },
      };
    case ADD_USER_DATA:
      const { number } = state.document;
      let status2 =
        number && number !== "" ? profileStatus.pending : profileStatus.draft;
      return {
        ...state,
        user: { ...state.user, ...action.payload, status: status2 },
      };
    default:
      return state;
  }
}
