import { STELLAR_URL } from "../utils/AppConstants";
import { getData } from "./storageServices";
import { Transaction } from "stellar-sdk";
import { decrypt } from "../utils/helpers";
var StellarSdk = require("stellar-sdk");
var server = new StellarSdk.Server(STELLAR_URL);

const updateAccount = async (publicKey) => {
  try {
    await server
      .accounts()
      .accountId(publicKey)
      .call()
      .then((account) => {});
  } catch (err) {
    // console.log(err);
  }
};

const getBalances = (selectedId) => {
  return new Promise((resolve, reject) => {
    if (selectedId) {
      server
        .loadAccount(selectedId)
        .then((data) => {
          let balances = data?.balances;
          resolve(balances);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      reject("New User.");
    }
  });
};
let getSelectedKeys = () => {
  let wallet = getData("selectedWallet");
  let walletObj = JSON.parse(wallet);
  let temp = wallet ? walletObj : { private: "", public: "" };
  return temp;
};
const signTransaction = async (secret, { network_passphrase, transaction }) => {
  var sourceKeys = StellarSdk.Keypair.fromSecret(secret);
  const transactionInit = new Transaction(transaction, network_passphrase);
  await transactionInit.sign(sourceKeys);
  return transactionInit.toXDR();
};
const getSelectedSecret = (privateKey) => {
  // let parseKey = parseInt(privateKey, 10);
  let key = decrypt(privateKey);
  return key;
};
const loadCoins = (address) => {
  return server
    .loadAccount(address)
    .then((result) => result?.balances)
    .catch((e) => []);
};

export {
  updateAccount,
  getBalances,
  getSelectedKeys,
  signTransaction,
  getSelectedSecret,
  loadCoins,
};
