import * as React from "react";
import CustomInput from "../../common/CustomInput";
import { colors } from "../../../styles";


export const CompanyInfoForm = ({
  handleInputChange,
  showAlert,
  companyName,
  companyNameVal,
  companyWebsite,
  companyWebsiteVal,
  businessName,
  status,
  setPhoto,
}) => {
  return (
    <div className="mt-4 mb-4">
      <p className="text-white font-regular mb-2 text-xs md:text-xl lg:text-2xl xl:text-2xl">
        Company Info
      </p>
      <div className="items-center justify-between">
        <div className="xl:flex">
          <div className="xl:mr-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Company Name"
              name="companyName"
              value={companyName}
              onChange={handleInputChange}
              valMsg={companyNameVal}
              marginVertical="0.30rem"
              padding={"12px"}
              placeholder="Enter your company name"
            />
            {companyName && companyName !== "" && (
              <p className="text-xs text-red italic" style={{marginTop: "-10px"}}>
                {companyName} will be used as your bank holder's account title,
                please make sure this is correct and according to your bank
                accounts detail.
              </p>
            )}
          </div>
          <div className="xl:ml-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Doing Business As (Optional)"
              name="businessName"
              value={businessName}
              onChange={handleInputChange}
              marginVertical="0.30rem"
              padding={"12px"}
              placeholder="Enter your Business type"
            />
          </div>
        </div>
        <div className="xl:flex">
          <div className="xl:ml-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Company Website"
              name="companyWebsite"
              value={companyWebsite}
              onChange={handleInputChange}
              valMsg={companyWebsiteVal}
              marginVertical="0.30rem"
              padding={"12px"}
              placeholder="Enter your company website"
            />
          </div>
          <div className="xl:ml-1 w-full" />
        </div>
      </div>
    </div>
  );
};
