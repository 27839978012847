import React, { useEffect } from "react";
import styles from "./work.module.scss";
import step1Img from "../../../assets/images/workStep1.svg";
import step2Img from "../../../assets/images/workStep2.svg";
import step3Img from "../../../assets/images/workStep3.svg";
import step4Img from "../../../assets/images/workStep4.svg";
import mobStep1Img from "../../../assets/images/workMobStep1.svg";
import mobStep2Img from "../../../assets/images/workMobStep2.svg";
import mobStep3Img from "../../../assets/images/workMobStep3.svg";
import mobStep4Img from "../../../assets/images/workMobStep4.svg";
import useMediaQuery from "../../common/useQuery";
import Aos from "aos";
import "aos/dist/aos.css";

const WorkFlow = () => {
  useEffect(() => {
    Aos.init();
  });
  const isDesktop = useMediaQuery(1024, true);
  return (
    <div className={styles.container}>
      <div
        className={styles.heading}
        data-aos="zoom-in"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1000"
      >
        How it Works
      </div>
      <div className={styles.text}>
        Simply activate your account, follow an easy KYC process and purchase a
        plan.
      </div>
      <div className={styles.wrapper}>
        <div
          className={styles.card}
          data-aos="zoom-out"
          data-aos-easing="ease-in-sine"
          data-aos-duration="800"
        >
          <div
            className={styles.imgWrapper}
            style={{
              backgroundImage: isDesktop
                ? `url(${step1Img})`
                : `url(${mobStep1Img})`,
            }}
          >
            <div className={styles.text}>
              Login your account and get public and private key. Send XLM to
              activate your account.
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          data-aos="zoom-out"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1200"
        >
          <div
            className={styles.imgWrapper}
            style={{
              backgroundImage: isDesktop
                ? `url(${step2Img})`
                : `url(${mobStep2Img})`,
            }}
          >
            <div className={styles.text}>
              Approve your kyc to get your API key.
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          data-aos="zoom-out"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1600"
        >
          <div
            className={styles.imgWrapper}
            style={{
              backgroundImage: isDesktop
                ? `url(${step3Img})`
                : `url(${mobStep3Img})`,
            }}
          >
            <div className={styles.text}>
              Get API Key to integrate the API on your website to get Funds.
            </div>
          </div>
        </div>
        <div
          className={styles.card}
          style={{ width: isDesktop && "20%" }}
          data-aos="zoom-out"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <div
            className={styles.imgWrapper}
            style={{
              width: isDesktop ? "100%" : "100%",
              height: isDesktop ? "100%" : "80%",
              backgroundImage: isDesktop
                ? `url(${step4Img})`
                : `url(${mobStep4Img})`,
            }}
          >
            <div
              className={styles.text}
              style={{
                width: isDesktop ? "80%" : "65%",
                paddingTop: isDesktop && "60%",
                paddingRight: isDesktop && "0%",
              }}
            >
              Have full access to your dashboard. Send and Receive funds and
              view all the information.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkFlow;
