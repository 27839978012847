import React, { Fragment } from "react";
// import { ProfileInfoForm } from "./ProfileInfoForm";
import { CompanyInfoForm } from "./CompanyInfoForm";
import { AddressInfoForm } from "./AddressInfoForm";
import useKYCProfile from "../hooks/useKYCProfile";
import { useSelector } from "react-redux";
import { BottomNav } from "../index";
import ConfirmIdentity from "./ConfirmIdentity";

function BasicInfoForm({ tab, setTab }) {
  const user = useSelector((state) => state.profile.user);

  let {
    setPhoto,
    firstName,
    lastName,
    otherName,
    email,
    phone,
    dob,
    file,
    countryCode,
    countryDial,
    state,
    city,
    postalCode,
    address,
    firstNameVal,
    lastNameVal,
    otherNameVal,
    companyName,
    companyNameVal,
    companyWebsite,
    companyWebsiteVal,
    businessName,
    emailVal,
    phoneVal,
    dobVal,
    countryCodeVal,
    stateVal,
    cityVal,
    postalCodeVal,
    fileVal,
    addressVal,
    handleInputChange,
    submit,
    submitting,
    status,
    isConfirmOpen,
    confirmMsgOpen,
    sendingEmail,
    sendingMsg,
    verifying,
    showAlert,
    closeMsgConfirm,
    closeConfirm,
    sendConfirmEmail,
    sendConfirmMsg,
    verifyEmail,
    verifyPhone,
    handleCountryChange
  } = useKYCProfile(user, setTab, tab);

  return (
    <Fragment>
      <div className="flex flex-col mt-6 flex-1 justify-between	">
        <form onSubmit={submit}>
        <CompanyInfoForm
            handleInputChange={handleInputChange}
            companyName={companyName}
            companyNameVal={companyNameVal}
            companyWebsite={companyWebsite}
            companyWebsiteVal={companyWebsiteVal}
            businessName={businessName}
            showAlert={showAlert}
            setPhoto={setPhoto}
            status={status}
          />
          <AddressInfoForm
            handleInputChange={handleInputChange}
            handleCountryChange={handleCountryChange}
            email={email}
            phone={phone}
            countryCode={countryCode}
            state={state}
            city={city}
            postalCode={postalCode}
            address={address}
            emailVal={emailVal}
            phoneVal={phoneVal}
            dobVal={dobVal}
            countryCodeVal={countryCodeVal}
            stateVal={stateVal}
            cityVal={cityVal}
            postalCodeVal={postalCodeVal}
            addressVal={addressVal}
            sendConfirmEmail={sendConfirmEmail}
            sendingEmail={sendingEmail}
            sendConfirmMsg={sendConfirmMsg}
            sendingMsg={sendingMsg}
            countryDial={countryDial}
            isPhoneVerified={user.isPhoneVerified}
            isEmailVerified={user.isEmailVerified}
          />
        </form>
        <BottomNav
          tab={tab}
          setTab={setTab}
          submit={submit}
          loading={submitting}
          status={user.status}
          edit={false}
        />
      </div>
      <ConfirmIdentity
        isOpen={isConfirmOpen}
        verifying={verifying}
        title="Email Address Verification"
        onReSend={sendConfirmEmail}
        onClose={closeConfirm}
        onSubmit={verifyEmail}
        reSending={sendingEmail}
      />
      <ConfirmIdentity
        isOpen={confirmMsgOpen}
        verifying={verifying}
        title="Phone Number Verification"
        onReSend={sendConfirmMsg}
        onClose={closeMsgConfirm}
        onSubmit={verifyPhone}
        reSending={sendingMsg}
      />
    </Fragment>
  );
}

export default BasicInfoForm;
