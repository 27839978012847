import React from "react";
import LandingPage from "../../pages/landingpage";
import Login from "../../pages/login";
import Register from "../../pages/register";
import ResetPassword from "../../pages/resetpassword";
import ImportWallet from "../../pages/importWallet";
import NewWallet from "../../pages/newWallet";
import Dashboard from "../../pages/dashboard";
import InstantReport from "../../pages/instantReport";
import EscrowReport from "../../pages/escrowReport";
import WalletDashboard from "../../pages/walletDashboard";
import PaymentPlan from "../../pages/paymentplan";
import UnderConstruction from "../../pages/underConstruction";
import Settings from "../../pages/settings";
import ApiKey from "../../pages/apiKey";
import Terms from "../../pages/TermOfUse";
import Privacy from "../../pages/PrivacyPolicy";
import Disclaimer from "../../pages/Disclaimer";
import contactUs from "../../pages/Support";
import KycPage from "../../pages/kyc";
import webHook from "../../pages/webhook";
import { Route } from "react-router-dom";
import PrivateRoute from "../navigation/PrivateRoute";
import Ecommerce from "../../pages/ecommerce";
const MainNav = () => {
  return (
    <>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/forgotPassword" component={ResetPassword} />
      <PrivateRoute exact path="/importWallet" component={ImportWallet} />
      <PrivateRoute exact path="/newWallet" component={NewWallet} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/instant-report" component={InstantReport} />
      <PrivateRoute exact path="/escrow-report" component={EscrowReport} />
      <PrivateRoute exact path="/wallet" component={WalletDashboard} />
      <PrivateRoute exact path="/subscription" component={PaymentPlan} />
      <Route exact path="/under-construction" component={UnderConstruction} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/api-key" component={ApiKey} />
      <PrivateRoute exact path="/terms" component={Terms} />
      <PrivateRoute exact path="/privacy" component={Privacy} />
      <PrivateRoute exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/contactUs" component={contactUs} />
      <Route exact path="/e-commerce" component={Ecommerce} />
      <Route exact path="/webhook" component={webHook} />
      <PrivateRoute exact path="/kyc-profile" component={KycPage} />
    </>
  );
};

export default MainNav;
