import * as React from "react";

function useMediaQuery(size, def) {
  const [isDesktop, setVisible] = React.useState(def);

  React.useEffect(() => {
    setVisible(window.innerWidth > size);
    window
      .matchMedia(`(min-width: ${size}px)`)
      .addListener((e) => setVisible(e.matches));
  }, []);

  return isDesktop;
}

export default useMediaQuery;