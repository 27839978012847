import React, { useEffect, useState } from "react";
import GenericModal from "../../common/genericModal/index";
import styles from "./instantFee.module.scss";
import { Button } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "../../common/CustomInput";
import { faDownload, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { getJWT } from "../../../services/httpServices";
import { BASE_URL } from "../../../utils/AppConstants";
import { useSelector } from "react-redux";

const EscrowFee = ({ isOpen, toggle }) => {
  let { wallet } = useSelector((state) => state);
  const [state, setState] = useState({
    fixedFee: "",
    percentageFee: "",
  });
  const [submitting, setSubmitting] = useState(false);

  let renderBtn = () => {
    if (submitting) {
      return (
        <div>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...
        </div>
      );
    }
    return <div> Update</div>;
  };
  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = async () => {
    setSubmitting(true);
    try {
      const data = {
        userID: wallet?.key?.public,
        fee: {
          fixedFee: +state.fixedFee,
          percentageFee: +state.percentageFee,
        },
      };
      const token = await getJWT();
      await Axios.post(`${BASE_URL}/settings?isInstant=${true}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      });
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    isOpen && fetchData();
  }, [isOpen]);
  const fetchData = async () => {
    const token = await getJWT();
    let res = await Axios.get(
      `${BASE_URL}/settings?isInstant=${true}&userID=${wallet?.key?.public}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      }
    );
    setState((prev) => ({
      ...prev,
      fixedFee: res.data.fixedFee,
      percentageFee: res.data.percentageFee,
    }));
  };
  return (
    <>
      <GenericModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainHeading}>Instant Fee</div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>Fixed Fee</div>
          <CustomInput
            placeholder={"Fixed Fee"}
            value={state.fixedFee}
            onChange={handleInputChange}
            // valMsg={passwordValidation}
            name="fixedFee"
            type="number"
          />
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>Percentage Fee</div>
          <CustomInput
            placeholder={"Percentage Fee"}
            value={state.percentageFee}
            onChange={handleInputChange}
            // valMsg={passwordValidation}
            name="percentageFee"
            type="number"
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
            onClick={submitHandler}
          >
            {renderBtn()}
          </Button>
        </div>
      </GenericModal>
    </>
  );
};

export default EscrowFee;
