export const orderHistory=[
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
  {
    "Order Number": "1234",
    "Ref Order Number": "090988",
    "Total Amount": "$101",
    "Merchant commission": "1%",
    "G Pay Commission": "1%",
    "Total Fee": "2%",
  },
];

export const report=[
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "pending",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "pending",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "pending",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "success",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "success",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "success",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "pending",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "pending",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "pending",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "fail",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "fail",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "fail",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "fail",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "fail",
    },
    {
      "Order Number": "1234",
      "Ref Order Number": "090988",
      "Total Amount": "$101",
      "Merchant commission": "1%",
      "G Pay Commission": "1%",
      "Total Fee": "2%",
      "Status": "success",
    },

]
export const coin = [
  {
    "Coin": { name: "Level G", image: "/assets/levelG.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
  {
    "Coin":  { name: "USDT", image: "/assets/usdt.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
  {
    "Coin":  { name: "BTC", image: "/assets/btc.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
  {
    "Coin": { name: "Ethereum", image: "/assets/ethereum.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
  {
    "Coin":  { name: "Litecoin", image: "/assets/litecoin.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
  {
    "Coin": { name: "Shiba", image: "/assets/shiba.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
  {
    "Coin": { name: "Kishu", image: "/assets/kishu.svg" },
    "Total": "0.000000",
    "Available": "0.000000",
    "USD Value": "0.000000",
    "BTC Value": "0.000000",
  },
]
export const payment = [
  {
    "Title": "Silver",
    "Customer Name": "John ",
    "Start Date": "01/09/2021",
    "End Date": "01/10/2021",
    "Status": "Paid",
  },
  {
    "Title": "Premium",
    "Customer Name": "John ",
    "Start Date": "01/09/2021",
    "End Date": "01/10/2021",
    "Status": "Paid",
  },
  {
    "Title": "Free",
    "Customer Name": "John ",
    "Start Date": "01/09/2021",
    "End Date": "01/10/2021",
    "Status": "Free Trail",
  },
  {
    "Title": "Silver",
    "Customer Name": "John ",
    "Start Date": "01/09/2021",
    "End Date": "01/10/2021",
    "Status": "Paid",
  },
  {
    "Title": "Free",
    "Customer Name": "John ",
    "Start Date": "01/09/2021",
    "End Date": "01/10/2021",
    "Status": "Paid",
  },
  {
    "Title": "Silver",
    "Customer Name": "John ",
    "Start Date": "01/09/2021",
    "End Date": "01/10/2021",
    "Status": "Pending",
  },
]

export const apiKey = [
  {
    "Title": "Amazon",
    "Type": "Escrow",
    "Api Key": "905b3439-7b8f-4abf-86d2-b643bf5f8d8f",
    "action":"true"
  },
  {
    "Title": "Daraz",
    "Type": "Instant",
    "Api Key": "905b3439-7b8f-4bbf-86d2-b643bf5f8d8f",
    "action":"true"
  },
  {
    "Title": "Ebay",
    "Type": "Escrow",
    "Api Key": "905b3439-7b8f-4cbf-86d2-b64eee5f8d8f",
    "action":"false"
  },
]
