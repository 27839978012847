import * as React from "react";
import {
  profileStDetail,
  profileStatus,
  profileStatusDesc,
} from "../../../utils/constants";
import { BottomNav } from "../index";
import statusImg from "../../../assets/images/name.png";

export const UploadedDocInfo = ({ user, tab, setTab, status }) => {
  const profileColor = {
    approved: "green",
    declined: "red",
    draft: "accent",
    pending: "accent",
  };
  const getStatus = () => {
    if (status === profileStatus.draft && user) {
      return "Under Review";
    }
    return profileStDetail[status];
  };
  const getStatusDesc = () => {
    if (status === profileStatus.draft && user) {
      return "Your KYC is Under Review";
    }
    return profileStatusDesc[status];
  };

  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="">
        <div
          className={`border border-${profileColor[status]} border-dashed rounded p-4 mt-16 flex items-center`}
        >
          <img src={statusImg} className="h-10 w-10" />
          <div>
            <div className="flex text-base text-white ml-2">
              <h1>Status:</h1>
              <h1 className={`text-${profileColor[status]} ml-1`}>
                {getStatus()}
              </h1>
            </div>
            <p className="text-xs text-secondaryGray ml-2">
              {/* Your application is rejected due to following reasons. */}
              {getStatusDesc()}
              {profileStDetail[status] == "rejected" && user?.adminNotes}
            </p>
          </div>
        </div>
        {user?.adminNotes && status === profileStatus.rejected && (
          <div className="mt-4">
            <label className="text-accent">Reason:</label>
            <label className="text-secondaryGray text-xs ml-2">
              {user?.adminNotes}
            </label>
          </div>
        )}
      </div>
      {status === profileStatus.rejected && (
        <BottomNav
          tab={tab}
          setTab={setTab}
          submit={() => {}}
          loading={false}
          status={status}
          edit={true}
        />
      )}
    </div>
  );
};

/**
 * <div className="md:flex lg:flex xl:flex justify-between py-1">
        <div className="flex">
          <img src={Images.fileIcon} className="h-5 w-4" />
          <div className="ml-3">
            <p className="text-white text-sm font-regular">
              {profile.user.document.backUrl}
            </p>
            <p className="text-secondaryGray text-xs font-regular">
              {capitalize(profile.user.document.docType)}
            </p>
          </div>
        </div>
        <div>
          <div className="ml-6 mt-4 md:ml-0 md:mt-0 lg:ml-0 lg:mt-0 xl:ml-0 xl:mt-0 flex items-center">
            <p className="text-secondaryGray mr-1 text-xs font-regular">
              Identity Status:
            </p>
            <div className="flex items-center">
              {profile.user.status=== profileStatus.approved && <img src={Images.verifiedIcon} className="h-4 w-4" />}
              {profile.user.status === profileStatus.draft && <FontAwesomeIcon icon={faSync} color="#FFA500" size="sm" />}
              <p className="text-white text-sm font-regular ml-1">{capitalize(profile.user.status)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex lg:flex xl:flex justify-between border-t border-secondaryGray ml-6">
        <div className="mt-2">
          <p className="text-secondaryGray text-xs font-regular">
            Identity verification country
          </p>
          <p className="text-white text-xs font-regular">{profile.user.document.country}</p>
        </div>
        <div className="mt-2">
          <p className="text-secondaryGray text-xs font-regular">
            Real Name on Document
          </p>
          <p className="text-white text-xs font-regular">{profile.user.firstName}</p>
        </div>
        <div className="mt-2 md:mr-40 lg:mr-40 xl:mr-40">
          <p className="text-secondaryGray text-xs font-regular">
            Document Number
          </p>
          <p className="text-white text-xs font-regular">{profile.user.document.number}</p>
        </div>
      </div>
 */
