import exclaimGray from "../../assets/images/ExclamGray.svg";
import exclaimWhite from "../../assets/images/ExclamWhite.svg";
import exclaimGreen from "../../assets/images/ExclamGreen.svg";
import verifyGray from "../../assets/images/verificGray.svg";
import verifyWhite from "../../assets/images/verificWhite.svg";
import verifyGreen from "../../assets/images/verificGreen.svg";
import docGray from "../../assets/images/DocGray.svg";
import docWhite from "../../assets/images/DocWhite.svg";
import docGreen from "../../assets/images/DocGreen.svg";
import QuestionGray from "../../assets/images/QuestionGray.svg";
import QuestionGreen from "../../assets/images/QuestionGreen.svg";
import verificationGreen from "../../assets/images/verify_green.svg";
import verificationWhite from "../../assets/images/verify_white.svg";

const coinIcons = {
  exclaimGray,
  exclaimWhite,
  exclaimGreen,
  verifyGray,
  verifyWhite,
  verifyGreen,
  docGray,
  docWhite,
  docGreen,
  QuestionGray,
  QuestionGreen,
  verificationWhite,
  verificationGreen
};

export default coinIcons;
