import React, { useEffect, useRef, useState } from "react";
import styles from "./notification.module.scss";
import NotificationIcon from "../../../assets/images/notification.svg";
// import NotificationImag from "../../../Assets/image/notification-image.svg";
// import { HTTP_CLIENT } from "../../../utils/services";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../../utils/AppConstants";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSyncAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
const Notification = (props) => {
  const ref = useRef(null);
  const [notification, setnotification] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  let { wallet } = useSelector((state) => state);

  useEffect(() => {
    fetchNotifications();
  }, [notification]);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setnotification(notification);
      }
    };
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);
  const fetchNotifications = async () => {
    try {
      let response = await axios.get(
        `${BASE_URL}/keys-management/notification/${wallet?.key?.public}`
      );
      setNotificationData(response.data);
    } catch (error) {}
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper} ref={ref}>
          <div
            className={styles.notification}
            onClick={() => setnotification(!notification)}
          >
            <img src={NotificationIcon} alt="Notification" />
            {false ? (
              <div className={styles.notificationbadge}>
                <span></span>
              </div>
            ) : (
              ""
            )}
          </div>

          {notification ? (
            <div className={styles.bellmessage}>
              <div className={styles.heading}>
                <span>Notifications</span>
              </div>

              {!notificationData.length ? (
                <div className={styles.emptyContainer}>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className={styles.empty}
                  />
                  <span className={styles.emptyText}>No Data Available</span>
                </div>
              ) : (
                notificationData?.map((items, index) => {
                  return (
                    <div className={styles.card} key={index}>
                      {/* <div className={styles.left}>
                        <img src={NotificationImag} alt="Notification" />
                      </div> */}
                      <div className={styles.rightSide} key={index}>
                        <p>{items.message}</p>
                        {/* <h6>Yogle Ishmada</h6> */}
                        <label>
                          {moment(items?.createdAt).format("MMMM Do YYYY")}
                        </label>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
