import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { authenticate } from "../../services/authServces";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { savePassword } from "../../store/actions/AuthActions";

const useLogin = () => {
  const { key } = useSelector((state) => state.wallet);
  let [inputs, setInputs] = useState({
    password: "",
    passwordValidation: "",
  });
  let [submitting, setSubmitting] = useState(false);
  let alert = useAlert();
  let history = useHistory();
  const dispatch = useDispatch();

  let handleInputChange = (e) => {
    let { value } = e.target;

    setInputs((prev) => ({ password: value, passwordValidation: "" }));
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    setSubmitting(true);

    let res = authenticate(inputs.password, key);
    if (res === "LOCAL_LOGIN") {
      dispatch(savePassword(inputs.password));
      history.replace("/dashboard");
    }
    if (res === "CREATE_WALLET") {
      dispatch(savePassword(inputs.password));
      history.push("/newWallet");
    }
    if (res === "TRY_AGAIN") {
      alert.removeAll();
      alert.show("Incorrect password please try again.", { type: "error" });
    }
    if (res === "CREATE_ACCOUNT") {
      alert.removeAll();
      alert.show("Create new password.", { type: "error" });
      history.push("/register");
    }
    setSubmitting(false);
  };

  let isValidated = () => {
    let response = true;

    if (!inputs.password) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        passwordValidation: "Password is required.",
      }));
    }

    if (submitting) {
      response = false;
    }

    return response;
  };

  return { ...inputs, handleInputChange, handleSubmit, submitting };
};

export default useLogin;
