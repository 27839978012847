import * as React from "react";
import { InputStyle, InputWidthfull } from "../../styles/CustomInputStyles";
import { genericFontStyle } from "../../styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import Copy from "../../assets/images/copy.svg";
import checkedIcon from "../../assets/images/checked_icon.png";
import copy from "copy-to-clipboard";

const CustomInput = (props) => {
  const { labelStyle, inputStyle = [""], name } = props;
  const [type, setType] = React.useState(props.type);
  let [copied, setCopied] = React.useState(false);

  const copyT = () => {
    copy(props.publicKey);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 6000);
  };

  return (
    <div
      className={`${InputWidthfull} ${inputStyle} ${
        props.bottom ? "border-b border-white" : ""
      }`}
    >
      <label
        className={genericFontStyle + " " + labelStyle}
        style={{
          display: props.label ? "flex" : "none",
          color: props.labelColor,
        }}
      >
        {props.label}
      </label>
      <div className="flex items-center w-full">
        {props.leading}
        <div
          className={`flex bg-primary w-full rounded  ${
            props.valMsg && "border border-red"
          }`}
          style={{
            marginTop: props.marginTop || props.marginVertical,
            marginBottom: props.marginBottom || props.marginVertical,
            paddingTop: props.paddingTop || props.paddingVertical,
            paddingBottom: props.paddingBottom || props.paddingVertical,
            backgroundColor: props.inputColor,
          }}
        >
          <input
            class={props.classs}
            style={{ padding: props.padding ?? "1.25rem" }}
            className={`${InputStyle} text-s`}
            placeholder={props.placeholder}
            name={name}
            type={type}
            value={props.value}
            onChange={props.onChange}
            autoComplete="off"
            disabled={props.disabled}
            readOnly={props.readOnly}
          ></input>
          {props.copy ? (
            <img onClick={copyT} class="w-3 mr-4 cursor-pointer" src={Copy} />
          ) : (
            ""
          )}
          {props.copy && copied ? (
            <div className="w-20 mx-auto">
              <div className="flex items-center pt-5 md:pt-5 mr-3">
                {/* <img src={checkedIcon} className="h-3 w-3 mr-2" /> */}
                <p className="text-lightGreen text-center text-xxxl md:text-sm lg:text-sm xl:text-sm">
                  Copied
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          {props.showIcon ? (
            <FontAwesomeIcon
              icon={type == "password" ? faEye : faEyeSlash}
              onClick={() => setType(type == "password" ? "text" : "password")}
              color="#ffffff"
              className={
                props.iconName
                  ? " h-4 w-3 self-center mr-5"
                  : "h-4 w-6 mr-5 self-center"
              }
            />
          ) : null}
          {props.searchIcon ? (
            <FontAwesomeIcon
              icon={faSearch}
              color="#ffffff80"
              className={
                props.iconName
                  ? " h-4 w-3 self-center mr-5"
                  : "h-4 w-6 mr-5 self-center"
              }
            />
          ) : null}
        </div>
        {props.trailing}
      </div>
      <small className="text-red italic">{props.valMsg}</small>
    </div>
  );
};
export default CustomInput;
