import React, { useState, useEffect } from "react";
import CommonTable from "../../components/common/CommonTable/CommonTable";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import TabNav from "../common/tabNav";
import styles from "./wallet.module.scss";
import { useSelector } from "react-redux";
import styles2 from "../../components/common/CommonTable/CommonTable.module.scss";
import importantMsg from "../../assets/images/important-message.svg";

const WalletDashboard = () => {
  let { coins } = useSelector((state) => state);
  const [totalBalance, setTotalBalance] = useState(0);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    let totalbal = 0;
    coins.coinsData.map((item) => {
      totalbal = totalbal + +item.balance * +item.current_price;
    });
    setTotalBalance(totalbal);
  }, []);
  const column = [
    {
      title: "Coin",
      key: "asset_code",
      render: (item, data) => {
        return (
          <span className={styles2.coinWrapper}>
            <img src={`/assets/${item ? item : "XLM"}.svg`} />
            <div>{item ? item : "XLM"}</div>
          </span>
        );
      },
    },
    {
      title: "Total",
      key: "balance",
      render: (item, data) => {
        let datee = Number(data?.balance);
        return (
          <span className={styles2.coinWrapper}>
            <div>{datee?.toFixed(4)}</div>
          </span>
        );
      },
    },
    // {
    //   title: "Available",
    //   key: "selling_liabilities",
    //   render: (item, data) => {
    //     return (
    //       <span className={styles2.coinWrapper}>
    //         <div>{(data?.balance - data?.selling_laibilities).toFixed(4)}</div>
    //       </span>
    //     );
    //   },
    // },
    {
      title: "USD Value",
      key: "current_price",
      render: (item, data) => {
        return (
          <span className={styles2.coinWrapper}>
            <div>
              {((data?.balance - data?.selling_laibilities) * item).toFixed(4)}
            </div>
          </span>
        );
      },
    },
    // {
    //   title: "BTC Value",
    //   key: "BTC Value",
    //   render: (item, data) => {
    //     return (
    //       <span className={styles2.coinWrapper}>
    //         <div>0.000</div>
    //       </span>
    //     );
    //   },
    // },
    {
      title: "Action",
      key: "Action",
      render: (item, data) => {
        return (
          <span className={styles2.coinWrapper}>
            <span
              className={styles2.text}
              onClick={() => openInNewTab("https://gwallet.tech/")}
            >
              Go to Gwallet for Details
            </span>
          </span>
        );
      },
    },
  ];
  return (
    <>
      <SideBarWrapper>
        <TabNav />
        <div className={styles.container}>
          {!coins?.coinsData ||
            (coins?.coinsData?.length === 0 && (
              <div className={styles.activateAccount}>
                <img src={importantMsg} className={styles.img} />
                <label className={styles.text}>
                  <a
                    target="_blank"
                    href="https://laboratory.stellar.org/#account-creator?network=test"
                  >
                    Activate your wallet before proceeding
                  </a>
                </label>
              </div>
            ))}
          <div className={styles.tabWrapper}>
            <div className={styles.left}>
              <div className={styles.label}>Estimated Balance</div>
              <div className={styles.amount}>
                <div className={styles.btc}>{totalBalance?.toFixed(4)}</div>
                <div className={styles.usd}>~ USDT</div>
              </div>
            </div>
            {/* <div className={styles.right}>
              <div className={styles.buttonWrapper}>
                <button className={styles.button}>
                  <span className={styles.text}>
                    Check Your Merchant Account detail
                  </span>
                </button>
              </div>
            </div> */}
          </div>

          <CommonTable
            column={column}
            heading=""
            maxHeight={"410px"}
            data={coins.coinsData}
          />
        </div>
      </SideBarWrapper>
    </>
  );
};

export default WalletDashboard;
