import * as React from "react";
import CustomInput from "../../common/CustomInput";
import { colors } from "../../../styles";
import { DropdownGray, DropdownCountry } from "../../common/DropdownGray";
import { countries } from "../../../utils/countries";
import Loader from "react-loader-spinner";
import Icon from "../kycIcons";

export const AddressInfoForm = ({
  handleInputChange,
  email,
  phone,
  countryCode,
  state,
  city,
  postalCode,
  address,
  emailVal,
  phoneVal,
  dobVal,
  countryCodeVal,
  stateVal,
  cityVal,
  postalCodeVal,
  addressVal,
  sendingEmail,
  sendConfirmEmail,
  sendingMsg,
  sendConfirmMsg,
  handleCountryChange,
  countryDial,
  isEmailVerified,
  isPhoneVerified,
}) => {
  const btnStyle = { paddingTop: "3.3px", paddingBottom: "3.3px" };
  const getTrailing = (isSending, onSend, isVerified) => (
    <button
      type="button"
      className="focus:outline-none bg-secondaryGray px-2"
      onClick={onSend}
      style={btnStyle}
    >
      {!isSending && (
        <img
          src={isVerified ? Icon.verificationGreen : Icon.verificationWhite}
          className="h-8"
        />
      )}
      {isSending && (
        <Loader type="Bars" color="#ffffff" height={31} width={20} />
      )}
    </button>
  );

  const getLeading = () => (
    <div
      className="bg-primary border-r-2 border-background px-2 flex text-secondaryGray text-xs items-center"
      style={dial_code}
    >
      {countryDial}
    </div>
  );
  return (
    <div className="mt-4 mb-4">
      <p className="text-white font-regular mb-2 text-xs md:text-xl lg:text-2xl xl:text-2xl">
        Contact Info
      </p>
      <div className="items-center justify-between">
        <div className="lg:flex items-center">
          <div className="xl:w-3/6 lg:mr-1 w-full">
            <DropdownCountry
              label="Country"
              textMd={true}
              value={countryCode}
              name="countryCode"
              validation={countryCodeVal}
              onChange={handleCountryChange}
              data={countries}
              disabled={isPhoneVerified}
            />
          </div>
          <div className="xl:w-3/6 lg:ml-1 w-full flex">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Phone Number"
              name="phone"
              type="number"
              value={phone}
              onChange={handleInputChange}
              valMsg={phoneVal}
              marginVertical="2px"
              padding={"12px"}
              placeholder="Enter your phone number here"
              trailing={getTrailing(
                sendingMsg,
                sendConfirmMsg,
                isPhoneVerified
              )}
              leading={getLeading()}
            />
          </div>
        </div>
        <div className="lg:flex items-center">
          <div className="xl:w-3/6 lg:mr-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Email"
              name="email"
              value={email}
              onChange={handleInputChange}
              valMsg={emailVal}
              marginVertical="2px"
              padding={"12px"}
              placeholder="Enter your email here"
              trailing={getTrailing(
                sendingEmail,
                sendConfirmEmail,
                isEmailVerified
              )}
            />
          </div>
          <div className="xl:w-3/6 lg:ml-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Address"
              name="address"
              value={address}
              onChange={handleInputChange}
              valMsg={addressVal}
              marginVertical="2px"
              padding={"12px"}
              placeholder="Please enter your address"
            />
          </div>
        </div>
        <div className="lg:flex items-center">
          <div className="xl:w-3/6 lg:mr-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Postal Code"
              name="postalCode"
              value={postalCode}
              onChange={handleInputChange}
              valMsg={postalCodeVal}
              marginVertical="2px"
              padding={"12px"}
              placeholder="Enter your postal code"
            />
          </div>
          <div className="xl:w-3/6 lg:ml-1 w-full flex">
            <div className="w-full mr-2">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="State/Province"
                name="state"
                value={state}
                onChange={handleInputChange}
                valMsg={stateVal}
                marginVertical="2px"
                padding={"12px"}
                placeholder="Please enter state or province name"
              />
            </div>
            <div className="w-full">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="City"
                name="city"
                value={city}
                onChange={handleInputChange}
                valMsg={cityVal}
                marginVertical="2px"
                padding={"12px"}
                placeholder="Enter your city name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dial_code = { height: "38.7px" };
