import React from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import useConfirm from "../hooks/useConfirm";

function ConfirmIdentity({
  isOpen,
  onClose,
  reSending,
  onSubmit,
  onReSend,
  title,
  verifying,
}) {
  let {
    input1,
    input2,
    input3,
    input4,
    inputVal,
    tick,
    setRef,
    handleInputChange,
    submit,
    handleEmptyInput,
  } = useConfirm(isOpen, onSubmit);

  const renderInput = (name, value, ref) => (
    <input
      type="number"
      name={name}
      ref={ref}
      value={value}
      autoComplete="off"
      onKeyDown={handleEmptyInput}
      onChange={handleInputChange}
      className="text-2xl py-1 px-3 bg-background rounded text-white focus:outline-none w-10 mr-4"
    />
  );

  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <div className="flex justify-end">
        <button onClick={onClose}>
          <img src={"/assets/close_button.png"} className="h-6 w-6" />
        </button>
      </div>
      <div className="flex flex-col items-center">
        <h2 className="text-accent text-lg font-semibold text-center mb-2">
          {title}
        </h2>
        <div className="flex w-full justify-center">
          {renderInput("input1", input1, (ref) => setRef(ref, 1))}
          {renderInput("input2", input2, (ref) => setRef(ref, 2))}
          {renderInput("input3", input3, (ref) => setRef(ref, 3))}
          {renderInput("input4", input4, (ref) => setRef(ref, 4))}
        </div>
        <p className="text-red text-sm italics mt-2">{inputVal}</p>
        <button
          onClick={submit}
          className="focus:outline-none py-2 px-4 bg-green text-sm mt-8 text-white"
        >
          {verifying ? (
            <Loader type="Bars" color="#ffffff" height={20} width={20} />
          ) : (
            "Submit OTP"
          )}
        </button>
        <div className="text-white flex text-xs my-6">
          Didn't Receive OTP Code.
          <button
            className={`ml-1 focus:outline-none ${
              tick < 1 ? "text-green" : "text-secondaryGray"
            }`}
            onClick={onReSend}
            disabled={tick > 0 || reSending}
          >
            {reSending ? "...Sending" : "Send Again"}
            {tick > 0 && "(" + tick + "s)"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmIdentity;

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    zIndex: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: "10px",
    backgroundColor: "#232321",
    width: "30%",
    border: "0 px",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "auto",
  },
};
