import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button } from "../../common";
import GenericModal from "../../common/genericModal";
import styles from "./Plans.module.scss";
import circle from "../../../assets/images/check-circle.svg";
import { BASE_URL } from "../../../utils/AppConstants";
import Axios from "axios";
import { useSelector } from "react-redux";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../common/CustomInput";
import copy from "copy-to-clipboard";

const Plans = ({
  isOpen,
  toggle,
  toggleMsg,
  data,
  selected,
  availableBal,
  fetchDataPlans,
}) => {
  let { wallet } = useSelector((state) => state);
  const [submitting, setSubmitting] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [toAddress, setToAddress] = useState("");
  const [memo, setMemo] = useState("");
  const [payableAmount, setPayableAmount] = useState("");
  const [copied, setCopied] = useState("");

  const copyKey = (apiKey) => {
    copy(apiKey);
    setCopied(apiKey);
  };

  let renderBtn = () => {
    if (submitting) {
      return (
        <div>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...
        </div>
      );
    }
    return "Subscribe";
  };
  let renderBtnCopy = (item) => {
    return copied.length > 0 && copied === item ? "Copied" : "Copy";
  };
  const submitHandler = async (data) => {
    let totalAmount = data?.price * selected?.substr(0, selected.indexOf(" "));
    setSubmitting(true);
    try {
      let res = await Axios.post(`${BASE_URL}/plans-subscription`, {
        planId: data._id,
        userId: wallet?.key?.public,
        payableAmount: totalAmount,
        duration: selected,
        planType: data.title,
      });
      if (res.data.status == "active") {
        fetchDataPlans();
        setSubmitting(false);
        toggle();

        return;
      }
      if (res) {
        //
        fetchDataPlans();
        setToAddress(res.data.toAddress);
        setMemo(res.data.memo);
        setPayableAmount(res.data.payableAmount);
      }
      setSubmitting(false);
      setSubscribe(true);
    } catch (error) {
      toggleMsg("Failed");
      setSubmitting(false);
    }
  };
  const paymentHandler = () => {
    // toggle();
    // setSubscribe(false);
    const newWindow = window.open(
      `https://testnet.gwallet.tech/`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <GenericModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setSubscribe(false);
        }}
        style={{ backgroundColor: "#1A1B16!important" }}
      >
        <div className={styles.mainHeading}>{data?.title}</div>
        {subscribe ? (
          <>
            <div className={styles.fieldWrapper2}>
              <div className={styles.inputWrapper}>
                <CustomInput
                  labelStyle={"mb-0"}
                  placeholder="Payable Address"
                  inputStyle={"rounded"}
                  padding={"1rem"}
                  value={toAddress}
                  readOnly
                  name="key"
                  type="text"
                  label="Payable Address"
                />
              </div>
              <div className={styles.buttonWrapperCopy}>
                <Button
                  type="submit"
                  styles={[
                    "bg-accent text-xs py-3.5 mt-0 w-full uppercase mt-6",
                  ]}
                  onClick={() => copyKey(toAddress)}
                >
                  {renderBtnCopy(toAddress)}
                </Button>
              </div>
            </div>
            <div className={styles.fieldWrapper2}>
              <div className={styles.inputWrapper}>
                <CustomInput
                  labelStyle={"mb-0"}
                  placeholder="Memo"
                  inputStyle={"rounded"}
                  padding={"1rem"}
                  value={memo}
                  readOnly
                  name="key"
                  type="text"
                  label="Memo"
                />
              </div>
              <div className={styles.buttonWrapperCopy}>
                <Button
                  type="submit"
                  styles={[
                    "bg-accent text-xs py-3.5 mt-0 w-full uppercase mt-6",
                  ]}
                  onClick={() => copyKey(memo)}
                >
                  {renderBtnCopy(memo)}
                </Button>
              </div>
            </div>
            <div className={styles.fieldWrapper2}>
              <div className={styles.inputWrapper}>
                <CustomInput
                  labelStyle={"mb-1"}
                  placeholder="Payable Amount"
                  inputStyle={"rounded"}
                  padding={"1rem"}
                  value={
                    Number(payableAmount) -
                    Number(data.userBalance ? data.userBalance : 0)
                  }
                  readOnly
                  name="key"
                  type="text"
                  label="Payable Amount"
                />
              </div>
              <div className={styles.buttonWrapperCopy}>
                <Button
                  type="submit"
                  styles={[
                    "bg-accent text-xs py-3.5 mt-0 w-full uppercase mt-6",
                  ]}
                  onClick={() => copyKey(payableAmount)}
                >
                  {renderBtnCopy(payableAmount)}
                </Button>
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                type="submit"
                styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
                onClick={paymentHandler}
              >
                Pay in USDT
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.fieldWrapper}>
              <div className={styles.row1}>
                {/* <div className={styles.textWrapper}>
                  <img src={circle} />
                  <div className={styles.text}>
                    {data?.apisPerSecond} APIs per second
                  </div>
                </div> */}
              </div>
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.row1}>
                <div className={styles.textWrapper}>
                  <img src={circle} />
                  <div className={styles.text}>
                    Total API Keys {data?.apisPerDay}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.row1}>
                <div className={styles.textWrapper}>
                  <img src={circle} />
                  <div className={styles.text}>{selected}</div>
                </div>
              </div>
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.row2}>
                {data?.price * selected?.substr(0, selected.indexOf(" "))} USDT
              </div>
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.balanceWrapper}>
                <div className={styles.textLeft}>Available Balance:</div>
                <div className={styles.textRight}>
                  {availableBal ? availableBal?.toFixed(4) : 0} USDT
                </div>
              </div>
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.balanceWrapper}>
                <div className={styles.textLeft}>Payable Balance:</div>
                <div className={styles.textRight}>
                  {data?.userBalance ? data?.userBalance : 0} USDT
                </div>
              </div>
            </div>
            {data?.price * selected?.substr(0, selected?.indexOf(" ")) >
              availableBal && (
              <div className={styles.fieldWrapper}>
                <div className={styles.footerText}>
                  * You have Insufficent Balance
                </div>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <Button
                type="submit"
                styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
                onClick={() => submitHandler(data)}
                disabled={
                  data?.price * selected?.substr(0, selected?.indexOf(" ")) >
                  availableBal
                }
              >
                {renderBtn()}
              </Button>
            </div>
          </>
        )}
      </GenericModal>
    </>
  );
};

export default Plans;
