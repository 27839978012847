import React from "react";
import ApiKey from "../components/ApiKey";

const ApiKeyPage = () => {
  return (
    <>
      <ApiKey />
    </>
  );
};

export default ApiKeyPage;
