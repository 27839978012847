import React, { useState, useEffect } from "react";
import { apiKey } from "../../dump";
import { Button } from "../common";
import CommonTable from "../common/CommonTable/CommonTable";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import TabNav from "../common/tabNav";
import styles from "./apikey.module.scss";
import ApiKeyModal from "../../components/Modals/ApiKey/index";
import Add from "../../../src/assets/images/add.svg";
import AddActive from "../../../src/assets/images/addActive.svg";
import useApiKey from "./useApiKey";
import styles2 from "../../components/common/CommonTable/CommonTable.module.scss";
import copyIcon from "../../assets/images/copy_icon.png";
import checkedIcon from "../../assets/images/checked_icon.png";
import ToggleSwitch from "../common/ToggleSwitch/switch";

const ApiKey = () => {
  const { data, setIsGenerated, copyKey, copied, statusHandler } = useApiKey();
  const [isOpenApi, setIsOpenApi] = useState(false);

  const column = [
    {
      title: "Title",
      key: "title",
    },
    {
      title: "Type",
      key: "type",
    },
    {
      title: "Api Key",
      key: "apiKey",
      render: (item, data) => {
        return (
          <>
            <div className={styles2.coinWrapper}>
              {item.substring(0, 10) + "..."}
              {copied.length > 0 && copied === item ? (
                <div className={styles2.icon}>
                  <img src={checkedIcon} />
                  <span className={styles2.copyLabel}> Copied</span>
                </div>
              ) : (
                <div className={styles2.icon} onClick={() => copyKey(item)}>
                  <img src={copyIcon} />
                  <span className={styles2.copyLabel}> Copy</span>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    // {
    //   title: "Action",
    //   key: "active",
    //   render: (item,data) => {
    //     return (
    //       <div className={styles2.coinWrapper}>
    //         <ToggleSwitch status={item} onChange={()=>statusHandler(data.id)}/>
    //       </div>
    //     );
    //   },
    // },
  ];
  let renderBtn = () => {
    // if(submitting) {
    //  return <div> <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...</div>
    // }
    return (
      <div className={styles.apiWrapper}>
        <img src={Add} className={styles.add} />{" "}
        <img src={AddActive} className={styles.addActive} />
        Create New Api Key
      </div>
    );
  };
  const headings = ["Title", "Type", "Api Key", "Action"];
  const toggleApi = () => {
    setIsOpenApi(!isOpenApi);
  };
  return (
    <>
      <SideBarWrapper>
        <TabNav />
        <div className={styles.container}>
          <div className={styles.tabWrapper}>
            <Button
              type="submit"
              styles={[
                "bg-accent text-sm py-3.5 mt-0 w-full uppercase rounded",
              ]}
              onClick={toggleApi}
              image={Add}
              // image={AddActive}
            >
              {renderBtn()}
            </Button>
          </div>
          <div className={styles.parent}>
            <marquee
              className={styles.disclaimer}
              width="100%"
              direction="left"
              height="60%"
            >
              <span>Disclaimer : </span>
              You can create both API keys (One Instant and One Escrow), but you
              can integrate only one API key at a time at any E-Commerce
              platform.
            </marquee>
          </div>
          <CommonTable
            column={column}
            heading=""
            maxHeight={"410px"}
            data={data}
          />
        </div>
        <ApiKeyModal
          apiKeyList={data}
          isOpen={isOpenApi}
          toggle={toggleApi}
          setIsGenerated={setIsGenerated}
        />
      </SideBarWrapper>
    </>
  );
};

export default ApiKey;
{
  /* <Alert variant="filled" severity="error">
              You can create both API keys (One Instant and One Escrow), but you
              can integrate only one API key at a time at any E-Commerce
              platform.
            </Alert> */
}
