import CardImg from "../../../assets/images/one.jpg";
import cardTwo from "../../../assets/images/two.jpg";
import cardThree from "../../../assets/images/three.jpg";
//
const Data = [
  {
    id: 1,
    img: CardImg,
    title: "Formal Men Shirt",
    quantity: "12",
    Dec: "We offer Free Delivery for all the online orders within Pakistan. Charges for all International delivery vary from country to country depending upon city, state, country, and locality.",
  },
  {
    id: 2,
    img: cardTwo,
    title: "Formal Men Shirt",
    quantity: "120000000",

    Dec: "We offer Free Delivery for all the online orders within Pakistan. Charges for all International delivery vary from country to country depending upon city, state, country, and locality.",
  },
  {
    id: 3,
    img: cardThree,
    title: "Formal Men Shirt",
    quantity: "810644545",

    Dec: "We offer Free Delivery for all the online orders within Pakistan. Charges for all International delivery vary from country to country depending upon city, state, country, and locality.",
  },
];
export default Data;
