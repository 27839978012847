import React, { useState, useEffect } from "react";
import GenericModal from "../../../components/common/genericModal/index";
import styles from "./apiKey.module.scss";
import { Button } from "../../../components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "../../../components/common/CustomInput";
import {
  faDownload,
  faSyncAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
// import DropDown from "../../common/DropDown/dropDown";
import { getJWT } from "../../../services/httpServices";
import Axios from "axios";
import { BASE_URL } from "../../../utils/AppConstants";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";

const APIKey = ({
  isOpen,
  toggle,
  editwebhook,
  userid,
  recieveUpdatedData,
}) => {
  const [webhookurl, setWebHookUrl] = useState("");
  const [submitting, setSubmitting] = useState(false);
  // const [copied, setCopied] = useState(false);
  const [updateWebHook] = useState(
    "https://stagingapi.gpay.tech/settings/update-webhook"
  );
  const alert = useAlert();

  useEffect(() => {
    setWebHookUrl(editwebhook);
  }, [isOpen]);

  // let isValidated = () => {
  //   let response = true;
  //   if (!inputData.title.length > 0) {
  //     setInputData((prev) => ({ ...prev, titleVal: " Title is required." }));
  //     response = false;
  //   }
  //   return response;
  // };
  // const options = ["Escrow", "Instant"];
  let renderBtn = () => {
    if (submitting) {
      return (
        <div>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...
        </div>
      );
    }
    return <div>update</div>;
  };
  const submitHandler = async () => {
    // if(inputData.apiKey.length>0){
    //   copy(inputData.apiKey)
    //   setCopied(true)
    //   return;
    // }
    // if (!isValidated()) {
    //   return;
    // }
    setSubmitting(true);

    const data = {
      webhook: webhookurl,
      userID: userid,
    };
    try {
      let res = await Axios.put(updateWebHook, data);

      recieveUpdatedData(res.data.webhook);
      alert.show("URL Updated successfully", { type: "success" });
      toggle();
      setSubmitting(false);
    } catch {
      setSubmitting(false);
      alert.show("something went wrong", { type: "error" });
    }
  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setWebHookUrl(value);
  };
  return (
    <>
      <GenericModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainHeading}>Web Hook</div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>URL</div>
          <CustomInput
            placeholder={"url"}
            value={webhookurl}
            onChange={(e) => handleInputChange(e)}
            // valMsg={inputData.titleVal}
            name="title"
            type="text"
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="Update"
            styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
            onClick={submitHandler}
            // disabled={copied?true:false}
          >
            {renderBtn()}
          </Button>
        </div>
      </GenericModal>
    </>
  );
};

export default APIKey;
