import {
  CREATE_ACCOUNT_PENDING,
  CREATE_ACCOUNT_SUCCESS,
  SAVE_PASSWORD,
  LOGOUT_USER,
  AUTH_TOKEN,
} from "./AuthActionType";
// import { ThunkAction } from "redux-thunk";
// import { AnyAction } from "redux";
// import { BASE_URL, postOptions } from "../../utils/AppConstants";

export function savePassword(password) {
  return {
    type: SAVE_PASSWORD,
    password: password,
  };
}
export function authToken(token) {
  return {
    type: AUTH_TOKEN,
    payload: token,
  };
}
