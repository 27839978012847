import React, { useEffect, useState } from "react";
import Slider from "./menu";
import styles from "./navigation.module.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import icon_menu from "../../../assets/images/Icon-menu.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Navigation = () => {
  let history = useHistory();
  const router = useLocation();
  const [menuOpenState, setMenuOpenState] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const [checklogin, setCheckLogin] = useState(false);
  let hash = localStorage.getItem("bcrypt_hash");
  let auth = useSelector((state) => state.auth);

  useEffect(() => {
    if ((hash != "") & (auth.password != "")) {
      setCheckLogin(true);
    }
  }, [hash, auth]);

  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftWrapper}>
          {checklogin ? (
            <Link to={"/dashboard"} className={styles.logo}>
              <img src={logo} />
            </Link>
          ) : (
            <Link to={"/"} className={styles.logo}>
              <img src={logo} />
            </Link>
          )}
        </div>

        <div className={styles.middleWrapper}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <a
              className={`${
                router.pathname === "/" ? styles.activeLink : styles.menuItem
              } `}
            >
              Home
            </a>
          </Link>
          <div style={{ textDecoration: "none" }}>
            <a
              href="https://developer.gpay.tech/"
              target="_blank"
              className={`${
                router.pathname === "/1" ? styles.activeLink : styles.menuItem
              } `}
            >
              Api Docs
            </a>
          </div>

          <span
            to="/#PaymentPlan"
            style={{ textDecoration: "none" }}
            onClick={() => window.location.replace("/#PaymentPlan")}
          >
            <a
              className={`${
                router.pathname === "/pricing"
                  ? styles.activeLink
                  : styles.menuItem
              } `}
            >
              Pricing Plan
            </a>
          </span>
          <Link to="/contactUs" style={{ textDecoration: "none" }}>
            <a
              className={`${
                router.pathname === "/contactUs"
                  ? styles.activeLink
                  : styles.menuItem
              } `}
            >
              Contact Us
            </a>
          </Link>
          <Link to="/e-commerce" style={{ textDecoration: "none" }}>
            <a
              className={`${
                router.pathname === "/e-commerce"
                  ? styles.activeLink
                  : styles.menuItem
              } `}
            >
              E Commerce
            </a>
          </Link>
        </div>
        <div className={styles.rightWrapper}>
          <div
            className={styles.buttonWrapper}
            onClick={() => {
              history.push("/login");
            }}
          >
            <button className={styles.button}>
              <span className={styles.text}>Login</span>
            </button>
          </div>
          <div
            className={styles.buttonWrapper}
            onClick={() => {
              history.push("/register");
            }}
          >
            <button className={styles.button}>
              <span className={styles.text}>Sign Up</span>
            </button>
          </div>
          {/* <select className={styles.selector} name="languages" id="languages">
            <option value="English">English</option>
          </select> */}
          <div className={styles.menuIcon} onClick={handleMenu}>
            <img src={icon_menu} />
          </div>
        </div>
      </div>
      <Slider
        menuOpenState={menuOpenState}
        setMenuOpenState={setMenuOpenState}
        stateChangeHandler={stateChangeHandler}
      />
    </>
  );
};

export default Navigation;
