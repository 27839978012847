import React, { useEffect, useState } from "react";
import SideBarWrapper from "../../components/common/sidebarWrapperAdminPanel";
import TabNav from "../../components/common/tabNav";
import styles from "./webhook.module.scss";
import CustomInput from "../../components/common/CustomInput";
import Axios from "axios";
import CommonTable from "../../components/common/CommonTable/CommonTable";
import trashplan from "../../assets/images/trashplan.svg";
import editplan from "../../assets/images/editplan.svg";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ApiKeyModal from "./ApiKey/index";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils/AppConstants";

const WebHook = () => {
  const [columnData, setcolumnData] = useState([]);
  const [save, setSave] = useState(false);
  const [url, setUrl] = useState("");
  const [submitting, setsubmitting] = useState(false);
  const [savesubmitting, setsavesubmitting] = useState(false);
  const [testsubmitting, settestsubmitting] = useState(false);
  const alert = useAlert();
  const [testWebHook] = useState(
    "https://stagingapi.gpay.tech/settings/test-webhook"
  );

  const [updateWebHook] = useState(
    "https://stagingapi.gpay.tech/settings/update-webhook"
  );
  const [getWebHook] = useState("https://stagingapi.gpay.tech/settings");
  // ?id=GCQ5BEQG5UP5MGZMVUS2TIWBOYOEXGRWE3QWACKPF5DYILG6EJS5L5K6
  const [isOpenApi, setIsOpenApi] = useState(false);
  const [editwebhook, setEditwebhook] = useState("");
  const [userid, setuserId] = useState("");
  let { wallet } = useSelector((state) => state);

  const SubmitHandler = (v, name) => {
    if (name === "edit") {
      setEditwebhook(v.webhook);
      toggleApi();
    } else {
      deleteWebHook();
    }
  };

  const deleteWebHook = async (i, v) => {
    // setsubmitting(true)
    let data = {
      webhook: "",
    };
    try {
      let res = await Axios.delete(
        `${BASE_URL}/settings/delete-webhook/${wallet?.key?.public}`
      );
      if (res.status == "201" || res.status == "200") {
        alert.show("URL delete successflly", { type: "success" });
        fetchWebHook();
        setUrl("");
        //   let array = []
        //   array.push({webhook : res.data.webhook})
        // setcolumnData(array)

        // setcolumnData([...data, url])
        // setsubmitting(false)
      }
    } catch {
      alert.show("some thing went wrong", { type: "success" });
      // setsubmitting(false)
    }
  };

  const toggleApi = () => {
    setIsOpenApi(!isOpenApi);
  };

  useEffect(() => {
    if (wallet?.key?.public) {
      setsubmitting(true);

      setuserId(wallet?.key?.public);
      fetchWebHook();
      setsubmitting(false);
    }
  }, [wallet?.key?.public]);

  const fetchWebHook = async () => {
    setsubmitting(true);
    try {
      let res = await Axios.get(`${getWebHook}?id=${wallet?.key?.public}`);
      let array = [];
      let webhook = res.data.map((m) => {
        if (!m.webhook) {
          setcolumnData([]);
          setsubmitting(false);
        } else {
          array.push({ webhook: m.webhook });
          setsubmitting(false);
        }
      });
      setcolumnData(array);
      setsubmitting(false);
    } catch {
      setsubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setUrl(value);
  };

  let renderBtn = () => {
    if (savesubmitting) {
      return (
        <div style={{ color: "white" }}>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> saving..
        </div>
      );
    }
    return (
      <button
        className={save ? styles.button : styles.buttonNotALLow}
        onClick={save ? handleSave : handleUnsave}
      >
        <span className={styles.text}>Save</span>
      </button>
    );
  };

  let testBtn = () => {
    if (testsubmitting) {
      return (
        <div style={{ color: "white" }}>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> testing...
        </div>
      );
    }
    return (
      <>
        {url ? (
          <button className={styles.button} onClick={handleTest}>
            <span className={styles.text}>Test</span>
          </button>
        ) : (
          <button className={styles.buttonNotALLow}>
            <span className={styles.text}>Test</span>
          </button>
        )}
      </>
    );
  };

  const handleTest = async () => {
    settestsubmitting(true);

    let data = {
      memo: "rrindmrqhjssliev",
      memo_bytes: "cnJpbmRtcnFoanNzbGlldg==",
      _links: {
        self: {
          href: "https://horizon-testnet.stellar.org/transactions/4c2d38d5b8d1054e899ee8fd50ac9871a7a9e1c8dd4db63530250a21be4d30bd",
        },
        account: {
          href: "https://horizon-testnet.stellar.org/accounts/GBDA6K4SK4WNRNU24LRRWSS3UG3QRZYKDD2I76ZDO6FSY7WTKHUQY27F",
        },
        ledger: { href: "https://horizon-testnet.stellar.org/ledgers/378623" },
        operations: {
          href: "https://horizon-testnet.stellar.org/transactions/4c2d38d5b8d1054e899ee8fd50ac9871a7a9e1c8dd4db63530250a21be4d30bd/operations{?cursor,limit,order}",
          templated: true,
        },
        effects: {
          href: "https://horizon-testnet.stellar.org/transactions/4c2d38d5b8d1054e899ee8fd50ac9871a7a9e1c8dd4db63530250a21be4d30bd/effects{?cursor,limit,order}",
          templated: true,
        },
        precedes: {
          href: "https://horizon-testnet.stellar.org/transactions?order=asc&cursor=1626173402525696",
        },
        succeeds: {
          href: "https://horizon-testnet.stellar.org/transactions?order=desc&cursor=1626173402525696",
        },
        transaction: {
          href: "https://horizon-testnet.stellar.org/transactions/4c2d38d5b8d1054e899ee8fd50ac9871a7a9e1c8dd4db63530250a21be4d30bd",
        },
      },
      id: "4c2d38d5b8d1054e899ee8fd50ac9871a7a9e1c8dd4db63530250a21be4d30bd",
      paging_token: "1626173402525696",
      successful: true,
      hash: "4c2d38d5b8d1054e899ee8fd50ac9871a7a9e1c8dd4db63530250a21be4d30bd",
      created_at: "2022-07-15T11:09:41Z",
      source_account:
        "GBDA6K4SK4WNRNU24LRRWSS3UG3QRZYKDD2I76ZDO6FSY7WTKHUQY27F",
      source_account_sequence: "360107237965868",
      fee_account: "GBDA6K4SK4WNRNU24LRRWSS3UG3QRZYKDD2I76ZDO6FSY7WTKHUQY27F",
      fee_charged: "200",
      max_fee: "1000",
      operation_count: 2,
      envelope_xdr:
        "AAAAAgAAAABGDyuSVyzYtpri4xtKW6G3COcKGPSP+yN3iyx+01HpDAAAA+gAAUeEAAAALAAAAAEAAAAAAAAAAAAAAABi0UulAAAAAQAAABBycmluZG1ycWhqc3NsaWV2AAAAAgAAAAAAAAABAAAAAMptnfpFKJ46iCbd/uBrR7CAFadZakXepCHY4VSCOpWnAAAAAVVTRFQAAAAAgjvQUCJ3QWLEvQgKQv5QoTnVF+afntNtAwqiaWLAHOIAAAAAATjOIAAAAAAAAAABAAAAAM7m+ddbf2XvKj6ARPqUe9dj6ZdGQE90tql+sB4orxmXAAAAAVVTRFQAAAAAgjvQUCJ3QWLEvQgKQv5QoTnVF+afntNtAwqiaWLAHOIAAAAAAJiWgAAAAAAAAAAB01HpDAAAAEBOGRM1vHLaxwwgcj9+ulEapJcbPbXwtBGaSbGsrqt04dcFDNndf/s5QNo+3RfMQ2emyOFk10csr5Ao6wWKDb0F",
      result_xdr:
        "AAAAAAAAAMgAAAAAAAAAAgAAAAAAAAABAAAAAAAAAAAAAAABAAAAAAAAAAA=",
      result_meta_xdr:
        "AAAAAgAAAAIAAAADAAXG/wAAAAAAAAAARg8rklcs2Laa4uMbSluhtwjnChj0j/sjd4ssftNR6QwAAAAYBk6C+wABR4QAAAArAAAAAQAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAwAAAAAABcaKAAAAAGLRSI0AAAAAAAAAAQAFxv8AAAAAAAAAAEYPK5JXLNi2muLjG0pbobcI5woY9I/7I3eLLH7TUekMAAAAGAZOgvsAAUeEAAAALAAAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAAAAAAMAAAAAAAXG/wAAAABi0Ur1AAAAAAAAAAIAAAAEAAAAAwAFxooAAAABAAAAAEYPK5JXLNi2muLjG0pbobcI5woY9I/7I3eLLH7TUekMAAAAAVVTRFQAAAAAgjvQUCJ3QWLEvQgKQv5QoTnVF+afntNtAwqiaWLAHOIAAADWEaIikX//////////AAAAAQAAAAAAAAAAAAAAAQAFxv8AAAABAAAAAEYPK5JXLNi2muLjG0pbobcI5woY9I/7I3eLLH7TUekMAAAAAVVTRFQAAAAAgjvQUCJ3QWLEvQgKQv5QoTnVF+afntNtAwqiaWLAHOIAAADWEGlUcX//////////AAAAAQAAAAAAAAAAAAAAAwAFffUAAAABAAAAAMptnfpFKJ46iCbd/uBrR7CAFadZakXepCHY4VSCOpWnAAAAAVVTRFQAAAAAgjvQUCJ3QWLEvQgKQv5QoTnVF+afntNtAwqiaWLAHOIAAAAAGQWjwH//////////AAAAAQAAAAAAAAAAAAAAAQAFxv8AAAABAAAAAMptnfpFKJ46iCbd/uBrR7CAFadZakXepCHY4VSCOpWnAAAAAVVTRFQAAAAAgjvQUCJ3QWLEvQgKQv5QoTnVF+afntNtAwqiaWLAHOIAAAAAGj5x4H//////////AAAAAQAAAAAAAAAAAAAABAAAAAMABcb/AAAAAQAAAABGDyuSVyzYtpri4xtKW6G3COcKGPSP+yN3iyx+01HpDAAAAAFVU0RUAAAAAII70FAid0FixL0ICkL+UKE51Rfmn57TbQMKomliwBziAAAA1hBpVHF//////////wAAAAEAAAAAAAAAAAAAAAEABcb/AAAAAQAAAABGDyuSVyzYtpri4xtKW6G3COcKGPSP+yN3iyx+01HpDAAAAAFVU0RUAAAAAII70FAid0FixL0ICkL+UKE51Rfmn57TbQMKomliwBziAAAA1g/QvfF//////////wAAAAEAAAAAAAAAAAAAAAMABcaKAAAAAQAAAADO5vnXW39l7yo+gET6lHvXY+mXRkBPdLapfrAeKK8ZlwAAAAFVU0RUAAAAAII70FAid0FixL0ICkL+UKE51Rfmn57TbQMKomliwBziAAAEnouDQrh85mxQ4oQAAAAAAAEAAAAAAAAAAAAAAAEABcb/AAAAAQAAAADO5vnXW39l7yo+gET6lHvXY+mXRkBPdLapfrAeKK8ZlwAAAAFVU0RUAAAAAII70FAid0FixL0ICkL+UKE51Rfmn57TbQMKomliwBziAAAEnowb2Th85mxQ4oQAAAAAAAEAAAAAAAAAAAAAAAA=",
      fee_meta_xdr:
        "AAAAAgAAAAMABcaKAAAAAAAAAABGDyuSVyzYtpri4xtKW6G3COcKGPSP+yN3iyx+01HpDAAAABgGToPDAAFHhAAAACsAAAABAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAAAAAAAAAAAAAAAAAADAAAAAAAFxooAAAAAYtFIjQAAAAAAAAABAAXG/wAAAAAAAAAARg8rklcs2Laa4uMbSluhtwjnChj0j/sjd4ssftNR6QwAAAAYBk6C+wABR4QAAAArAAAAAQAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAwAAAAAABcaKAAAAAGLRSI0AAAAA",
      memo_type: "text",
      signatures: [
        "ThkTNbxy2scMIHI/frpRGqSXGz218LQRmkmxrK6rdOHXBQzZ3X/7OUDaPt0XzENnpsjhZNdHLK+QKOsFig29BQ==",
      ],
      valid_after: "1970-01-01T00:00:00Z",
      valid_before: "2022-07-15T11:12:37Z",
    };
    try {
      let res = await Axios.post(url, data);
      if (res.status == "201" || res.status == "200") {
        alert.show("URL Test successfully", { type: "success" });
        setSave(true);
        settestsubmitting(false);
      }
    } catch {
      settestsubmitting(false);
      alert.show("Url not valid", { type: "error" });
    }
  };

  const handleSave = async () => {
    setsavesubmitting(true);
    let data = {
      webhook: testWebHook,
      userID: wallet?.key?.public,
    };
    try {
      let res = await Axios.put(updateWebHook, data);
      if (res.status == "201" || res.status == "200") {
        alert.show("URL save successflly", { type: "success" });
        fetchWebHook();
        // setcolumnData({webhook : res.data.webhook})
        setsavesubmitting(false);
      }
    } catch {
      setsavesubmitting(false);
      alert.show("Something went wrong", { type: "error" });
    }
  };

  const handleUnsave = () => {};

  const recieveUpdatedData = (data) => {
    setcolumnData({ webhook: data });
    fetchWebHook();
  };
  const column = [
    {
      title: "Url",
      key: "webhook",
      render: (i, v) => {
        return <div style={{ fontSize: "1rem" }}>{i}</div>;
      },
      // render: (i, v) => {
      //   return (
      //     <>
      //       <div>{v}</div>
      //     </>
      //   );
      // },
    },

    {
      title: "Action",
      key: "action",
      render: (i, v) => {
        return (
          <>
            <div className={styles.actionicon}>
              <img src={trashplan} onClick={() => SubmitHandler(v, "trash")} />
              {/* <img src={editplan} onClick={() => SubmitHandler(v, "edit")} /> */}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <SideBarWrapper>
        <TabNav />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <h2 className={styles.text}>Setup your webhook</h2>
            <div className={styles.input}>
              <CustomInput
                // label="Business Name on Document"
                name="realName"
                value={url}
                onChange={(e) => handleInputChange(e)}
                // valMsg={realNameVal}
                marginVertical="2px"
                padding={"18px"}
                width={"100px"}
                placeholder="enter your webhook url"
              />
            </div>
            <div className={styles.buttonWrapper}>
              {testBtn()}
              {renderBtn()}
            </div>
          </div>
          <div className={styles.commontable}>
            <CommonTable
              column={column}
              heading=""
              maxHeight={"300px"}
              data={columnData}
              webHookTable={styles.containerinner}
              submitting={submitting}
            />
          </div>
        </div>
      </SideBarWrapper>
      <ApiKeyModal
        isOpen={isOpenApi}
        toggle={toggleApi}
        editwebhook={editwebhook}
        userid={userid}
        recieveUpdatedData={recieveUpdatedData}
      />
    </>
  );
};

export default WebHook;
