import Axios from "axios";
import { BASE_URL } from "../utils/AppConstants";
import { useDispatch } from "react-redux";
import {
  getSelectedKeys,
  signTransaction,
  getSelectedSecret,
} from "./trannsactionServices";
import { walletCoins } from "../utils/AppConstants";
import CoinGecko from "coingecko-api";
import { getData, saveData } from "./storageServices";
import { store } from "../store/index";
import { decrypt } from "./tweetnacl";
import { authToken } from "../store/actions/AuthActions";

const uploadFile = async (file, token) => {
  return new Promise((resolve, rejects) => {
    const data = new FormData();
    data.append("file", file);
    Axios.post(BASE_URL + "/kyc/upload", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        rejects(e);
      });
  });
};

const getJWT = async () => {
  const { auth, wallet } = store.getState();
  if (!auth.password) {
    return { data: { token: "" } };
  }
  const token = getData("myToken");
  if (token) {
    return { data: { token: token } };
  }
  const account_id = wallet.key.public;
  let account_secret = getSelectedSecret(wallet?.key?.private);

  try {
    const data = await Axios.get(
      BASE_URL + `/anchor/authentication/?account=${account_id}`
    );

    const trx = await signTransaction(account_secret, data.data);
    const token = await submitSignedTrx(trx);
    saveData({ myToken: token.data.token });
    return token;
  } catch (e) {
    // console.log(e);
    return e;
  }
};

const verifyCode = async (body) => {
  const token = await getJWT();
  const url = `${BASE_URL}/kyc/verify-code`;
  const res = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};
const sendConfirmCode = async (body) => {
  const token = await getJWT();
  const url = `${BASE_URL}/kyc/send-code`;
  const res = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};
const submitSignedTrx = async (trx) => {
  return new Promise((resolve, rejects) => {
    Axios.post(BASE_URL + "/anchor/authentication", { transaction: trx })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        // console.log(e);
        rejects(e);
      });
  });
};

const fetchCoinMarketData = async () => {
  const CoinGeckoClient = new CoinGecko();
  let data = await CoinGeckoClient.coins.markets({
    vs_currency: "usd",
    ids: walletCoins,
    order: "market_cap_desc",
    // per_page: 100,
    // page: 1,
    // sparkline: true,
    // price_change_percentage: "24h",
  });
  return data;
};

export { uploadFile, getJWT, verifyCode, sendConfirmCode, fetchCoinMarketData };
