import { useState, useRef, useEffect } from "react";

const useConfirm = (isOpen, callback) => {
  let init = null;
  let input1Ref = useRef(init);
  let input2Ref = useRef(init);
  let input3Ref = useRef(init);
  let input4Ref = useRef(init);

  const [state, setState] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    inputVal: "",
    tick: 60,
  });

  const submit = () => {
    if (!validate()) return;
    const { input1, input2, input3, input4 } = state;
    callback(input1 + input2 + input3 + input4);
  };

  useEffect(() => {
    if (isOpen) {
      startTicking();
    } else {
      setState((prev) => ({
        ...prev,
        input1: "",
        input2: "",
        input3: "",
        input4: "",
      }));
    }
  }, [isOpen]);

  const startTicking = () => {
    setState((prev) => ({ ...prev, tick: 60 }));
    var i = 60;
    var timer = setInterval(() => {
      i--;
      if (i < 1) clearInterval(timer);
      setState((prev) => ({ ...prev, tick: prev.tick - 1 }));
    }, 1000);
  };

  const validate = () => {
    let validated = true;
    if (
      state.input1 === "" ||
      state.input2 === "" ||
      state.input3 === "" ||
      state.input4 === ""
    ) {
      setState((prev) => ({ ...prev, inputVal: "Please fill all fields." }));
      validated = false;
    }

    return validated;
  };

  const setActive = ({ name, value }) => {
    if (name === "input1") {
      if (value !== "") {
        input2Ref.current.focus();
      }
    }
    if (name === "input2") {
      if (value === "") {
        input1Ref.current.focus();
      } else {
        input3Ref.current.focus();
      }
    }
    if (name === "input3") {
      if (value === "") {
        input2Ref.current.focus();
      } else {
        input4Ref.current.focus();
      }
    }
    if (name === "input4") {
      if (value === "") {
        input3Ref.current.focus();
      }
    }
  };
  const setRef = (ref, no) => {
    if (no === 1) {
      input1Ref.current = ref;
    } else if (no === 2) {
      input2Ref.current = ref;
    } else if (no === 3) {
      input3Ref.current = ref;
    } else if (no === 4) {
      input4Ref.current = ref;
    }
  };
  
  const handleEmptyInput = (e) => {
    const key = e.keyCode || e.charCode;
    const { name } = e.target;
    if (key === 8) {
      if (state[name] === "") {
        setActive({ name, value: "" });
      }
    }
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setActive({ name, value });
    setState((prev) => ({
      ...prev,
      [name]: prev[name] === "" || value === "" ? value : prev[name],
      inputVal: "",
    }));
  };

  return { ...state, setRef, handleInputChange, handleEmptyInput, submit };
};

export default useConfirm;
