import React from 'react'
import WalletDashboard from '../components/Wallet'

const WalletDashboardPage = () => {
    return (
        <div>
            <WalletDashboard/>
        </div>
    )
}

export default WalletDashboardPage
