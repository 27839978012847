import React from "react";
import Register from "../components/NewSessionPassword/index";
import Navigation from "../components/LandingPage/navigation";

const RegisterPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navigation />
      <div className="mt-20 w-5/6 mx-auto lg:w-1/3 xl:w-1/3">
        <Register />
      </div>
    </div>
  );
};

export default RegisterPage;