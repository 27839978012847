export const FETCH_PROFILE_PENDING = "FETCH_PROFILE_PENDING";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const PHONE_VERIFIED = "PHONE_VERIFIED";
export const SET_INPUT_PROPERTY = "SET_INPUT_PROPERTY";
export const DELETE_KYC_PENDING = "DELETE_KYC_PENDING";
export const SET_PROFILE_PICTURE = "SET_PROFILE_PICTURE";

