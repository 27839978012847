import { authReducer } from "./AuthReducer";
import { walletReducer } from "./walletReducer";
import { profileReducer } from "./ProfileReducer";
import { coinReducer } from "./coinReducer";
import { combineReducers } from "redux";





export const rootReducer = combineReducers({
  auth: authReducer,
  wallet: walletReducer,
  profile:profileReducer,
  coins:coinReducer
});


