import { store } from "../../src/store";

var CryptoJS = require("crypto-js");

const encryptText = (text, password) => {
  return CryptoJS.AES.encrypt(text, password).toString();
};

const decrypt = (text) => {
  const { password } = store.getState().auth;

  try {
    let bytes = CryptoJS.AES.decrypt(text, password);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return "";
  }
};

export { encryptText, decrypt };
