import React from "react";
import FooterLarge from "../components/LandingPage/footer";
import Navigation from "../components/LandingPage/navigation";

function Disclaimer() {
  return (
    <div>
      <Navigation />
      <div
        className="text-center text-sm"
        style={{
          padding: "30px 0px",
          paddingLeft: "20vw",
          paddingRight: "20vw",
        }}
      >
        <p className="text-2xl font-semibold text-white ">
          LEGAL DISCLAIMER &ndash; GPay
        </p>
        <p className="text-white text-base font-semibold my-2">www.GPay.tech</p>
        <p className="text-secondaryGray text-base">
          Effective date: 30 November 2020
        </p>
        <p className="text-white text-base font-semibold my-2 text-left">
          Information published on the GPay Platform
        </p>
        <p className="text-secondaryGray text-left">
          The website and mobile applications of GPay Global OU (hereinafter,
          referred to as &ldquo;Gpay&rdquo;, "Platform" or &ldquo;Site&rdquo;)
          provides information and material of a general nature. Do not take any
          of our content or our information on the platform as
          legal/financial/business advice. By relying on the content of the
          website, you proceed at your own risk. You can contact a licenced
          attorney in the relevant jurisdiction in which you want or need
          assistance if you decide to act or not to act. The owners of or
          contributors to the Website are in no way responsible for the actions,
          decisions or other conduct you have taken or have not taken in
          reliance on the Website.
        </p>
        <p className="text-white text-base font-semibold my-2 text-left">
          Your Own Responsibilities
        </p>
        <p className="text-secondaryGray text-left">
          You, the user, are solely responsible for ensuring in your
          jurisdiction your own compliance with laws and taxes. In your country,
          crypto-currencies can be illegal. You are solely responsible for your
          own security, including the safety and backup of your account's secret
          keys.
        </p>
        <p className="text-white text-base font-semibold my-2 text-left">
          Disclaimer
        </p>
        <p className="text-secondaryGray text-left">
          IN THE EVENT YOU LOSE ACCESS TO YOUR WALLET OR YOUR ENCRYPTED PRIVATE
          KEYS AND YOU HAVE NOT SEPARATELY STORED A BACKUP OF YOUR WALLET AND
          CORRESPONDING PASSWORD, YOU ACCEPT AND AGREE THAT ANY CRYPTOCURRRENCY
          YOU HAVE ASSSOCIATED WITH THAT WALLET WILL BECOME UNAVAILABLE.
        </p>
        <p className="text-secondaryGray text-left">
          If you lose or forget them, the developers of the software, staff and
          affiliates of GPay, copyright holders, and Gpay cannot recover your
          private keys or passwords and cannot guarantee transaction
          confirmation because they have no control over the network.
        </p>
        <p className="text-white text-base font-semibold my-2 text-left">
          No Warranty
        </p>
        <p className="text-secondaryGray text-left">
          THERE IS NO WARRANTY FOR THE PROGRAM, TO THE EXTENT PERMITTED BY
          APPLICABLE LAW. EXCEPT WHEN OTHERWISE STATED IN WRITING THE COPYRIGHT
          HOLDERS AND/OR OTHER PARTIES PROVIDE THE PROGRAM &ldquo;AS IS&rdquo;
          WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE QUALITY
          AND PERFORMANCE OF THE PROGRAM IS WITH YOU. SHOULD THE PROGRAM PROVE
          DEFECTIVE, YOU ASSUME THE COST OF ALL NECESSARY SERVICING, REPAIR OR
          CORRECTION.
        </p>
        <p className="text-white text-base font-semibold my-2 text-left">
          Contact
        </p>
        <p className="text-secondaryGray text-left">
          For any concerns, please send us an email at{" "}
          <a href="mailto:admin@Gpayglobal.com">admin@Gpayglobal.com</a>.
        </p>
      </div>
      <FooterLarge />
    </div>
  );
}

export default Disclaimer;
