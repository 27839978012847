import React from "react";
import styles from "./tabNav.module.scss";
import personIcon from "../../../assets/images/person-icon.svg";
import bellIcon from "../../../assets/images/notificationBell-icon.svg";
import Notification from "../Notification/Notification";
export default function TabNav({ heading, info, children, showDivider }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.rowWrapper}>
        <div className={styles.textCol}>
          <div className={styles.heading}>{heading || " "}</div>
          <div className={styles.info}>{info || " "}</div>
        </div>
        {/* <img src={bellIcon} />
        <img src={personIcon} /> */}
        {/* <select className={styles.selector} name="languages" id="languages">
          <option value="English">English</option>
        </select> */}
        <Notification />
      </div>
    </div>
  );
}
