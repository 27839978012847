import axios from "axios";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
// import { BASE_URL } from "../../../utils/AppConstants";
// import { HTTP_CLIENT } from "../../../utils/AxiosClient";
import GenericButton from "../../../common/GenericButton/genericButton";
import GenericModal from "../../../common/genericModal/index";
import styles from "./deleteModal.module.scss";

const UserModal = ({ isOpen, toggle, APIKeyStatus, recieveStatus }) => {
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    alert.show(`Transaction has been send to`, {
      type: "success",
    });
  };

  const empty = () => {};

  return (
    <>
      <GenericModal isOpen={isOpen} toggle={() => toggle("delete")}>
        <div className={styles.alert}>
          <img src={"/assets/PaymentWhite.svg"} />
        </div>

        <div className={styles.mainHeading}>Please select payment type</div>
        <div className={styles.buttonWrapper}>
          <GenericButton
            submitting={loading}
            disable={APIKeyStatus == "escrow" ? true : false}
            onClick={
              APIKeyStatus == "escrow"
                ? () => empty()
                : () => recieveStatus("instant")
            }
            title="instant"
            fontsize="14px"
          />
          <GenericButton
            submitting={submitting}
            disable={APIKeyStatus == "instant" ? true : false}
            onClick={
              APIKeyStatus == "instant"
                ? () => empty()
                : () => recieveStatus("escrow")
            }
            title="Escrow"
            fontsize="14px"
          />
        </div>
      </GenericModal>
    </>
  );
};

export default UserModal;
