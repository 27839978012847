import { useState, useEffect } from "react";
import { getSelectedKeys } from "../../../services/trannsactionServices";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { addDocumentAction } from "../../../store/actions/ProfileActions";
import { useAlert } from "react-alert";

const useUpload = (tab, setTab, isCompany) => {
  let { wallet } = useSelector((state) => state);
  const document = useSelector((state) => state.profile.document);
  const user = useSelector((state) => state.profile.user);
  const dispatch = useDispatch();
  const alert = useAlert();
  let [state, setState] = useState({ submitting: false });
  let [inputs, setInputs] = useState({
    docType: "",
    country: "",
    issueDate: new Date(),
    expDate: new Date(),
    realName: "",
    number: "",
    frontUrl: "",
    backUrl: "",
    companyDocument: "",
    companyDocWithName: "",
    proofImage: "",
    proofCode: "",
    proofImageVal: "",
    docTypeVal: "",
    countryVal: "",
    realNameVal: "",
    issueDateVal: "",
    expDateVal: "",
    numberVal: "",
    frontUrlVal: "",
    backUrlVal: "",
    companyDocumentVal: "",
    companyDocWithNameVal: "",
  });

  let initialize = () => {
    setInputs((prev) => ({ ...prev, ...document }));
  };
  useEffect(() => {
    initialize();
  }, [document]);

  const submit = async (e) => {
    e?.preventDefault();
    if (!isValidated()) {
      return;
    }
    // setTab(tab+1);
    let issue = moment(inputs.issueDate).format("YYYY-MM-DD");
    let exp = moment(inputs.expDate).format("YYYY-MM-DD");
    let body = {
      accountId: wallet?.key?.public,
      idType: inputs.docType,
      idTountry_code: inputs.country,
      idIssue_date: issue,
      idExpiration_date: exp,
      idNumber: inputs.number,
      photoIdFront: inputs.frontUrl,
      photoIdBack: inputs.backUrl,
      realName: inputs.realName,
      companyDocument: inputs.companyDocument,
      companyDocWithName: inputs.companyDocWithName,
      proofImage: inputs.proofImage,
      proofCode: inputs.proofCode,
    };
    let data = {
      docType: inputs.docType,
      country: inputs.country,
      issueDate: inputs.issueDate,
      expDate: inputs.expDate,
      realName: inputs.realName,
      number: inputs.number,
      frontUrl: inputs.frontUrl,
      backUrl: inputs.backUrl,
      companyDocument: inputs.companyDocument,
      companyDocWithName: inputs.companyDocWithName,
      proofImage: inputs.proofImage,
      proofCode: inputs.proofCode,
    };
    setState((prev) => ({ ...prev, submitting: true }));
    dispatch(
      addDocumentAction(body, data, (success) => {
        if (success) {
          alert.show("Information submitted.", { type: "success" });
          setTab(tab + 1);
        }
        if (!success) {
          alert.show("Error while saving document.", { type: "error" });
        }
        setState((prev) => ({ ...prev, submitting: false }));
      })
    );
  };

  const isValidated = () => {
    let response = true;
    if (state.submitting) {
      response = false;
    }
    if (user?.isCompanyKyc) {
      if (!inputs.issueDate) {
        response = false;
        setInputs((prev) => ({
          ...prev,
          issueDateVal: "Registration date is required.",
        }));
      }
    }
    if (
      (!inputs.companyDocument || inputs.companyDocument === "") &&
      isCompany
    ) {
      setInputs((prev) => ({
        ...prev,
        companyDocumentVal: "Company document is required.",
      }));
      response = false;
    }
    if (
      (!inputs.companyDocWithName || inputs.companyDocWithName === "") &&
      isCompany
    ) {
      setInputs((prev) => ({
        ...prev,
        companyDocWithNameVal: "This field is required",
      }));
      response = false;
    }

    if (!inputs.realName || inputs.realName == "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        realNameVal: "Name field is required.",
      }));
    }
    if (!inputs.country || inputs.country === "") {
      setInputs((prev) => ({ ...prev, countryVal: "Please select a country" }));
      response = false;
    }
    // if(inputs.issueDate >= new Date() && inputs.docType!=="ID"){
    //   setInputs(prev=>({...prev, issueDateVal: "Please select a valid date"}))
    //   response = false
    // }
    if ((!inputs.frontUrl || inputs.frontUrl == "") && !isCompany) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        frontUrlVal: "Front side image of document is required.",
      }));
    }
    if ((!inputs.backUrl || inputs.backUrl == "") && !isCompany) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        backUrlVal: "Back side image of document is required.",
      }));
    }

    if (!isCompany && !inputs.proofImage) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        proofImageVal: "Verification image is required.",
      }));
    }
    // if (!inputs.docType || inputs.docType == "") {
    //   response = false;
    //   setInputs((prev) => ({
    //     ...prev,
    //     docTypeVal: "Please select a document type.",
    //   }));
    // }
    if (!inputs.number || inputs.number == "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        numberVal: "Number field is required.",
      }));
    }

    return response;
  };

  let saveUrl = (data) => {
    let { name, value } = data;
    setInputs((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };

  let saveDates = (data) => {
    let { name, value } = data;
    let expVal, issueVal;
    setInputs((prev) => {
      if (name === "expDate") {
        expVal = value > prev.issueDate ? "" : prev.expDateVal;
        issueVal = value > prev.issueDate ? "" : prev.issueDateVal;
      } else {
        expVal = value < prev.expDate ? "" : prev.expDateVal;
        issueVal = value < prev.expDate ? "" : prev.issueDateVal;
      }
      return {
        ...prev,
        [name]: value,
        expDateVal: expVal,
        issueDateVal: issueVal,
      };
    });
  };

  let handleInputChange = (e) => {
    let { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
      [name + "Val"]: value !== "" ? "" : prev[name + "Val"],
    }));
  };

  return {
    ...inputs,
    ...state,
    handleInputChange,
    submit,
    saveUrl,
    saveDates,
  };
};

export default useUpload;
