import React, { useState, useEffect } from "react";
import Axios from "axios";
import { BASE_URL } from "../../utils/AppConstants";
import { getJWT } from "../../services/httpServices";
import { useSelector } from "react-redux";
import moment from "moment";
import { useAlert } from "react-alert";

import XLSX from "xlsx";

const UsePlan = () => {
  const [data, setData] = useState([]);
  const [dataPlans, setDataPlans] = useState([]);
  const [pendingData, setPendingData] = useState({});
  const [openPlanPending, setOpenPlanPending] = useState(false);
  const [responseMsgPending, setResponseMsgPending] = useState(null);
  const [availableBal, setAvailableBal] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [planLogs, setplanLogs] = useState([]);
  const alert = useAlert();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  });
  const [count, setCount] = useState(null);
  const ExcelHeader = [
    "Status",
    "Api's Per Day",
    "Api's Per Second",
    "Plan",
    "Payable Amount",
    "Duration",
    "Payable Address",
    "Start Date",
    "End Data",
  ];

  // dataPlans.map((el) => {
  //   data.map((item) => {
  //     if (el?.planId?.title === item?.title) {
  //       if (el?.status == "pending") {
  //         item.check = "Pending";
  //       } else if (el?.status == "active") {
  //         item.check = "Activated";
  //       }
  //     }
  //   });
  // });

  useEffect(() => {
    fetchPaymentLog();
  }, [pagination.page, pagination.limit]);

  const handlePageChange = async (pageNumber) => {
    setPagination((prev) => ({ ...prev, page: pageNumber }));
  };

  let { wallet, coins } = useSelector((state) => state);
  useEffect(() => {
    fetchDataPlans();
    fetchData();
    fetchPaymentLog();

    const transferCoin = coins.coinsData.find((item) => item.symbol === "usdt");
    const availableBal =
      transferCoin?.balance - transferCoin?.selling_laibilities;

    setAvailableBal(availableBal);
  }, []);

  const fetchPaymentLog = async () => {
    setLoadingPlans(true);
    try {
      const token = await getJWT();
      const res = await Axios.get(
        `${BASE_URL}/plans-subscription/plans/${wallet?.key?.public}?page=${pagination.page}&limit=${pagination.limit}`
      );
      setCount(res.data.count);
      setplanLogs(res.data.results);
      setLoadingPlans(false);
    } catch (e) {
      setLoadingPlans(false);
    }
  };

  const handleRefresh = async () => {
    setLoadingPlans(true);
    try {
      const token = await getJWT();
      const res = await Axios.get(
        `${BASE_URL}/plans-subscription/transactions/${wallet?.key?.public}`
      );
      fetchDataPlans();
      fetchPaymentLog();
      alert.show(res?.data, {
        type: "success",
      });
      setLoadingPlans(false);
    } catch (e) {
      alert.show(e?.response?.data?.message || "Request Failed", {
        type: "error",
      });
      setLoadingPlans(false);
    }
  };

  const fetchData = async () => {
    setLoadingPlans(true);
    try {
      const token = await getJWT();
      const res = await Axios.get(`${BASE_URL}/plans`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      });
      setData(res.data);
      setLoadingPlans(false);
    } catch (e) {
      setLoadingPlans(false);
    }
  };
  const fetchDataPlans = async () => {
    debugger;
    try {
      const token = await getJWT();
      const res = await Axios.get(
        `${BASE_URL}/plans-subscription/get-subscriptions/${wallet?.key?.public}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token?.data?.token}`,
          },
        }
      );
      const resp = await Axios.get(`${BASE_URL}/plans`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      });
      res.data.map((el) => {
        resp.data.map((item) => {
          if (el?.planId?.title === item?.title) {
            if (el?.status == "pending") {
              item.check = "Pending";
              item.userBalance = el.userAmount;
            } else if (el?.status == "active") {
              item.check = "Activated";
              item.userBalance = el.userAmount;
            }
          }
        });
      });
      if (res.data.length == 0) {
        let resp = await Axios.post(`${BASE_URL}/plans-subscription`, {
          planId: "62f9f66af90155587c3d3641",
          userId: wallet?.key?.public,
          payableAmount: 0,
          duration: "1 Month",
          planType: "Free Plan",
        });
        if (resp.data.status === "active") {
          fetchDataPlans();
        }
      }
      setData(resp.data);

      setDataPlans(res.data);
    } catch (e) {}
  };
  const pendingStateHandler = (data) => {
    setPendingData(data);
    setOpenPlanPending(!openPlanPending);
    setResponseMsgPending(null);
  };
  const planHandlerPending = () => {
    setOpenPlanPending(!openPlanPending);
    setResponseMsgPending(null);
  };
  const responseHandlerPending = (msg) => {
    setResponseMsgPending(msg);
  };
  const downloadHandler = (data, duration) => {
    const subscribe = [];
    const subscribeArray = [];
    for (let item in data) {
      if (
        item !== "__v" &&
        item !== "_id" &&
        item !== "updatedAt" &&
        item !== "userId" &&
        item !== "planId" &&
        item !== "memo" &&
        item !== "updatedAt"
      ) {
        subscribe.push(data[item]);
      } else if (item === "planId") {
        subscribe.push(data[item]?.apisPerDay);
        subscribe.push(data[item]?.apisPerSecond);
        subscribe.push(data[item]?.title);
      } else if (item === "updatedAt") {
        const month = duration.substr(0, duration.indexOf(" "));
        subscribe.push(moment(data[item]).format("MMM Do YY"));
        subscribe.push(
          moment(data[item]).add(month, "months").format("MMM Do YY")
        );
      }
    }
    subscribeArray.push(subscribe);
    subscribeArray.unshift(ExcelHeader);
    const ws = XLSX.utils.aoa_to_sheet(subscribeArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "subscription-report.xlsx");
  };

  return {
    data,
    dataPlans,
    pendingStateHandler,
    pendingData,
    openPlanPending,
    planHandlerPending,
    responseMsgPending,
    responseHandlerPending,
    downloadHandler,
    availableBal,
    coins,
    loadingPlans,
    fetchDataPlans,
    handleRefresh,
    planLogs,
    fetchData,
    handlePageChange,
    pagination,
    count,
  };
};

export default UsePlan;
