import React from "react";
import Navigation from "../components/LandingPage/navigation";
import {NewWallet} from "../components/NewWallet/index";

const NewWalletPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navigation />
      <div className="mt-20 ">
        <NewWallet />
      </div>
    </div>
  );
};

export default NewWalletPage;
