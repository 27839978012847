import { SET_COINS } from "../actions/coinTypes";
import { loadCoins } from "../../services/trannsactionServices";
import { fetchCoinMarketData } from "../../services/httpServices";
export function setWalletCoins(addedCoins, totalCoins) {
  const coinData = totalCoins
    .map((totalItem) => {
      return addedCoins.map((addedItem) => {
        if (
          addedItem?.asset_code?.toUpperCase() ===
            totalItem?.symbol?.toUpperCase() ||
          (totalItem?.symbol?.toUpperCase() === "XLM" &&
            addedItem?.asset_type?.toUpperCase() === "NATIVE")
        ) {
          return {
            asset_code: addedItem?.asset_code,
            balance: addedItem?.balance,
            buying_laibilities: addedItem?.buying_liabilities,
            selling_laibilities: addedItem?.selling_liabilities,
            is_authorized: addedItem?.is_authorized,
            symbol: totalItem?.symbol,
            current_price: totalItem?.current_price,
          };
        }
      });
    })
    .flat()
    .filter((x) => x !== undefined);

  return {
    type: SET_COINS,
    payload: coinData,
  };
}
export const loadCoinsData = (coinData) => {
  return async (dispatch, getState) => {
    const { wallet } = getState();
    const totalCoins = await fetchCoinMarketData();
    const addedCoins = await loadCoins(wallet.key.public);
    dispatch(setWalletCoins(addedCoins, totalCoins.data));
  };
};

// if (
//   addedItem?.asset_code?.toUpperCase() ===
//     totalItem?.symbol?.toUpperCase() ||
//   (totalItem?.symbol?.toUpperCase() === "XLM" &&
//     addedItem?.asset_type?.toUpperCase() === "NATIVE")
// ) {
//   return {
//     asset_code: addedItem?.asset_code,
//     asset_code: addedItem?.asset_code,
//     buying_laibilities: addedItem?.buying_laibilities,
//     selling_laibilities: addedItem?.selling_laibilities,
//     is_authorized: addedItem?.is_authorized,
//     symbol: totalItem?.symbol,
//     current_price: totalItem?.current_price,
//     addedItem

//   }
// }
