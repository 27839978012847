import React from "react";
import styles from "./header.module.scss";
const header = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>
              <label>
                FLAMINGO
                <br /> DESIGNS
              </label>
              <div className={styles.borderWrapper}></div>
              <p>HAND CRAFTED COLLECTION</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default header;
