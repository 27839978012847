import React, { useEffect, useState } from "react";
import Axios from "axios";
import { BASE_URL } from "../../utils/AppConstants";
import { getJWT } from "../../services/httpServices";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";

const UseApiKey = () => {
  const [data, setData] = useState([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [copied, setCopied] = useState("");
  let { wallet } = useSelector((state) => state);
  const copyKey = (apiKey) => {
    copy(apiKey);
    setCopied(apiKey);
  };

  useEffect(() => {
    fetchData();
  }, [isGenerated]);

  const fetchData = async () => {
    const token = await getJWT();

    let res = await Axios.get(
      `${BASE_URL}/keys/get-all-keys/${wallet?.key?.public}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      }
    );
    const apiKeyData = res?.data?.map((item) => {
      return {
        id: item._id,
        title: item.title,
        type: item.type,
        apiKey: item.secretKey,
        active: item.active,
      };
    });
    setData(apiKeyData);
    setIsGenerated(false);
  };
  const statusHandler = async (_id) => {
    const token = await getJWT();
    let res = await Axios.post(
      `${BASE_URL}/keys/change-status`,
      { keyId: _id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.data?.token}`,
        },
      }
    );

    const apiKeyData = {
      id: res?.data?._id,
      title: res?.data?.title,
      type: res?.data?.type,
      apiKey: res?.data?.secretKey,
      active: res?.data?.active,
    };
    const result = data.map((x, index) => {
      if (apiKeyData.id === x.id) {
        x = apiKeyData;
      }
      return x;
    });

    setData(result);
  };
  return { data, setIsGenerated, copyKey, copied, statusHandler };
};

export default UseApiKey;
