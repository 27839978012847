import React from "react";
import Footer from "../../components/LandingPage/footer/index";
import NavBar from "../../components/LandingPage/navigation";
import Cards from "./Cards/Cards";
import Header from "./Header/header";
const Ecommerce = () => {
  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <NavBar />

        <Header />
        <div style={{ padding: "0px 5%" }}>
          <Cards />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ecommerce;
