import React from "react";
// import Dashboard from "../components/Dashboard";
import Dashboard from "../components/dashboard";

const DashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default DashboardPage;
