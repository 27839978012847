import React from "react";
import Login from "../components/Login";
import Navigation from "../components/LandingPage/navigation";

const LoginPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navigation />
      <div className="mt-20 w-5/6 mx-auto lg:w-1/3 xl:w-1/3">
        <Login />
      </div>
    </div>
  );
};

export default LoginPage;
