import React from "react";
import NavBar from "../components/LandingPage/navigation";
import Banner from "../components/LandingPage/banner";
import Feature from "../components/LandingPage/feature";
import WorkFlow from "../components/LandingPage/workFlow";
import Plan from "../components/LandingPage/packagePlan";
import FeeInfo from "../components/LandingPage/feeInfo";
import Testimonial from "../components/LandingPage/testimonial";
import MapSection from "../components/LandingPage/mapSection";
import Footer from "../components/LandingPage/footer/index";
import Invoice from "../components/Invoice/Invoice";
import Verification from "../components/Modals/Verification/verification";

const LandingPage = () => {
  return (
    <>
      <div style={{ minHeight: "100vh" }}>
        <NavBar />
        <div style={{ padding: "0px 5%" }}>
          <Banner />
          <Feature />
          <WorkFlow />
          <Plan />
          <FeeInfo />
          <Testimonial />
          <MapSection />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
