import React from "react";
import { persistReducer, persistStore } from "redux-persist";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';
import {rootReducer} from "../store/reducers/index";
const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["wallet"],
  }
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store)