import React , {useState} from 'react';
import GenericModal from '../../common/genericModal/index';
import styles from "./Insufficient.module.scss";
import error from "../../../assets/images/error.svg"


const Insufficient = ({isOpen , toggle}) => {
    const [selected, setSelected] = useState("XML");
    const [isActive, setIsActive] = useState(false);
    const changeHandler = () => {};
    return (
        <>
        <GenericModal isOpen={isOpen} toggle={toggle} style={{backgroundColor:"#1A1B16!important"}}>
       
    
    <div className={styles.fieldWrapper}>
     <div className={styles.imgdiv}>
     <img src={error}/>
     </div>
      </div>
    
      <div className={styles.fieldWrapper}>
      <div className={styles.row1}>
      Error!</div>
      <div className={styles.row2}>
      Insufficient Funds 
    </div>
    </div>


   
        </GenericModal>
            
        </>
    )
}

export default Insufficient;