import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./banner.module.scss";
import bannerImage from "../../../assets/images/banner.svg";
import { Button } from "../../common";
import circle from "../../../assets/images/circle.png";
import arrow from "../../../assets/images/up-arrow.png";
import Aos from "aos";
const Banner = () => {
  useEffect(() => {
    Aos.init();
  });
  let history = useHistory();

  let renderBtn = () => {
    // if(submitting) {
    //  return <div> <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...</div>
    // }
    return <div onClick={() => history.push("/login")}>Get Started</div>;
    //
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.contentWrapper}>
            <div
              className={styles.heading}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              data-aos="fade-down"
            >
              BLOCKCHAIN WEB SERVICES
            </div>
            <div
              className={styles.text}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1300"
              data-aos="fade-down"
            >
              G PAY is the infrastructure fabric for blockchain applications.
            </div>
            <div
              className={styles.buttonWrapper}
              data-aos-easing="ease-in-sine"
              data-aos-duration="1500"
              data-aos="fade-right"
            >
              <Button
                type="submit"
                styles={["bg-accent text-sm py-3.5 mt-0 w-full uppercase"]}
                onClick={() => history.push("/login")}
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
        <div
          className={styles.right}
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1500"
        >
          <img className={styles.imgContainer} src={bannerImage} />
        </div>
      </div>
      <div className={styles.scroll}>
        <img src={circle} className={styles.img1} />
        <img src={arrow} className={styles.img2} />
      </div>
    </div>
  );
};

export default Banner;
