import React from "react";
import styles from "./dashboard.module.scss";
import cardIcon1 from "../../assets/images/sent.svg";
import cardIcon2 from "../../assets/images/payment.svg";
import cardIcon3 from "../../assets/images/percentage.svg";
import cardIcon4 from "../../assets/images/box.svg";

const InfoCard = ({ order }) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div className={styles.left}>
          <img src={cardIcon1} />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>Total Orders</p>
          <p className={styles.amount}>{order?.merchantTotalOrders}</p>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.left}>
          <img src={cardIcon2} />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>Payments In Escrow</p>
          <p className={styles.amount}>
            $ {order?.merchantEscrowAmount?.toFixed(5)}
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.left}>
          <img src={cardIcon3} />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>Total Earning</p>
          <p className={styles.amount}>
            $ {order?.merchantTotalEarnings?.toFixed(5)}
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.left}>
          <img src={cardIcon4} />
        </div>
        <div className={styles.right}>
          <p className={styles.title}>Payment Orders</p>
          <p className={styles.amount}> {order?.merchantPaymentOrders}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
