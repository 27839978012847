import React, { useEffect } from "react";
import styles from "./map.module.scss";
import map from "../../../assets/images/map.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const MapSection = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <div className={styles.container}>
            <div
              className={styles.heading}
              data-aos="fade-down"
              data-aos-easing="ease-in-sine"
              data-aos-duration="2000"
            >
              Join Our Global Community
            </div>
            <div
              className={styles.text}
              data-aos="fade-down"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1800"
            >
              JSed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem .
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container2}>
        <div
          className={styles.mapsection}
          data-aos="zoom-out"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2000"
        >
          <img src={map} className="imgFluid" />
        </div>
      </div>
    </>
  );
};

export default MapSection;
