import React, { useState } from "react";
import Navigation from "../components/LandingPage/navigation";
import ResetPassword from "../components/resetPassword";

const ResetpasswordPage = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () =>{
    setIsOpen((prev)=>(!prev))
  }
  return (
    <div style={{ minHeight: "100vh" }}>
      <Navigation />
      <ResetPassword toggle={toggle} isOpen={isOpen}/>
    </div>
  );
};

export default ResetpasswordPage;
