import moment from "moment";
import circle from "../../assets/images/check-circle.svg";
import editPlanSvg from "../../assets/images/edit.svg";
import importantMsg from "../../assets/images/important-message.svg";
import refresh from "../../assets/images/refresh.svg";
import download from "../../assets/images/Download.svg";
import styles2 from "../../components/common/CommonTable/CommonTable.module.scss";
import CommonTable from "../common/CommonTable/CommonTable";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import TabNav from "../common/tabNav";
import Insufficient from "../Modals/Insufficient/Insufficient";
import PlansPending from "../Modals/Plans/Plans";
import Success from "../Modals/Success/Success";
import styles from "./payment.module.scss";
import PaymentCard from "./PaymentCard";
import usePlan from "./usePlan";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";

// import {Link} from "react-router-dom";

const PaymentPlan = () => {
  const {
    data,
    dataPlans,
    pendingStateHandler,
    pendingData,
    openPlanPending,
    planHandlerPending,
    responseMsgPending,
    responseHandlerPending,
    downloadHandler,
    availableBal,
    coins,
    loadingPlans,
    fetchDataPlans,
    handleRefresh,
    planLogs,
    fetchData,
    handlePageChange,
    pagination,
    count,
  } = usePlan();

  const column = [
    {
      title: "Title",
      key: "planType",
      render: (item, data) => {
        return (
          <span className={styles2.coinWrapper}>
            <div>{item}</div>
          </span>
        );
      },
    },
    {
      title: "Duration",
      key: "duration",
      render: (item, data) => {
        return (
          <span className={styles2.coinWrapper}>
            <div>{!data.planId ? "unlimited" : item}</div>
          </span>
        );
      },
    },
    {
      title: "Start Date",
      key: "updatedAt",
      render: (item, data) => {
        return (
          <span className={styles2.coinWrapper}>
            <div>{moment(item).format("MMM Do YY")}</div>
          </span>
        );
      },
    },
    {
      title: "End Date",
      key: "updatedAt",
      render: (item, data) => {
        const month = data.duration.substr(0, data.duration.indexOf(" "));
        return (
          <span className={styles2.coinWrapper}>
            <div>{moment(item).add(month, "months").format("MMM Do YY")}</div>
          </span>
        );
      },
    },
    {
      title: "Payment Status",
      key: "status",
      render: (item, data) => {
        return (
          <span
            className={styles2.statusButtonWrapper}
            style={
              item.toUpperCase() === "PENDING"
                ? { cursor: "pointer", columnGap: "5px" }
                : {}
            }
            onClick={() =>
              item.toUpperCase() === "PENDING" && pendingStateHandler(data)
            }
          >
            {item.toUpperCase() === "PENDING" ? (
              <>
                <div className={styles2.label}>{item.toUpperCase()}</div>
                <img src={editPlanSvg} width={17} />
              </>
            ) : (
              <div className={styles2.label}>
                {item.toUpperCase() == "ACTIVE" ? "Purchased" : ""}
              </div>
            )}
          </span>
        );
      },
    },
    // {
    //   title: "Refresh Plan",
    //   key: "status",
    //   render: (item, data) => {
    //     return (
    //       <span className={styles2.coinWrapper} onClick={handleRefresh}>
    //         <img
    //           src={refresh}
    //           style={{ marginLeft: "1.4rem", cursor: "pointer" }}
    //         />
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Action",
    //   key: "Action",
    //   render: (item, data) => {
    //     return (
    //       <span className={styles2.coinWrapper}>
    //         <span
    //           className={styles2.text}
    //           onClick={() => downloadHandler(data, data.duration)}
    //         >
    //           <img src={download} />
    //         </span>
    //       </span>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <ReactTooltip />
      <SideBarWrapper>
        <TabNav />

        <div className={styles.container}>
          {!loadingPlans ? (
            <div className={styles.refreshImg} onClick={handleRefresh}>
              <img src={refresh} data-tip="Refresh Plan" />
            </div>
          ) : (
            ""
          )}

          {!coins?.coinsData ||
            (coins?.coinsData?.length === 0 && (
              <div className={styles.activateAccount}>
                <img src={importantMsg} className={styles.img} />
                <label className={styles.text}>
                  <a
                    target="_blank"
                    href="https://laboratory.stellar.org/#account-creator?network=test"
                  >
                    {" "}
                    Activate your wallet before proceeding
                  </a>
                </label>
              </div>
            ))}
          <dv className={styles.cardWrapper}>
            <div className={styles.parent}>
              <marquee
                className={styles.disclaimer}
                width="100%"
                direction="left"
                height="60%"
              >
                <span>Disclaimer : </span>
                Pay the exact amount as mentioned to upgrade a plan. If you
                partially pay the amount for a plan and upgrade another plan
                than the last paid amount will be declined. You have to pay
                again for that Payment Plan. Please pay from the same account
                that you are using as a Merchant. Otherwise your payment will be
                lost and plan will not be activated. GPay will not be
                responsible for any loss. So pay the amount attentively!
              </marquee>
            </div>
            {loadingPlans ? (
              <div className={styles.spinnerWrapper}>
                <div className={styles.spinner}></div>
              </div>
            ) : (
              <>
                {data.map((item) => {
                  return (
                    <PaymentCard
                      tableData={dataPlans}
                      data={item}
                      availableBal={availableBal}
                      disable={coins?.coinsData?.length}
                      fetchDataPlans={fetchDataPlans}
                      coins={coins?.coinsData}
                    />
                  );
                })}
                <div className={styles.card}>
                  <div className={styles.heading}>Business Plan </div>
                  <div className={styles.middle}>
                    <div className={styles.textWrapper}>
                      <div className={styles.icon}>
                        <img src={circle} width={"80%"} />
                      </div>
                      <div className={styles.text}>
                        Contact us for your custom plan.
                      </div>
                    </div>
                    <div className={styles.textWrapper}>
                      {/* <div className={styles.icon}>
                        <img src={circle} width={"80%"} />
                      </div> */}
                      {/* <div className={styles.text}>Duration 1 Month</div> */}
                    </div>
                  </div>
                  <a
                    className={styles.buttonWrapper}
                    href="mailto:test@gmail.com"
                  >
                    <button className={styles.button}>
                      <span className={styles.text}>Contact Us</span>
                    </button>
                  </a>
                </div>
              </>
            )}
          </dv>
          <CommonTable
            column={column}
            heading=""
            maxHeight={"410px"}
            // data={dataPlans}
            data={planLogs}
          />
          <Pagination
            activePage={pagination.page}
            itemsCountPerPage={pagination.limit}
            totalItemsCount={count}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
            activeClass="activeClass"
            innerClass="innerClass"
            itemClass="itemClass"
            // hideFirstLastPages={true}
          />
          <PlansPending
            isOpen={openPlanPending}
            toggle={planHandlerPending}
            toggleMsg={responseHandlerPending}
            data={pendingData.planId}
            selected={pendingData.duration}
            availableBal={availableBal}
            fetchDataPlans={fetchDataPlans}
          />
          {responseMsgPending === "Success" && (
            <Success isOpen={openPlanPending} toggle={planHandlerPending} />
          )}
          {responseMsgPending === "Failed" && (
            <Insufficient
              isOpen={openPlanPending}
              toggle={planHandlerPending}
            />
          )}
        </div>
      </SideBarWrapper>
    </>
  );
};

export default PaymentPlan;
