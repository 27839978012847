import React, { useEffect } from "react";
import styles from "./testimonial.module.scss";
import TestimonialComponent from "../../common/testimonial/index";
import Aos from "aos";
import "aos/dist/aos.css";

const Testimonial = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className={styles.wrapper}>
      {/* <div
        className={styles.heading}
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-duration="2000"
      >
        What Customer's says
      </div> */}
      {/* <div
        className={styles.text}
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1800"
      >
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem .
      </div> */}
      {/* <TestimonialComponent /> */}
    </div>
  );
};

export default Testimonial;
