import {
    SAVE_PASSWORD,  LOGOUT_USER
  } from "../actions/AuthActionType";
 
  
  const initialState = {
    password: '',
  };
  
  export function authReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case SAVE_PASSWORD:
        return {
          ...state,
          password: action.password,
        };
        case LOGOUT_USER:
          return {
            ...state,
            password: action.payload,
          };
      default:
        return state;
    }
  }
  