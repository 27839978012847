import moment from "moment";
// import { getSelectedKeys } from "./trannsactionServices";

let defaultPrefrences = JSON.stringify({
  FEE: "fee_min",
  CURRENCY: "USD",
  NOTIFICATION_SOUND: false,
  SHOW_MEMO: false,
  PASSWORD: "Once for Log In",
  LANGUAGE: "English",
});

const saveData = (data) => {
  let keys = Object.keys(data);
  keys.forEach((item) => {
    localStorage.setItem(item, data[item]);
  });
};

const getData = (str) => {
  return localStorage.getItem(str);
};

// const getKeys = () => {
//   let publicKeys = getData("wallets") || "[]";
//   return JSON.parse(publicKeys);
// };

// const setKeys = (data) => {
//   let temp = JSON.stringify(data);
//   saveData({ wallets: temp });
// };

// const getMyAssets = () => {
//   let assets = getData("myAssets") || "{}";
//   return JSON.parse(assets);
// };

// const setMyAssets = (data) => {
//   let temp = JSON.stringify(data);
//   saveData({ myAssets: temp });
// };

// const saveTransaction = (amount, coin, kind, hash) => {
  
//   try {
//     let wallet = getSelectedKeys().public;
//     let transactions = getAllTransactions(null);
    
//     let data = {
//       kind: kind,
//       status: "completed",
//       finalAmount: amount,
//       started_at: moment().format(),
//       wallet,
//       coin,
//       stellar_transaction_id: hash
//     };
//     transactions.push(data);
//     setTransactions(transactions);
//   } catch (e) {
//     // console.log(e);
//   }
// };

// const getLocalToken = () =>{
//   let auth_token = localStorage.getItem('auth_token') ?? '';
//   return auth_token;
// }

// const setLocalToken = (token) =>{
//   localStorage.setItem('auth_token', token);
// }

// const getTransactions = (wallet, coin) => {
//   let transactions = getData("transactions") ?? "[]";
//   let arrayTr= JSON.parse(transactions);
//   let trans = arrayTr.filter((item) => item.wallet == wallet && item.coin?.toUpperCase() == coin?.toUpperCase());
//   return trans;
// };


// const getAllTransactions = (wallet) => {
//   let transactions = getData("transactions") ?? "[]";
//   let trans = JSON.parse(transactions);
//   if(wallet){
//     let result = trans.filter((item)=> item.wallet == wallet);
//     trans = result;
//   }
//   return trans;
// };

// const setTransactions = (data) => {
//   let temp = JSON.stringify(data);
//   saveData({ transactions: temp });
// };

const getPreferences = () => {
  let assets = getData("prefrences") || defaultPrefrences;
  return JSON.parse(assets);
};

// const setPrefrences = (data) => {
//   let temp = JSON.stringify(data);
//   saveData({ prefrences: temp });
// };

export {
  saveData,
  getData,
  // getKeys,
  // setKeys,
  // getMyAssets,
  // setMyAssets,
  getPreferences,
  // setPrefrences,
  // getTransactions,
  // setTransactions,
  // getAllTransactions,
  // getLocalToken,
  // setLocalToken
};
