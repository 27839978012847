
import { WALLET_KEY, WALLET_COINS,SET_COINS } from "./walletTypes";

export function walletKey(key) {
  return {
    type: WALLET_KEY,
    payload: key,
  };
}
;
