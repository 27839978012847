import React, { useEffect } from "react";
import styles from "./info.module.scss";
import img1 from "../../../assets/images/gIcon.svg";
import coin from "../../../assets/images/coin.svg";
import meeting from "../../../assets/images/meeting.svg";
import headphone from "../../../assets/images/headphone.svg";
import elasticsearch from "../../../assets/images/elasticsearch.svg";
import shield from "../../../assets/images/sheild.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const Info = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div
            className={styles.contentWrapper}
            data-aos="fade-left"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <div className={styles.heading}>Fee Information</div>
            <div className={styles.text}>
              Cryptocurrency exchanges monetize their businesses by charging
              transaction fees for the acquisition, disposition, or a trade of
              cryptocurrency. Similar to equity brokers, transaction fees are a
              necessary evil so that exchanges don’t have to charge monthly or
              yearly fees to use their services. Whether exchanges monetize
              through exchange fees, or liquidity spread, TaxBit optimizes to
              ensure the best results.
            </div>
            <div className={styles.text}>
              Prior to the Tax Cuts and Jobs Act (TCJA) certain
              investment-related expenses were eligible for itemized deductions.
              For tax years 2018 to 2025 these deductions have been eliminated.
              Taxpayer’s, however, can still save money on their transaction
              fees by adjusting their cost basis on the acquisition of crypto
              and from deducting fees from the sale proceeds from the
              disposition of crypto.
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div
            className={styles.imgContainer}
            data-aos="fade-up-right"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <img src={img1} className={styles.img1} />
            <img src={coin} className={styles.img2} />
            <img src={meeting} className={styles.img3} />
            <img src={headphone} className={styles.img4} />
            <img src={elasticsearch} className={styles.img5} />
            <img src={shield} className={styles.img6} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
