import React from "react";
import { Text } from "../../components/common";
import  Wrapper from "../../components/common/wrapper";
import newWallet2x from "../../assets/images/new_wallet@2x.png";
import { NavLink, Link, useHistory } from "react-router-dom";
import useNewWallet from "./useNewWallet";
import '../../styles/rotate.css'
import { WalletCreated } from "../WalletCreated";

const WalletBox = (props) => {
  return (
    <Wrapper
      isBtn
      Fw
      Fh
      Center
      Middle
      style={["md:mx-1 sm:mb-3 mb-3 rounded py-6 bg-" + props.color + ""]}
    >
      <Wrapper Fw style={"flex justify-center"}>
        <img
          src={newWallet2x}
          className={`w-8 h-10 md:w-8 md:h-12 object-contain ${props.submitting && 'rotate'}`}
        />
      </Wrapper>
      <Wrapper style={["flex justify-center"]}>
        <Text Color={"white"} Style={"md:text-md sm:text-xs text-xxs"}>
          {props.submitting? 'Creating....' :props.title}
        </Text>
      </Wrapper>
    </Wrapper>
  );
};

const ImportBtn = (props) => {
  return (
    <Wrapper
      isBtn
      Fh
      Fw
      Middle
      style={" flex flex-wrap px-2 mb-4 rounded bg-gray2"}
    >
      {/* <img src={''} /> */}
      <Text
        Color={"gray4 px-2"}
        Style={"font-regular text-xs lg:text-sm xl:text-sm"}
      >
        {props.title}
      </Text>
    </Wrapper>
  );
};

export const NewWallet = (props) => {
  const history = useHistory()
  let { createWallet, submitting, key, showAgreed, privateKey, agreeToTerms } = useNewWallet();

  if(showAgreed){
    return <WalletCreated callback={()=>agreeToTerms(true)} privateKey={privateKey} myKey={key} />
  }

  return (
    <Wrapper Fh Center style={"md:pb-40"} container>
      <Wrapper Fh Center style={"md:w-5/5 sm:w-2/6 sm:w-4/6 w-4/6"}>
        
        <Wrapper Fw Center style={"flex mt-10 py-10"}>
          <Text Color={"white"} Style={"md:text-h1 sm:text-xs text-md"}>
            New Wallet
          </Text>
        </Wrapper>
        <Wrapper Fw Center Middle style={"sm:block md:flex h-32"}>
          {/*<NavLink to="/congratsScreen" style={{display:'block',width:'100%',marginRight:'1rem'}}>*/}
          <div onClick={createWallet} style={{display:'block', marginRight:'1rem'}} className="sm:w-3/5">
            <WalletBox title={"Generate Random Wallet"} color={"accent"} submitting={submitting} />
          </div>
          {/*<WalletBox title={"Generate manual Wallet"} color={"purple"} />*/}
        </Wrapper>
        <Wrapper Fw Center style={"flex mt-32 md:mt-5 py-5 md:py-10"}>
          <Text Color={"white"} Style={"md:text-xl sm:text-xs text-xxs"}>
            Import Existing Wallet
          </Text>
        </Wrapper>
        <Wrapper Fw Center Middle style={"h-16 sm:block md:flex "}>

          <button onClick={()=>{
            history.push({pathname: "/importWallet", state: history.location.state})
          }} className="focus:outline-none w-full sm:w-3/5 bg-primary rounded-lg text-white text-xs lg:text-sm xl:text-sm py-2">
          + Import A Single Wallet

          </button>
          
          {/*<ImportBtn title={" + Import Multiple Wallet"} />*/}
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
