import React from "react";
import InstantReport from "../components/InstantReport";

const InstantReportPage = () => {
  return (
    <div>
      <InstantReport />
    </div>
  );
};

export default InstantReportPage;
