import * as React from "react";
// import { title, subTitle } from "styles";
import { CustomAlign} from "../../styles/index";

export const Text = (props) => {
  const { Title, SubTitle, Style = [""], Color, Center,Middle } = props;

  const switcher = [
      Title && CustomAlign.title, SubTitle && CustomAlign.subTitle,
      Center && CustomAlign.textAlign('center'),
      Color && CustomAlign.color(Color),

    ];

  const styles = [
    `${switcher} ${Style}`
  ];
  return <p className={`${styles}`}>{props.children}</p>;
};
