import React, { useState } from "react";
import styles from "./settings.module.scss";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import TabNav from "../common/tabNav";
import iconRight from "../../assets/images/icon-right.svg";
import ChangePassword from "../Modals/changePassword/changePassword";
import EscrowFee from "../Modals/EscrowFee";
import InstantFee from "../Modals/InstantFee";
import { useHistory } from "react-router-dom";

const Setting = () => {
  const [isOpenReset, setIsOpenReset] = useState(false);
  const [isOpenEscrow, setIsOpenEscrow] = useState(false);
  const [isOpenInstant, setIsOpenInstant] = useState(false);
  let history = useHistory();

  const toggleReset = () => {
    setIsOpenReset(!isOpenReset);
  };
  const toggleEscrowReport = () => {
    setIsOpenEscrow(!isOpenEscrow);
  };
  const toggleInstantReport = () => {
    setIsOpenInstant(!isOpenInstant);
  };

  const webHook = () => {
    history.push("/webhook");
  };

  return (
    <SideBarWrapper>
      <TabNav />
      <div className={styles.container}>
        <div className={styles.container1}>
          <div className={styles.wrapper}>
            {/* <div className={styles.currency} onClick={toggleEscrowReport}>
              <div className={styles.left}>
                <div className={styles.text}>Escrow Fee</div>
              </div>
              <div className={styles.right} onClick={toggleEscrowReport}>
                <img src={iconRight} alt="open" />
              </div>
            </div> */}
            {/* <div className={styles.currency} onClick={toggleInstantReport}>
              <div className={styles.left}>
                <div className={styles.text}>Instant Fee</div>
              </div>
              <div className={styles.right} onClick={toggleInstantReport}>
                <img src={iconRight} alt="open" />
              </div>
            </div> */}

            {/* <div className={styles.password} onClick={toggleReset}>
              <div className={styles.left}>
                <div className={styles.text}>Change Password</div>
              </div>
              <div className={styles.right}>
                <img src={iconRight} alt="open" />
              </div>
            </div> */}

            <div className={styles.webHook} onClick={webHook}>
              <div className={styles.left}>
                <div className={styles.text}>Web Hook</div>
              </div>
              <div className={styles.right} onClick={webHook}>
                <img src={iconRight} alt="open" />
              </div>
            </div>
          </div>
        </div>
        <ChangePassword isOpen={isOpenReset} toggle={toggleReset} />
        <EscrowFee isOpen={isOpenEscrow} toggle={toggleEscrowReport} />
        <InstantFee isOpen={isOpenInstant} toggle={toggleInstantReport} />
      </div>
    </SideBarWrapper>
  );
};

export default Setting;
