import * as React from 'react';

export class CustomText extends React.Component{

    render(){
        return(
            <h1 style={{
                color:this.props.color,
                fontSize:this.props.fontSize,
                fontFamily:this.props.fontFamily,
                display:this.props.display,
                textAlign:this.props.align,
                padding:this.props.padding,
                textTransform:this.props.transform,
                alignSelf:this.props.alignSelf,
                paddingTop:this.props.paddingTop || this.props.paddingVertical,
                paddingRight:this.props.paddingRight|| this.props.paddingHorizontal,
                paddingBottom:this.props.paddingBottom || this.props.paddingVertical,
                paddingLeft:this.props.paddingLeft|| this.props.paddingHorizontal,
                margin:this.props.margin,
                marginTop:this.props.marginTop ||this.props.marginVertical,
                marginLeft:this.props.marginLeft || this.props.marginHorizontal,
                marginRight:this.props.marginRight || this.props.marginHorizontal,
                marginBottom:this.props.marginBottom || this.props.marginVertical,
            }}>
                {this.props.children}
            </h1>
        );
    }
}