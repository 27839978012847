import { Keypair } from "stellar-sdk";
import { useSelector, useDispatch } from "react-redux";
import { encrypt } from "../../services/tweetnacl";
import { saveData } from "../../services/storageServices";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateAccount } from "../../services/trannsactionServices";
import { useAlert, positions } from "react-alert";
import { postOptions, BASE_URL } from "../../utils/AppConstants";
import { encryptText } from "../../utils/helpers";
import { walletKey } from "../../store/actions/walletAction";

let useNewWallet = () => {
  let { auth } = useSelector((state) => state);
  let history = useHistory();
  let [key, setKey] = useState("");
  let [validation, setValidation] = useState("");
  let [submitting, setSubmitting] = useState(false);
  const [createAgreed, setCreateAgreed] = useState(false);
  const [importAgreed, setImportAgreed] = useState(false);
  const [showAgreed, setShow] = useState(false);
  const [privateKey, setPrivate] = useState("");
  let alert = useAlert();
  let dispatch = useDispatch();

  const agreeToTerms = (create) => {
    setShow(false);
    if (create) {
      setCreateAgreed(true);
    } else {
      setImportAgreed(true);
    }
  };
  useEffect(() => {
    if (createAgreed) {
      createWallet();
    }
  }, [createAgreed]);
  useEffect(() => {
    if (importAgreed) {
      handleImportWallet(null);
    }
  }, [importAgreed]);

  let createWallet = async () => {
    try {
      setSubmitting(true);
      let keypair;
      if (!createAgreed) {
        keypair = Keypair.random();
        setPrivate(keypair.secret());
        setShow(true);
        return;
      } else {
        keypair = Keypair.fromSecret(privateKey);
      }

      await fetch(
        BASE_URL + "/accounts/create",
        postOptions({
          publicKey: keypair.publicKey(),
          referral: history.location.state ? history.location.state : "",
          title: "free",
        })
      ).then((res) => res.json());

      updateAccount(keypair.publicKey());

      let secretEnc = encryptText(keypair.secret(), auth.password);

      // saveData({
      //   selectedWallet: JSON.stringify({
      //     public: keypair.publicKey(),
      //     private: secretEnc,
      //   }),
      // });
      dispatch(
        walletKey({
          public: keypair.publicKey(),
          private: secretEnc,
        })
      );

      setSubmitting(false);
      history.push("/dashboard");
    } catch (e) {
      setSubmitting(false);
    }
  };

  let setValue = (e) => {
    setKey(e.target.value);
  };

  let handleImportWallet = async (e) => {
    debugger;
    e?.preventDefault();
    if (key === "") {
      setValidation("Private key is required.");
      return;
    }

    setSubmitting(true);
    try {
      let keypair;
      if (!importAgreed) {
        keypair = Keypair.fromSecret(key);
        setPrivate(keypair.secret());
        setShow(true);
        return;
      } else {
        keypair = Keypair.fromSecret(privateKey);
      }

      await fetch(
        BASE_URL + "/accounts/create",
        postOptions({
          publicKey: keypair.publicKey(),
          referral: history.location.state ? history.location.state : "",
        })
      ).then((res) => res.json());

      let secretEnc = encryptText(keypair.secret(), auth.password);

      dispatch(
        walletKey({
          public: keypair.publicKey(),
          private: secretEnc,
        })
      );

      setSubmitting(false);
      alert.show("Wallet imported successfully!", { type: "success" });

      history.push("/dashboard");
    } catch (e) {
      setSubmitting(false);
      alert.show("Error occurred please try again.", { type: "error" });
    }
  };

  return {
    key,
    setValue,
    submitting,
    validation,
    showAgreed,
    agreeToTerms,
    privateKey,
    createWallet,
    handleImportWallet,
  };
  //  handleImportWallet,
};

export default useNewWallet;
