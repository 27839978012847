import React from "react";
import Ecommerce from "../components/Ecommerce/Ecommerce";

const ecommerce = () => {
  return (
    <div>
      <Ecommerce />
    </div>
  );
};

export default ecommerce;
