import React, { useState } from "react";
import CommonTable from "../common/CommonTable/CommonTable";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import DatePicker from "react-datepicker";
import styles from "./escrow.module.scss";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../common/CustomInput";
import DropDown from "../common/DropDown/dropDown";
import { Button } from "../common";
import Pagination from "react-js-pagination";
import TabNav from "../common/tabNav";
import styles2 from "../../components/common/CommonTable/CommonTable.module.scss";
import IconSquare from "../../assets/images/Icon-square.svg";
import useEscrow from "./useEsscrow";
import XLSX from "xlsx";
import UseApiKey from "../ApiKey/useApiKey";
import copyIcon from "../../assets/images/copy_icon.png";
import checkedIcon from "../../assets/images/checked_icon.png";
import DisputeModal from "../../components/common/disputeModal/index";
import moment from "moment";

const EscrowReport = () => {
  const { copyKey, copied } = UseApiKey();
  const {
    handlePageChange,
    pagination,
    data,
    count,
    dataSelected,
    setDataSelected,
    dataLimit,
    submitting,
    selectedOrder,
    setSelectedOrder,
    optionsOrder,
    pickedStartDate,
    setPickedStartDate,
    pickedEndDate,
    setPickedEndDate,
    searchHandler,
    searchState,
    searchData,
    handleDispute,
    toggle,
    isOpen,
    rowData,
    setrowData,
    fetchData,
  } = useEscrow();

  const CustomInputDate = React.forwardRef((props, ref) => {
    return (
      <div className={styles.customInput} onClick={props.onClick}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <FontAwesomeIcon icon={faCalendarAlt} color="#ffffff" />
      </div>
    );
  });
  const ExcelHeader = [
    "Status",
    "Fee",
    "Receiver Id",
    "Sender Id",
    "Total Amount",
    "Created At",
  ];
  const column = [
    {
      title: "Customer Public Key",
      key: "merchantPublicKey",
      render: (item, data) => {
        return (
          <>
            <div className={styles2.coinWrapper}>
              {item.substring(0, 10) + "..."}
              {copied.length > 0 && copied === item ? (
                <div className={styles2.icon}>
                  <img src={checkedIcon} />
                  <span className={styles2.copyLabel}> Copied</span>
                </div>
              ) : (
                <div className={styles2.icon} onClick={() => copyKey(item)}>
                  <img src={copyIcon} />
                  <span className={styles2.copyLabel}> Copy</span>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Merchant Public Key",
      key: "customerPublicKey",
      render: (item, data) => {
        return (
          <>
            <div className={styles2.coinWrapper}>
              {item.substring(0, 10) + "..."}
              {copied.length > 0 && copied === item ? (
                <div className={styles2.icon}>
                  <img src={checkedIcon} />
                  <span className={styles2.copyLabel}> Copied</span>
                </div>
              ) : (
                <div className={styles2.icon} onClick={() => copyKey(item)}>
                  <img src={copyIcon} />
                  <span className={styles2.copyLabel}> Copy</span>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Total Amount",
      key: "totalAmount",
      render: (data) => {
        return <span className={styles2.coinWrapper}>$ {data}</span>;
      },
    },
    {
      title: " Fee",
      key: "transactionFee",
      render: (data) => {
        return <span className={styles2.coinWrapper}>$ {data}</span>;
      },
    },
    ,
    {
      title: "Status",
      key: "status",
    },
    {
      title: "Order Number",
      key: "orderNumber",
    },
    {
      title: "Created Date",
      key: "createdAt",
      render: (item, data) => {
        return (
          <div className={styles2.coinWrapper}>
            {" "}
            <span>{moment(item).format("MMM Do YY")}</span>
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "status",
      render: (item, data) => {
        setrowData(data);
        return (
          <div className={styles.disputeParent}>
            <div className={styles.disputeButton}>
              {data.status == "Disputed" || item == "Pending" ? (
                <button
                  className={styles.disablebtn}
                  // onClick={() => handleDispute(data)}
                >
                  {data.status == "Disputed" ? "Disputed" : "Dispute"}
                </button>
              ) : (
                <button
                  className={styles.btn}
                  onClick={() => handleDispute(data)}
                >
                  Dispute
                </button>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const downloadHandler = () => {
    let arrayData = [];
    arrayData = searchData.map((item) => {
      let outputData = [];
      for (let i in item) {
        if (
          i !== "__v" &&
          i !== "_id" &&
          i !== "updatedAt" &&
          i !== "isInstant"
        ) {
          outputData.push(item[i]);
        }
      }
      return outputData;
    });
    arrayData.unshift(ExcelHeader);
    const ws = XLSX.utils.aoa_to_sheet(arrayData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "escrow-report.xlsx");
  };

  let renderBtn = () => {
    return (
      <div>
        {" "}
        <FontAwesomeIcon icon={faDownload} /> Download Excel
      </div>
    );
  };

  return (
    <>
      <SideBarWrapper>
        <TabNav />
        <DisputeModal
          isOpen={isOpen}
          toggle={toggle}
          param={rowData}
          fetchData={fetchData}
        />
        <div className={styles.container}>
          <div className={styles.tabWrapper}>
            <div className={styles.left}>
              <div className={styles.datepickerContainer}>
                <label>From</label>
                <div className={styles.datepickerWrapper}>
                  <DatePicker
                    selected={pickedStartDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setPickedStartDate(date);
                    }}
                    customInput={<CustomInputDate />}
                  />
                </div>
              </div>
              <div className={styles.datepickerContainer}>
                <label>To</label>
                <div className={styles.datepickerWrapper}>
                  <DatePicker
                    maxDate={new Date()}
                    selected={pickedEndDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setPickedEndDate(date);
                    }}
                    customInput={<CustomInputDate />}
                  />
                </div>
              </div>
              <div className={styles.datepickerContainer}>
                <label>Order Status</label>
                <div className={styles.datepickerWrapper}>
                  <DropDown
                    padding="10px 5px"
                    selected={selectedOrder}
                    setSelected={setSelectedOrder}
                    options={optionsOrder}
                  />
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.inputWrapper}>
                <CustomInput
                  labelStyle={"mb-0"}
                  placeholder="Search"
                  inputStyle={"rounded"}
                  padding={"1rem"}
                  value={searchState.value}
                  onChange={searchHandler}
                  // valMsg={validation}
                  searchIcon={true}
                  name="key"
                  type="text"
                />
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  type="submit"
                  styles={["bg-accent text-xs py-3.5 mt-0 w-full uppercase"]}
                  onClick={downloadHandler}
                >
                  {renderBtn()}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.tabWrapper}>
            <div className={styles.left}>
              {/* <div className={styles.datepickerContainer}>
                <div className={styles.datepickerWrapper}>
                  <DropDown
                    padding="10px 5px"
                    selected={dataSelected}
                    setSelected={setDataSelected}
                    options={dataLimit}
                  />
                </div>
              </div> */}
            </div>
          </div>

          <CommonTable
            column={column}
            heading=""
            maxHeight={"410px"}
            data={searchData}
            submitting={submitting}
          />
          {count && (
            <Pagination
              activePage={pagination.page}
              itemsCountPerPage={pagination.limit}
              totalItemsCount={count}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              activeClass="activeClass"
              innerClass="innerClass"
              itemClass="itemClass"
              hideFirstLastPages={true}
            />
          )}
        </div>
      </SideBarWrapper>
    </>
  );
};

export default EscrowReport;
