import React from 'react'
import styles from "../styles/underConstruction.module.scss"
const UnderConstructionPage = () => {
    return (
        <div className={styles.container}>
            
        </div>
    )
}

export default UnderConstructionPage;
