import * as React from "react";
// import { ProfileRightBlock } from "components/organisms/ProfileScreen/ProfileRightBlock";
// import { UploadDocumentsForm } from "components/organisms/ProfileScreen/UploadDocumentsForm";
import { CompanyDocumentsForm } from "./screens/CompanyDocumentsForm";
import KycStatusbar from "./screens/KycStatusbar";
import { GeneralInfoBlock } from "./screens/GeneralInfoBlock";
import BasicInfoForm from "./screens/BasicInfoForm";
import { UploadedDocInfo } from "./screens/UploadedDocInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteKycAction,
  getProfileInfoAction,
} from "../../store/actions/ProfileActions";

import { profileIndex, profileStatus } from "../../utils/constants";
import Loader from "react-loader-spinner";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import TabNav from "../common/tabNav";

export const KYCProfileTab = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state?.profile);
  const [tab, setTab] = React.useState(0);
  let { wallet, coins } = useSelector((state) => state);

  React.useEffect(() => {
    dispatch(getProfileInfoAction(wallet, coins));
  }, []);
  React.useEffect(() => {
    if (
      user.status === "pending" ||
      user.status === "approved" ||
      user.status === "rejected" ||
      user.companyDocument
    ) {
      setTab(3);
    } else if (
      user.status === "draft" &&
      user.isEmailVerified &&
      user.isPhoneVerified &&
      (user.file || user.companyName) &&
      (user.firstName || user.companyName)
    ) {
      setTab(2);
    } else if (
      user.status === "draft" ||
      user.isEmailVerified ||
      user.isPhoneVerified
    ) {
      setTab(1);
    } else if (!user.status || user.status == "") {
      setTab(0);
    }
  }, [user]);

  if (loading) {
    return (
      <div
        className="w-full mb-6 border border-primary p-5 flex justify-center items-center"
        style={{ minHeight: "100vh" }}
      >
        <Loader type="Bars" color="#FFFF00" height={30} width={30} />
      </div>
    );
  }
  if (error) {
    return (
      <div
        className="w-full pb-6 border border-primary p-5 flex justify-center items-center"
        style={{ minHeight: "100vh" }}
      >
        <h3 className="text-secondaryGray">
          Unable to load check you connection.
        </h3>
      </div>
    );
  }

  return (
    <>
      <SideBarWrapper>
        <TabNav />
        <div
          className="w-full pb-6 border border-primary p-5 flex flex-col"
          style={{ minHeight: "90vh" }}
        >
          <KycStatusbar tab={tab} />
          {tab === 0 && (
            <GeneralInfoBlock tab={tab} setTab={setTab} status={user.status} />
          )}
          {tab === 1 && <BasicInfoForm tab={tab} setTab={setTab} />}
          {tab === 2 && (
            <CompanyDocumentsForm
              tab={tab}
              setTab={setTab}
              status={user.status}
            />
          )}
          {tab === 3 && (
            <UploadedDocInfo
              user={user}
              tab={tab}
              setTab={setTab}
              status={user.status}
            />
          )}
        </div>
      </SideBarWrapper>
    </>
  );
};

export function BottomNav({ tab, setTab, submit, status, loading, edit }) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = React.useState(false);
  const handleNext = () => {
    if (status === profileStatus.approved) {
      return;
    }
    submit();
  };

  const handlePrev = () => {
    if (status === profileStatus.approved) {
      return;
    }
    if (edit) {
      setDeleting(true);
      dispatch(
        deleteKycAction((success) => {
          setDeleting(false);
          if (!success) {
          }
        })
      );
      return;
    }
    setTab(tab - 1);
  };
  return (
    <div className="flex justify-between">
      {tab === 0 || tab === 3 ? (
        <div />
      ) : (
        <button
          onClick={handlePrev}
          className="focus:outline-none bg-accent rounded py-2 px-10 text-s text-white hover:bg-transparent border border-accent hover:text-accent "
        >
          Back
        </button>
      )}
      {edit ? (
        <button
          onClick={handlePrev}
          className="focus:outline-none bg-accent rounded py-2 px-10 text-s text-white hover:bg-transparent border border-accent hover:text-accent "
        >
          {!deleting && "Resubmit"}
          {deleting && (
            <Loader type="Bars" color="#ffffff" height={15} width={15} />
          )}
        </button>
      ) : (
        <button
          onClick={handleNext}
          className="focus:outline-none bg-accent rounded py-2 px-10 text-s text-white hover:bg-transparent border border-accent hover:text-accent "
        >
          {!loading && (tab === 0 ? "Start Process" : "Next")}
          {loading && (
            <Loader type="Bars" color="#ffffff" height={15} width={15} />
          )}
        </button>
      )}
    </div>
  );
}
