import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/Menu.scss";
import "./styles/OwlCarousel.css";
import { Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../src/components/common/AlertTemplate";
import { store } from "../src/store/index";
import MainNav from "./components/navigation/MainNav";

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE,
};

function App() {
  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <div className="App">
          <Switch>
            <MainNav />
          </Switch>
        </div>
      </AlertProvider>
    </Provider>
  );
}

export default App;
