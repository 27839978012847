import React, { useEffect, useState } from "react";
import Slider from "./menu";
import styles from "./navigation.module.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../../assets/images/logo.svg";
import logoMenu from "../../../../assets/images/Icon-menu.svg";
import { useSelector } from "react-redux";

const Navigation = () => {
  const router = useLocation();
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [checklogin, setCheckLogin] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);

  let hash = localStorage.getItem("bcrypt_hash");
  let auth = useSelector((state) => state.auth);

  useEffect(() => {
    if ((hash != "") & (auth.password != "")) {
      setCheckLogin(true);
    }
  }, [hash, auth]);

  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftWrapper}>
          {checklogin ? (
            <>
              <Link to={"/dashboard"} className={styles.logo}>
                {/* <img src={logo} /> */}
              </Link>
            </>
          ) : (
            <Link to={"/"} className={styles.logo}>
              <img src={logo} />
            </Link>
          )}
        </div>

        <div className={styles.middleWrapper}></div>
        <div className={styles.rightWrapper}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <img src={logoMenu} />
          </div>
        </div>
      </div>
      <Slider
        menuOpenState={menuOpenState}
        setMenuOpenState={setMenuOpenState}
        stateChangeHandler={stateChangeHandler}
      />
    </>
  );
};

export default Navigation;
