import Axios from "axios";
import { BASE_URL } from "../../../utils/AppConstants";
import styles from "./Cards.module.scss";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Data from "./data";
import { useDispatch, useSelector } from "react-redux";
// const CryptoJS = require("crypto-js");
import CryptoJS from "crypto-js";
import { useAlert } from "react-alert";
import CardModal from "./paymentTypeModal/index";
import CustomInput from "../../common/CustomInput";

/////////*******Card *****///////
const Card = ({ item, instantPayment, escrowPayment }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  let { wallet } = useSelector((state) => state);
  const [isOpen, setisOpen] = useState(false);
  const [active, setactive] = useState(true);
  const [APIKeyStatus, setAPIKeyStatus] = useState("");

  const toggle = () => {
    setisOpen(false);
  };
  // useEffect(() => {
  //   // const string = utf8_to_b64(wallet?.key?.public);
  //   // console.log("string", string);
  //   var ciphertext = CryptoJS.AES.encrypt(
  //     wallet?.key?.public,
  //     "123"
  //   ).toString();
  //   console.log("encode string", ciphertext);

  // var bytes = CryptoJS.AES.decrypt(ciphertext, "123");
  // var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  //   console.log("decode string", decryptedData);
  // }, []);
  // const utf8_to_b64 = (str) => {
  //   return window.btoa(unescape(encodeURIComponent(str)));
  // };

  const checkStatus = async () => {
    try {
      // let a = "GDFG3HP2IUUJ4OUIE3O75YDLI6YIAFNHLFVELXVEEHMOCVECHKK2OYR6";
      const response = await Axios.get(
        `${BASE_URL}/keys-management/check-keys/${wallet?.key?.public}`
      );
      setAPIKeyStatus(response.data);
      console.log("rs---------", response);
    } catch (error) {
      console.log("error---------", error.response.data.message);
      if (error.response.data.message == "Merchant Public Key is wrong") {
        setactive(false);
      }
    }
  };

  const recieveStatus = async (item) => {
    let dynamicType;
    if (item == "instant") {
      dynamicType = true;
    } else if (item == "escrow") {
      dynamicType = false;
    }

    let Data = [
      {
        id: item.id,
        quantity: item.quantity,
        title: item.title,
      },
    ];
    let instantKey = CryptoJS.AES.encrypt(
      instantPayment,
      "Api11SecrEctKey222kRyptomInd.cOm218476brO"
    ).toString();

    let escrowKey = CryptoJS.AES.encrypt(
      escrowPayment,
      "Api11SecrEctKey222kRyptomInd.cOm218476brO"
    ).toString();
    let orderNo =
      String(Math.floor(100 + Math.random() * 900)) +
      "-acbb-" +
      String(Math.floor(100 + Math.random() * 900)) +
      "-axdnb";
    try {
      setLoading(true);
      let product = {
        products: Data,
        merchantPublicKey: wallet?.key?.public,
        totalAmount: 2,
        discount: 0,
        orderNumber: orderNo,
        isInstant: dynamicType,
        paymentType: item,
        service: "DARAZ",
        description:
          "This is a test product, If the test is successful then we can all have party.",
        "x-api-key": [
          {
            type: "Instant",
            key: instantKey,
          },
          {
            type: "Escrow",
            key: escrowKey,
          },
        ],
      };
      const res = await Axios.post(
        `${BASE_URL}/ordered-transactions`,
        product,
        {
          headers: {
            "Content-Type": "application/json",
            // "x-api-key":
            // "Sk_test_bMgXV6EQ1GEuUtUJ/DiKc//vh83QKIELzSIC8Ykg5iTo/g+zVdrzlfD5ygreOJXW",
            // "Sk_test_oH3yWd1fnnlRGK0CDv4N4sET/1nIiOpxIq057gp4B034hsnQafy3Y6M9DClpdHCH",

            // Authorization: `x-api-key ${wallet?.key?.public}`,
          },
        }
      );
      window.open(res?.data?.link);
      setLoading(false);
    } catch (err) {
      alert.show(err?.response?.data?.message, { type: "error" });
      setLoading(false);
    }
    // else if (response.data == "both") {
    //   dynamicType = true;
    // }
  };
  useEffect(() => {
    if (wallet?.key?.public) {
      checkStatus();
    }
  }, [wallet?.key?.public]);
  const handleSubmit = async (item) => {
    console.log("iiiiii-----", instantPayment);
    console.log("eeeeee-----", escrowPayment);
    if (!(instantPayment || escrowPayment)) {
      alert.show("Please enter API Key first", {
        type: "error",
      });
      return;
    }
    if (!active) {
      alert.show("No API Key found. Please contact GPay support", {
        type: "error",
      });
      return;
    }
    setisOpen(true);

    // return;
    const response = await Axios.get(
      `${BASE_URL}/keys-management/check-keys/${wallet?.key?.public}`
    );

    // var ciphertext = CryptoJS.AES.encrypt(
    //   wallet?.key?.public,
    //   "123"
    // ).toString();
    // console.log("encode string>>>>>>>>> ", ciphertext);
    // return;
  };
  return (
    <div className={styles.card} key={item.id}>
      <div className={styles.cardSides}>
        <div className={styles.leftSide}>
          <img src={item.img} alt="" />
        </div>
        <div className={styles.rightSide}>
          <label>{item.title}</label>
          <p>{item.Dec}</p>
        </div>
      </div>

      <button className={styles.btn} onClick={() => handleSubmit(item)}>
        {loading ? <Loader height={28} width={28} /> : "Buy"}
      </button>
      <CardModal
        isOpen={isOpen}
        toggle={toggle}
        APIKeyStatus={APIKeyStatus}
        recieveStatus={recieveStatus}
      />
    </div>
  );
};
/////////*******Card ******/////////////
const Cards = () => {
  const [loading, setLoading] = useState(false);
  const [instantPayment, setinstantPayment] = useState();
  const [escrowPayment, setescrowPayment] = useState();

  const dispueUser = async () => {
    debugger;
    try {
      setLoading(true);
      let product = {
        userEmail: "ali.asghar@kryptomind.com",
        orderNumber: "222-acbb-222-axxx",
        releaseTo: "",
        disputedBy: "user",
        totalAmount: "2",
        fee: "0.02",
      };
      const res = await Axios.post(
        `${BASE_URL}/dispute/create-user-dispute`,
        product
      );
      window.open(res?.data?.link);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert.show(err?.response?.data?.error, { type: "error" });
    }
  };
  const handleInstantInputChange = (e) => {
    setinstantPayment(e.target.value);
  };

  const handleEscrowInputChange = (e) => {
    setescrowPayment(e.target.value);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Shop Now</div>
          <div className={styles.inputs}>
            <CustomInput
              placeholder={"Instant Key"}
              value={instantPayment}
              onChange={handleInstantInputChange}
              // valMsg={}
              name="title"
              type="text"
            />
            <CustomInput
              placeholder={"Escrow Key"}
              value={escrowPayment}
              onChange={handleEscrowInputChange}
              // valMsg={}
              name="title"
              type="text"
            />
          </div>

          {/* <button className={styles.btn} onClick={dispueUser}>
            {loading ? <Loader height={28} width={28} /> : "Dispute"}
          </button> */}
          <div className={styles.cardWrapper}>
            {Data.map((item) => (
              <>
                <Card
                  item={item}
                  instantPayment={instantPayment}
                  escrowPayment={escrowPayment}
                />
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
