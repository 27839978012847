import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getJWT } from "../../services/httpServices";
import { BASE_URL } from "../../utils/AppConstants";
import { useAlert } from "react-alert";

const useDashboard = () => {
  const [submitting, setSubmitting] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  });
  const [dataOrder, setDataOrder] = useState([]);
  const [count, setCount] = useState(null);
  let { wallet } = useSelector((state) => state);
  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.limit]);

  const alert = useAlert();

  const handlePageChange = async (pageNumber) => {
    setPagination((prev) => ({ ...prev, page: pageNumber }));
  };
  const fetchData = async () => {
    setSubmitting(true);
    try {
      const token = await getJWT();
      let res = await axios.get(
        `${BASE_URL}/order-histories/merchant?page=${pagination.page}&limit=${pagination.limit}&pubKey=${wallet?.key?.public}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token?.data?.token}`,
          },
        }
      );

      setDataOrder(res?.data?.merchantResults);
      setCount(res.data.merchantCount);
      setSubmitting(false);
    } catch {
      alert.show("Something Went Wrong", { type: "error" });
      setSubmitting(false);
    }
  };
  return { wallet, handlePageChange, pagination, dataOrder, count, submitting };
};

export default useDashboard;
