import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import DynamicImage from "./dynamicFooterIcons";
import styles from "./footer.module.scss";
import logo from "../../../assets/images/logo.svg";
import { useLocation, useHistory } from "react-router-dom";
function FooterLarge() {
  const location = useLocation();
  let history = useHistory();

  const topFunction = () => {
    if (location.pathname == "/") {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      history.push("/");
    }
  };

  const topFunctioncontactUs = () => {
    if (location.pathname == "/contactUs") {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      history.push("/contactUs");
    }
  };
  return (
    <footer id="myFooter" className={styles.container}>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <div className={styles.img}>
            <img layout="responsive" width="100%" height="100%" src={logo} />
          </div>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem .
          </p>
        </div>
        <div className={styles.footerMiddle}>
          <div className={styles.column}>
            <h3>Company</h3>
            <div onClick={topFunction} className={styles.link}>
              About
            </div>
            <div onClick={topFunctioncontactUs} className={styles.link}>
              Support
            </div>
          </div>
        </div>
        <div className={styles.footerMiddle}>
          <div className={styles.column}>
            <h3>Navigation</h3>
            <a
              className={styles.link}
              onClick={() => window.location.replace("/#PaymentPlan")}
            >
              Pricing plans
            </a>
          </div>
        </div>
        <div className={styles.footerMiddle}>
          <div className={styles.column}>
            <h3>Policies</h3>
            <Link to="/terms" className={styles.link}>
              Terms of Usage
            </Link>
            <Link to="/privacy" className={styles.link}>
              Privacy policy
            </Link>
            <Link to="/disclaimer" className={styles.link}>
              Disclaimer
            </Link>
          </div>
        </div>
        {/* <div className={styles.footerRight}>
        <h3>Social Icons</h3>
          <div className={styles.socialContainer}>
            <a to="/">
              <DynamicImage name="facebook" />
            </a>
            <a to="/">
              <DynamicImage name="twitter" />
            </a>
            <a to="/">
              <DynamicImage name="telegram" />
            </a>
            <a to="/">
              <DynamicImage name="instagram" />
            </a>
          </div>
        </div> */}
      </div>
      <div className={styles.bottom}>
        <text>GPAY. Copyrights. All Rights Reserved.</text>
      </div>
    </footer>
  );
}

export default FooterLarge;
