import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import styles from "./menu.module.scss";

const Slider = ({ menuOpenState, stateChangeHandler, setMenuOpenState }) => {
  return (
    <Menu
      right
      disableAutoFocus
      customBurgerIcon={false}
      isOpen={menuOpenState}
      onStateChange={(state) => stateChangeHandler(state)}
      itemListElement="div"
    >
      <div className="bm-item">
        <Link to="/">
          <div
            onClick={() => setMenuOpenState(false)}
            className="burger-menu-link"
          >
            
            <div className="burger-menu-link__text">Home</div>
          </div>
        </Link>
      </div>
      <div className="bm-item">
        <Link to="/product">
          <div
            onClick={() => setMenuOpenState(false)}
            className="burger-menu-link"
          >
            <div className="burger-menu-link__text">API Docs</div>
          </div>
        </Link>
      </div>
      <div className="bm-item">
        <Link to="/contact">
          <div
            onClick={() => setMenuOpenState(false)}
            className="burger-menu-link"
          >
            <div className="burger-menu-link__text">Pricing Plan</div>
          </div>
        </Link>
      </div>
      <div className="bm-item">
        <Link to="/about">
          <div
            onClick={() => setMenuOpenState(false)}
            className="burger-menu-link"
          >
            <div className="burger-menu-link__text">Contact Us</div>
          </div>
        </Link>
      </div>
      <div className="bm-item">
        <Link to="/login">
          <div
            onClick={() => setMenuOpenState(false)}
            className="burger-menu-link"
          >
            <div className={styles.buttonWrapper}>
              <button className={styles.button}>
                <span className={styles.text}>Login</span>
              </button>
            </div>
          </div>
        </Link>
      </div>
      <div className="bm-item">
        <Link to="/register">
          <div
            onClick={() => setMenuOpenState(false)}
            className="burger-menu-link"
          >
            <div className={styles.buttonWrapper}>
              <button className={styles.button}>
                <span className={styles.text}>Sign Up</span>
              </button>
            </div>
          </div>
        </Link>
      </div>
    </Menu>
  );
};

export default Slider;
