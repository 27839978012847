import React from 'react';
import CustomInput from '../common/CustomInput';
import useNewWallet from './useNewWallet';
import { Button } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { WalletCreated } from '../WalletCreated';

function ImportWallet() {
    let { key, setValue, validation, handleImportWallet, submitting, agreeToTerms, privateKey, showAgreed } = useNewWallet()

    if(showAgreed){
      return <WalletCreated callback={() => agreeToTerms(false)} privateKey={privateKey} myKey={key} />
    }
    return(
      <div className="flex justify-center">
            <form onSubmit={handleImportWallet} className="w-4/5 sm:w-1/3">
      <p className="text-white py-3 text-xl md:text-title lg:text-title xl:text-title text-center mb-6">
        Import your Wallet
      </p>
      <CustomInput
        labelStyle={"mb-3"}
        placeholder="Enter your private key"
        value={key}
        onChange={setValue}
        valMsg={validation}
        name="key"
        type="text"
      />
   
      <Button
        type="submit"
        styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
      >
        {submitting?<div><FontAwesomeIcon icon={faSync} color="#ffffff" spin /> Importing...</div>:'Import'}
      </Button>

    </form>
    </div>
    )
}
export default ImportWallet