import React from "react";
import { Redirect, Route } from "react-router-dom";
import { checkAuthState } from "../../services/authServces";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let auth = useSelector((state) => state.auth);
  let res = checkAuthState(auth.password);
  
  return (
    <Route
      {...rest}
      render={(props) => {
        if (res === "PASS") return <Component {...props} />;
        if (res === "LOGIN_SCREEN") return <Redirect to="/login" />;
        if (res === "REGISTER") return <Redirect to="/register" />;
      }}
    />
  );
};

export default PrivateRoute;
