import axios from "axios";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../utils/AppConstants";
// import { HTTP_CLIENT } from "../../../utils/AxiosClient";
import GenericButton from "../GenericButton/genericButton";
import GenericModal from "../genericModal/index";
import styles from "./deleteModal.module.scss";

const UserModal = ({ isOpen, toggle, param, fetchData }) => {
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (name) => {
    let status = "";
    let payload;
    let disputePayload = {
      fee: String(param.transactionFee),
      merchantPubKey: param.merchantPublicKey,
      orderNumber: param.orderNumber,
      releaseTo: "",
      totalAmount: String(param.totalAmount),
      userEmail: "ali.asghar@kryptomind.com",
    };

    if (name == "release") {
      setloading(true);
      status = "Merchant";
      disputePayload.disputedBy = "merchant";
      payload = {
        totalAmount: String(param.totalAmount),
        orderNumber: param.orderNumber,
        fee: String(param.transactionFee),
        release: true,
      };
    } else if (name == "decline") {
      status = "User";
      setSubmitting(true);
      disputePayload.disputedBy = "merchant";
      payload = {
        totalAmount: String(param.totalAmount),
        orderNumber: param.orderNumber,
        fee: String(param.transactionFee),
        release: false,
      };
    }

    let data = { disputePayload, payload };
    try {
      const response = await axios.post(
        `${BASE_URL}/transactions/escrow-payments`,
        data
      );
      // setTimeout(() => {
      alert.show(`Transaction has been send to ${status}`, {
        type: "success",
      });
      setloading(false);
      setSubmitting(false);
      toggle();
      // }, 4000);
      fetchData();
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
      setloading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <GenericModal isOpen={isOpen} toggle={() => toggle("delete")}>
        <div className={styles.alert}>
          <img src={"/assets/dispute.svg"} />
        </div>

        <div className={styles.mainHeading}>Where you want to dispute ?</div>
        <div className={styles.buttonWrapper}>
          <GenericButton
            submitting={loading}
            onClick={() => handleSubmit("release")}
            title="Merchant as Winner"
            fontsize="14px"
          />
          <GenericButton
            submitting={submitting}
            onClick={() => handleSubmit("decline")}
            title="User as Winner"
            fontsize="14px"
          />
        </div>
      </GenericModal>
    </>
  );
};

export default UserModal;
