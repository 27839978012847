import React, { useState } from "react";
import { Text } from "../common/index";
import CustomInput from "../common/CustomInput";
import { Button } from "../common/index";
import { NavLink, useLocation } from "react-router-dom";
import useRegister from "./useRegister";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import TermsAndConditions from "./termAndConditions";

// var Checkbox = require('rc-checkbox');
const NewSessionPassword = (props) => {
  let [isModalOpen, setIsOpen] = useState(false);
  const {
    isAgree,
    setAgree,
    password,
    confirm,
    referral,
    handleInputChange,
    handleSubmit,
    notAgreed,
    submitting,
    passwordValidation,
    confirmValidation,
  } = useRegister();
  const { styles = [" "] } = props;
  const location = useLocation();

  let renderBtn = () => {
    if (submitting) {
      // return <div> <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...</div>
    }
    return location.state?.reset ? "Login" : "Create Account";
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full flex justify-center">
        <Text Title Color={"white"}>
          New Session Password
        </Text>
      </div>
      <Text SubTitle Center Style={["py-4"]} Color="secondaryGray">
        This wallet doesn’t require any personal user information to sign up.
        The app only requires a password to encrypt your sensitive data on this
        device
      </Text>

      <div className={"mt-7"}>
        <CustomInput
          showIcon
          placeholder={"Password"}
          value={password}
          onChange={handleInputChange}
          valMsg={passwordValidation}
          name="password"
          type="password"
        />

        <div className={"mt-4"}>
          <CustomInput
            showIcon
            placeholder={"Confirm Password"}
            name="confirm"
            value={confirm}
            onChange={handleInputChange}
            valMsg={confirmValidation}
            type="password"
          />
        </div>
      </div>
      {/* <div className="mt-5">
        <CustomInput
          labelStyle={"mb-2"}
          label={"Refer By A Friend?"}
          name="referral"
          value={referral}
          onChange={handleInputChange}
          placeholder={"Referral ID (Optional)"}
          type="text"
        />
      </div> */}

      {/* <div className="w-full">
        <p className="text-white text-sm text-secondaryGray ">
          Your commission kickback rate: 0%
        </p>
      </div> */}
      <div className="w-full mt-5">
        <span className={"flex"}>
          <input
            onClick={() =>
              setAgree((prev) => ({
                ...prev,
                isAgree: !prev.isAgree,
                notAgreed: false,
              }))
            }
            className="mr-2 mt-1 lg:mt-0"
            type="checkbox"
            checked={isAgree}
          />
          <Text SubTitle Color={"white"}>
            <span
              className="cursor-pointer"
              onClick={() =>
                setAgree((prev) => ({
                  ...prev,
                  isAgree: !prev.isAgree,
                  notAgreed: false,
                }))
              }
            >
              I am over age 18 , and I agree to GPay’s
            </span>
            <span className="md:px-0 lg:px-1 sm:px-0 px-0 text-accent cursor-pointer">
              {" "}
              <NavLink to="/terms" target="_blank">
                Terms &amp; Conditions.
              </NavLink>
            </span>
          </Text>
        </span>
        {notAgreed && (
          <small className="text-red italic">
            You need to agree to our terms and conditions.
          </small>
        )}
      </div>
      <Button
        type="submit"
        styles={["bg-accent text-sm py-4 mt-8 w-full uppercase mb-7"]}
      >
        {renderBtn()}
      </Button>

      <TermsAndConditions
        isOpen={isModalOpen}
        onClose={() => setIsOpen(false)}
      />
    </form>
  );
};

export default NewSessionPassword;
