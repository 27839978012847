import * as React from "react";
import { BottomNav } from "../index";
import "../../../styles/inputFile.css";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import importantMsg from "../../../assets/images/important-message.svg"
export const GeneralInfoBlock = ({
  tab,
  setTab,
  status,
  
}) => {
  const coins = useSelector((state) => state.wallet.coins);
  const alert = useAlert();


  const handleNext = () => {
    if (coins && coins.length > 0) {
      setTab(tab + 1);
    } else {
      alert.show("You need to activate your wallet before proceeding.");
    }
  };
  return (
    <div className="flex-1 flex flex-col justify-between ">
      <div className="">
        <h1 className="text-white text-2xl mt-12">
          Know Your Customers Profile
        </h1>
        {!coins ||
          (coins.length === 0 && (
            <div className="flex items-center mt-4">
              <img src={importantMsg} className="w-4 h-4" />
              <label className="text-red font-regular text-s ml-2">
              <a target="_blank" href="https://laboratory.stellar.org/#account-creator?network=test">Activate your wallet before proceeding to KYC</a>
           
              </label>
            </div>
          ))}
        <p
          className="text-secondaryGray font-regular text-s"
          style={{ marginTop: "16px" }}
        >
          Know Your Customer (KYC) refers to the process of verifying you or
          your company identity.
        </p>
        <p className="text-secondaryGray font-regular mt-2 text-s">
          KYC on this platform is for the most part optional. Except when
          required by certain financial service providers.
        </p>
        <p className="text-secondaryGray font-regular mt-2 text-s">
          All KYC info is stored encrypted on your local device with encrypted
          backups on a remote server. We keep remote encrypted backups in order
          to synchronize your KYC info across all of your devices.
        </p>
        <p className="text-secondaryGray font-regular mt-2 text-s">
          Your KYC is encrypted with your secret key, so even we do not have
          access to your data.
        </p>
        <p
          className="text-secondaryGray font-regular text-s"
          style={{ marginTop: "12px" }}
        >
          You can verify your identity as an individual or a company. Select
          below KYC type.
        </p>
      </div>

      <BottomNav
        tab={tab}
        setTab={setTab}
        edit={false}
        submit={handleNext}
        loading={false}
        status={status}
      />
    </div>
  );
};
