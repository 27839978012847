import React, { useState } from "react";
import KycStatusTab, { KycStatusTab2 } from "./KycStatusTab";
import Icon from "../kycIcons";

function KycStatusbar({tab}) {
  const getQuestionImage = () =>
    tab === 0 ? Icon.QuestionGray : Icon.QuestionGreen;
  const getExclamImg = () => {
    let img = "";
    if (tab < 1) {
      img = Icon.exclaimGray;
    }
    if (tab === 1) {
      img =Icon.exclaimWhite;
    }
    if (tab > 1) {
      img = Icon.exclaimGreen;
    }
    return img
  };
  const getDocImg = () => {
    let img = "";
    if (tab < 2) {
      img = Icon.docGray;
    }
    if (tab === 2) {
      img = Icon.docWhite;
    }
    if (tab > 2) {
      img = Icon.docGreen;
    }
    return img
  };
  const getVerificImg = () => {
    let img = "";
    if (tab < 3) {
      img = Icon.verifyGray;
    }
    if (tab === 3) {
      img = Icon.verifyWhite;
    }
    if (tab > 3) {
      img = Icon.verifyGreen;
    }
    return img
  };
  return (
    <div className="w-full flex items-center p-8 mb-6">
      <KycStatusTab2 
        active={tab > 0}
        leading={getQuestionImage()}
        trailing={getExclamImg()}
        bottom={"text"}
      />
      <KycStatusTab
        active={tab >= 2}
        trailing={getDocImg()}
        bottom={"ID Documentation"}
        bottomMargin="-35px"
      />
      <KycStatusTab
        active={tab >= 3}
        trailing={getVerificImg()}
        bottom={"Verification"}
        bottomMargin="-20px"
      />
    </div>
  );
}

export default KycStatusbar;
