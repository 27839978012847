import React from "react";
import GenericModal from "../../common/genericModal/index";
import styles from "./changePassword.module.scss";
import { Button } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "../../common/CustomInput";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const changePassword = ({ isOpen, toggle }) => {
  let renderBtn = () => {
    // if(submitting) {
    //  return <div> <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...</div>
    // }
    return <div> Update</div>;
  };
  const changeHandler = () => {};
  return (
    <>
      <GenericModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainHeading}>Change Password</div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>Old Password</div>
          <CustomInput
            showIcon
            placeholder={"Old Password"}
            // value={password}
            // onChange={handleInputChange}
            // valMsg={passwordValidation}
            name="Enter Old Password"
            type="password"
          />
        </div>
        <div className={styles.fieldWrapper}>
        <div className={styles.label}>New Password</div>
          <CustomInput
            showIcon
            placeholder={"New Password"}
            // value={password}
            // onChange={handleInputChange}
            // valMsg={passwordValidation}
            name="password"
            type="password"
          />
        </div>
        <div className={styles.fieldWrapper}>
        <div className={styles.label}>Repeat Password</div>
          <CustomInput
            showIcon
            placeholder={"Repeat Password"}
            // value={password}
            // onChange={handleInputChange}
            // valMsg={passwordValidation}
            name="password"
            type="password"
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
            // onClick={submitHandler}
          >
            {renderBtn()}
          </Button>
        </div>
      </GenericModal>
    </>
  );
};

export default changePassword;
