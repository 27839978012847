import React from 'react'
import Setting from '../components/Settings/settings'

const SettingsPage = () => {
    return (
        <>
            <Setting/>
        </>
    )
}

export default SettingsPage
