import React from "react";
import PaymentPlan from "../components/PaymentPlan";

const PaymentPlanPage = () => {
  return (
    <div>
      <PaymentPlan />
    </div>
  );
};

export default PaymentPlanPage;
