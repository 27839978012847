const GenericButtonStyleMd = "rounded py-3 focus:outline-none";
const GenericButtonStyleSm = "rounded py-3 focus:outline-none";
const GenericButtonStyleLg = "rounded py-3 focus:outline-none";
const GenericButtonStyleXl = "rounded py-3 focus:outline-none";

export const GenericBtnStyle =
 `md:${GenericButtonStyleMd} 
  sm:${GenericButtonStyleSm} 
  lg:${GenericButtonStyleLg} 
  xl:${GenericButtonStyleXl} 
 text-white py-2 focus:outline-none`;
