import React, { useState, useEffect } from "react";
import styles from "../payment.module.scss";
import circle from "../../../assets/images/check-circle.svg";
import DropDown from "../../common/DropDown/dropDown";
import Plans from "../../Modals/Plans/Plans";
import Success from "../../Modals/Success/Success";
import Insufficient from "../../Modals/Insufficient/Insufficient";
import numbro from "numbro";
import { useAlert } from "react-alert";

const PaymentCard = ({
  data,
  availableBal,
  disable,
  fetchDataPlans,
  coins,
  tableData,
}) => {
  // console.log("response second", resp.data);
  const [selected, setSelected] = useState("1 Month");
  const [openPlan, setOpenPlan] = useState(false);
  const [responseMsg, setResponseMsg] = useState(null);
  const alert = useAlert();

  const options = data?.duration.map((item) => {
    return `${item} ${item === 1 ? "Month" : "Months"}`;
  });

  const choseplane = () => {
    if (coins.length === 0) {
      alert.show("Please activate your Wallet", { type: "error" });
    }
  };

  const planHandler = () => {
    setOpenPlan(!openPlan);
    setResponseMsg(null);
  };
  const responseHandler = (msg) => {
    setResponseMsg(msg);
  };
  {
  }
  // useEffect(() => {
  //   const transferCoin = coins.coinsData.find((item) => item.symbol === "xlm");
  //   const availableBal=(transferCoin.balance - transferCoin.selling_laibilities) * transferCoin.current_price;
  //   setAvailableBal(availableBal)
  // }, []);

  const currentPlan = () => {
    alert.show("your plan is already activated", { type: "error" });
  };
  return (
    <>
      <div className={styles.card}>
        <div className={styles.heading}>{data?.title}</div>
        <div className={styles.middle}>
          <div className={styles.textWrapper}>
            {/* <div className={styles.icon}>
              <img src={circle} width={"80%"} />
            </div> */}
            {/* <div className={styles.text}>
              {data?.apisPerSecond} APIs per sec.
            </div> */}
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.icon}>
              <img src={circle} width={"80%"} />
            </div>
            <div className={styles.text}>
              Total API Keys {data?.apisPerDay}
              {/* {`${numbro().format({
                average: true,
                mantissa: 1,
              })} Total API ${data?.apisPerDay}`} */}
            </div>
          </div>
          <div className={styles.dropdownWrapper}>
            <div className={styles.label}>Duration</div>
            <DropDown
              selected={selected}
              setSelected={setSelected}
              options={options}
            />
          </div>

          <div className={styles.priceWrapper}>
            <div className={styles.text}>
              {data?.price * selected.substr(0, selected.indexOf(" "))} USDT
            </div>
          </div>
          {disable > 0 && (
            <div className={styles.balanceWrapper}>
              <div className={styles.textLeft}>Available Balance:</div>
              <div className={styles.textRight}>
                {availableBal ? availableBal?.toFixed(4) : 0} USDT
                {/* {data?.price * selected.substr(0, selected.indexOf(" "))}USDT */}
              </div>
            </div>
          )}

          {data?.price * selected.substr(0, selected.indexOf(" ")) >
            availableBal && (
            <div className={styles.noBalanceWrapper}>
              <div className={styles.textLeft}>
                * You have Insufficent Balance
              </div>
            </div>
          )}
          {data?.userBalance && (
            <div className={styles.balanceWrapper}>
              <div className={styles.textLeft}>Payable Balance:</div>
              <div className={styles.textRight}>
                {data?.userBalance ? data?.userBalance : 0} USDT
                {/* {data?.price * selected.substr(0, selected.indexOf(" "))}USDT */}
              </div>
            </div>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          {/* {data.check == "Activated" ? (
            <button
              className={
                data.check == "Activated" || data.check == "Pending"
                  ? styles.buttonTwo
                  : styles.button
              }
              disabled={
                data?.price * selected.substr(0, selected.indexOf(" ")) >
                  availableBal || disable === 0
              }
            >
              <span className={styles.text}>
                {data.check == "Activated"
                  ? "Current Plan "
                  : data.check == "Pending"
                  ? "Pending"
                  : "Upgrade Plans"}
              </span>
            </button>
          ) : ( */}
          <button
            className={
              data.check == "Activated" || data.check == "Pending"
                ? styles.buttonTwo
                : styles.button
            }
            onClick={data.check !== "Activated" ? planHandler : currentPlan}
            disabled={
              data?.price * selected.substr(0, selected.indexOf(" ")) >
                availableBal || disable === 0
            }
          >
            <span
              onClick={data.check !== "Activated" ? choseplane : ""}
              className={styles.text}
            >
              {data.check == "Activated"
                ? "Current Plan "
                : data.check == "Pending"
                ? "Pending"
                : "Upgrade Plan"}
            </span>
          </button>

          {/* ) : (
            <button
              className={styles.buttonTwo}
              // onClick={planHandler}
              // disabled={
              //   data?.price * selected.substr(0, selected.indexOf(" ")) >
              //     availableBal || disable === 0
              // }
            >
              <span className={styles.text}>Activated</span>
            </button>
          )} */}
        </div>
      </div>
      <Plans
        isOpen={openPlan}
        toggle={planHandler}
        toggleMsg={responseHandler}
        selected={selected}
        data={data}
        availableBal={availableBal}
        fetchDataPlans={fetchDataPlans}
      />
      {responseMsg === "Success" && (
        <Success isOpen={openPlan} toggle={planHandler} />
      )}
      {responseMsg === "Failed" && (
        <Insufficient isOpen={openPlan} toggle={planHandler} />
      )}
    </>
  );
};

export default PaymentCard;
