import React, { useEffect, useState } from "react";
import CustomInput from "../common/CustomInput";
import SideBarWrapper from "../common/sidebarWrapperAdminPanel";
import TabNav from "../common/tabNav";
import styles from "./dashboard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import InfoCard from "./infoCard";
import CommonTable from "../common/CommonTable/CommonTable";
import { orderHistory } from "../../dump";
import useDashboard from "./useDashboard";
import checkedIcon from "../../assets/images/checked_icon.png";
import { loadCoinsData } from "../../store/actions/coinActions";
import { fetchCoinMarketData, getJWT } from "../../services/httpServices";
import styles2 from "../../components/common/CommonTable/CommonTable.module.scss";
import IconSquare from "../../assets/images/Icon-square.svg";
import importantMsg from "../../assets/images/important-message.svg";
import Pagination from "react-js-pagination";
import axios from "axios";
import { BASE_URL } from "../../utils/AppConstants";

const data = [
  {
    name: "jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "April",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "June",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "July",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Aug",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Sep",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Oct",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Nov",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Dec",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
];
const column = [
  {
    title: "Order Number",
    key: "orderNumber",
  },
  // {
  //   title: "Ref Order Number",
  //   key: "refNumber",
  // },
  {
    title: "Total Amount",
    key: "totalAmount",
  },
  // {
  //   title: "Merchant commission",
  //   key: "transactionFee",
  // },
  {
    title: "G Pay Commission",
    key: "transactionFee",
  },
  {
    title: "Payable Amount",
    key: "payableAmount",
  },
  // {
  //   title: "Details",
  //   key: "_id",
  //   render: (data) => {
  //     return (
  //       <span className={styles2.coinWrapper}>
  //         <img
  //           src={IconSquare}
  //           onClick={() => {
  //             // getId(data)
  //           }}
  //         />
  //       </span>
  //     );
  //   },
  // },
];

const Dashboard = () => {
  const { wallet, handlePageChange, pagination, dataOrder, count, submitting } =
    useDashboard();
  const dispatch = useDispatch();
  const { coins } = useSelector((state) => state);
  const [apiStatus, setApiStatus] = useState("year");
  const [apiHit, setApiHit] = useState([]);
  const [order, setOrder] = useState({});

  useEffect(async () => {
    dispatch(loadCoinsData());
    // getToken();
  }, []);

  // const getToken = async() =>{
  //   await getJWT();
  // }

  const handleApiStatus = (data) => {
    setApiStatus(data);
  };

  useEffect(() => {
    fetchApiHitData();
    fetchOrder();
  }, [apiStatus]);

  const fetchApiHitData = async () => {
    // setSubmitting(true);
    try {
      // const token = await getJWT();
      let res = await axios.get(
        `${BASE_URL}/order-histories/get-user-api-hit?day=${apiStatus}&userId=${wallet?.key?.public}`
      );
      setApiHit(res.data.results);
      // setSubmitting(false);
    } catch {
      // alert.show("Something Went Wrong", { type: "error" });
      // setSubmitting(false);
    }
  };

  const fetchOrder = async () => {
    try {
      // const token = await getJWT();
      let res = await axios.get(
        `${BASE_URL}/order-histories/get-total-orders/${wallet?.key?.public}`
      );
      setOrder(res?.data);
      // setSubmitting(false);
    } catch {
      // alert.show("Something Went Wrong", { type: "error" });
      // setSubmitting(false);
    }
  };

  return (
    <>
      <SideBarWrapper>
        <TabNav />
        <div className={styles.dashboardcontainer}>
          {!coins?.coinsData ||
            (coins?.coinsData?.length === 0 && (
              <div className={styles.activateAccount}>
                <img src={importantMsg} className={styles.img} />
                <label className={styles.text}>
                  <a
                    target="_blank"
                    href="https://laboratory.stellar.org/#account-creator?network=test"
                  >
                    Activate your wallet before proceeding
                  </a>
                </label>
              </div>
            ))}
          <div className={styles.keyWrapper}>
            <p className={styles.label}>Stellar Public Key</p>
            <CustomInput
              showIcon={true}
              labelStyle={"mb-3"}
              placeholder="Enter your password"
              value={wallet?.key?.public}
              type="password"
              copy={true}
              publicKey={wallet?.key?.public}
            />
          </div>
          <InfoCard order={order} />
          <div className={styles.chartWrapper}>
            <div className={styles.chartTab}>
              <div className={styles.title}>API Hit Analytics</div>
              <div className={styles.filter}>
                <div
                  className={styles.tab}
                  onClick={() => handleApiStatus("day")}
                >
                  Day
                </div>
                <div
                  className={styles.tab}
                  onClick={() => handleApiStatus("week")}
                >
                  Week
                </div>
                <div
                  className={styles.tab}
                  onClick={() => handleApiStatus("month")}
                >
                  Month
                </div>
                <div
                  className={styles.tab}
                  onClick={() => handleApiStatus("year")}
                >
                  Year
                </div>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={apiHit}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid
                  strokeDasharray="3 0"
                  horizontal={false}
                  stroke="#707070"
                />
                <XAxis dataKey="label" />
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#e48d0f" stopOpacity={0.9} />
                    <stop offset="25%" stopColor="#e48d0f" stopOpacity={0.4} />
                    <stop offset="95%" stopColor="#e48d0f" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="apiHit"
                  stroke="#e48d0f"
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <CommonTable
            column={column}
            heading="Recent Order History"
            maxHeight={"410px"}
            data={dataOrder}
            submitting={submitting}
          />
          {true && (
            <Pagination
              activePage={pagination.page}
              itemsCountPerPage={pagination.limit}
              totalItemsCount={count}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              activeClass="activeClass"
              innerClass="innerClass"
              itemClass="itemClass"
              // hideFirstLastPages={true}
            />
          )}
        </div>
      </SideBarWrapper>
    </>
  );
};

export default Dashboard;
