import React, { useState, useEffect } from "react";
import GenericModal from "../../common/genericModal/index";
import styles from "./apiKey.module.scss";
import { Button } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "../../common/CustomInput";
import {
  faDownload,
  faSyncAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import DropDown from "../../common/DropDown/dropDown";
import { getJWT } from "../../../services/httpServices";
import Axios from "axios";
import { BASE_URL } from "../../../utils/AppConstants";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";

const APIKey = ({ isOpen, toggle, setIsGenerated, apiKeyList }) => {
  let { wallet } = useSelector((state) => state);
  const [selected, setSelected] = useState("Instant");
  const [submitting, setSubmitting] = useState(false);
  const [copied, setCopied] = useState(false);
  const alert = useAlert();
  const [inputData, setInputData] = useState({
    title: "",
    titleVal: "",
    apiKey: "",
  });
  useEffect(() => {
    if (!isOpen) {
      setInputData({
        title: "",
        titleVal: "",
        apiKey: "",
      });
      setCopied(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (inputData.apiKey) {
      setInputData({
        title: inputData.title,
        titleVal: "",
        apiKey: "",
      });
    }
  }, [selected]);

  let isValidated = () => {
    let response = true;
    if (!inputData.title.length > 0) {
      setInputData((prev) => ({ ...prev, titleVal: " Title is required." }));
      response = false;
    }
    return response;
  };
  const options = ["Escrow", "Instant"];
  let renderBtn = () => {
    if (submitting) {
      return (
        <div>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...
        </div>
      );
    }
    return (
      <div>
        {inputData.apiKey.length > 0
          ? `${copied ? "Copied" : "Copy API Key"}`
          : "Generate"}
      </div>
    );
  };
  const submitHandler = async () => {
    if (Number(inputData.title.length) > 50) {
      alert.show(`Key title must be less then 50 character`, {
        type: "error",
      });
      return;
    }
    if (inputData.apiKey.length > 0) {
      copy(inputData.apiKey);
      setCopied(true);
      return;
    }
    const found = apiKeyList.some((item) => item.type == selected);
    if (found) {
      alert.show(`you cannot generate more then one ${selected} Key`, {
        type: "error",
      });
      return;
    }

    if (!isValidated()) {
      return;
    }
    setSubmitting(true);

    const data = {
      title: inputData.title,
      userId: wallet?.key?.public,
      type: selected,
      environment: "test",
    };
    try {
      const token = await getJWT();
      let res = await Axios.post(BASE_URL + "/keys/create", data);
      alert.show("API Key successfully created", {
        type: "success",
      });
      setSubmitting(false);
      setInputData((prev) => ({ ...prev, apiKey: res.data.secretKey }));
      setIsGenerated(true);
    } catch {
      setSubmitting(false);
      setIsGenerated(false);
    }
  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };
  return (
    <>
      <GenericModal isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainHeading}>New Api Key</div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>Title</div>
          <CustomInput
            placeholder={"Title"}
            value={inputData.title}
            onChange={handleInputChange}
            valMsg={inputData.titleVal}
            name="title"
            type="text"
          />
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>Type</div>
          <DropDown
            selected={selected}
            setSelected={setSelected}
            options={options}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.label}>Api Key</div>
          <CustomInput
            placeholder={"key"}
            value={inputData.apiKey}
            // onChange={handleInputChange}
            // valMsg={passwordValidation}
            // readonly={true}
            name="percentage fee"
            type="text"
            readOnly={true}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
            onClick={submitHandler}
            disabled={copied ? true : false}
          >
            {renderBtn()}
          </Button>
        </div>
      </GenericModal>
    </>
  );
};

export default APIKey;
